export const SET_INDEX = 'SET_INDEX';

export const setIndex = (indexGeneral, indexTypeGeneral) => ({
    type: SET_INDEX,
    indexGeneral,
    indexTypeGeneral,
});

const initialState = {
    indexGeneral: null,
    indexTypeGeneral: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_INDEX:
        return {
            ...state,
            indexGeneral: action.indexGeneral,
            indexTypeGeneral: action.indexTypeGeneral,
        };
    default:
        return state;
    }
};
