export const SET_BUTTON_CASETAS = 'SET_BUTTON_CASETAS';

export const setButtonCasetas = (apagado) => ({
    type: SET_BUTTON_CASETAS,
    apagado,
});

const initialState = {
    apagado: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_BUTTON_CASETAS:
        return {
            ...state,
            apagado: action.apagado,
        };
    default:
        return state;
    }
};
