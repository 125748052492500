import { Autocomplete, Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const yrs = [];
const currentYear = new Date().getFullYear();
for (let i = 2021; i <= currentYear; i += 1) {
    yrs.push(i);
}

const initialFieldValues = {
    id: 0,
    mes: '',
    year: currentYear,
    tipoGasto: '',
    gastos: 0.0,
};
export default function GastosExtraForm(props) {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);
    const dispatch = useDispatch();

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('mes' in fieldValues) {
            temp.mes = fieldValues.mes ? '' : 'Campo Requerido';
        }

        if ('year' in fieldValues) {
            temp.year = fieldValues.year ? '' : 'Campo Requerido';
        }

        if ('tipoGasto' in fieldValues) {
            temp.tipoGasto = fieldValues.tipoGasto ? '' : 'Campo Requerido';
        }

        if ('gastos' in fieldValues) {
            temp.gastos = fieldValues.gastos ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const changeTipoGasto = (e, v) => {
        setValues({
            ...values,
            tipoGasto: e.target.value,
        });
    };

    const changeGastos = (e, v) => {
        setValues({
            ...values,
            gastos: e.target.value,
        });
    };

    const handleMesInputChange = (e, v) => {
        const idx = meses.indexOf(v) + 1;
        setValues({
            ...values,
            mes: idx,
        });
    };

    const handleYearInputChange = (e, v) => {
        setValues({
            ...values,
            year: v,
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Autocomplete
                        getOptionLabel={(option) => `${option} `}
                        id='tags-standard'
                        name='mes'
                        onChange={handleMesInputChange}
                        options={meses}
                        renderInput={(params) => (
                            <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label='Mes'
                                placeholder='Mes'
                                variant='standard'
                            />
                        )}
                    />

                    <Autocomplete
                        defaultValue={currentYear}
                        getOptionLabel={(option) => `${option} `}
                        id='tags-standard'
                        name='year'
                        onChange={handleYearInputChange}
                        options={yrs}
                        renderInput={(params) => (
                            <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label='Año'
                                placeholder='Año'
                                variant='standard'
                            />
                        )}
                    />

                    <TextField
                        label='Tipo Gasto'
                        onChange={changeTipoGasto}
                        placeholder='Tipo Gasto'
                        value={values.tipoGasto}
                    />

                    <TextField
                        label='Cantidad'
                        onChange={changeGastos}
                        placeholder='Cantidad'
                        type='number'
                        value={values.gastos}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>

                </Grid>

            </Grid>
        </Form>
    );
}
