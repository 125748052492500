export const LOAD_GASOLINAS = 'LOAD_GASOLINAS';
export const SET_GASOLINAS = 'SET_GASOLINAS';
export const SAVE_GASOLINA = 'SAVE_GASOLINA';

export const loadGasolinas = () => ({
    type: LOAD_GASOLINAS,
});

export const setGasolinas = (gasolinas) => ({
    type: SET_GASOLINAS,
    gasolinas,
});

export const saveGasolina = (gasolina) => ({
    type: SAVE_GASOLINA,
    gasolina,
});

const initialState = {
    gasolinas: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_GASOLINAS:
        const { gasolinas } = action;
        return {
            ...state,
            gasolinas,
        };

    default:
        return state;
    }
};
