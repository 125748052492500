import React from 'react';
import {
    FormControl,
    TextField,
    FormHelperText,
} from '@mui/material';

import { Autocomplete as MuiAutocomplete } from '@mui/lab';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(0),
        '&. MuiFormControl-root': {
            width: '100%',
            margin: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(0),
            paddingLeft: theme.spacing(0),
        },
    },
}));

export default function Autocomplete(props) {
    const classes = useStyles();
    const {
        name,
        label,
        options,
        onChange,
        value,
        error = null,
        displayField = 'title',
        displayField1 = '',
        disabled = false,
        ...others

    } = props;
    return (
        <FormControl
            variant='outlined'
            {...(error && {
                error: true,
            })}
        >
            <MuiAutocomplete
                // className={classes.root}
                defaultValue={{ title: '', id: 0 }}
                disabled={disabled}
                getOptionLabel={(option) => option[displayField] + (displayField1 !== '' ? ` ${option[displayField1]}` : '')}
                isOptionEqualToValue={(option, v) => option.id === (v === undefined ? 0 : value.id)}
                name={name}
                onChange={(event, newValue) => {
                    onChange({
                        target: {
                            name,
                            value: newValue,
                        },
                    });
                }}
                options={options}
                renderInput={(params) => (
                    <TextField
                        sx={{ width: '100%' }}
                        {...params}
                        label={`${label}`}
                        variant='outlined'
                    />
                )}
                // renderOption={(_, option) => (
                //     <li key={option.id}>
                //         {option[displayField]}
                //         {' '}
                //         {(displayField1 !== '' ? option[displayField1] : '')}
                //     </li>
                // )}
                sx={{ width: '100%' }}
                value={value}

            />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
