import {
    InputAdornment, TableBody, TableCell, TableRow, Toolbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AddCircleOutline, AddIcCallTwoTone, Search } from '@mui/icons-material';
import moment from 'moment';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { filterOnAnyField } from '../../utils';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import Popup from '../../components/Popup';
import MonitoreoForm from './MonitoreoForm';
import ConfirmDialog from '../../components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'tramoId', label: 'Tramo' },
    { id: 'ciudad', label: 'Ciudad' },
    { id: 'estado', label: 'Estado' },
    { id: 'status', label: 'Estatus' },
    { id: 'detalles', label: 'Detalles' },
    { id: 'eta', label: 'ETA' },
    { id: 'fecha', label: 'Fecha' },
    // { id: 'actions', label: 'Acciones', disableSorting: true },
];
export default function Monitoreo({
    viajeId, tramos,
}) {
    const [records, setRecords] = useState([]);
    const fetchData = async () => {
        const data = await http.get(`/viajes/monitoreo/${viajeId}`);
        setRecords(data.data);
    };
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viajeId]);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);

    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };
    const classes = useStyles();
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState();
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });
    const addOrEdit = async (monitoreo, resetForm) => {
        console.log(moment(monitoreo.fecha).local(true).toISOString());
        await http.post('/viajes/monitoreo', {
            ...monitoreo,
            viajeId,
            tramoId: monitoreo.tramoId.id,
            fecha: moment.utc(monitoreo.fecha).toISOString(),
        });
        // dispatch(saveCliente(cliente));
        setOpenPopup(false);
        resetForm();
        fetchData();
    };

    return (
        <>
            <Toolbar>
                <Controls.Input
                    className={classes.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='end'>
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    label='Buscar'
                    onChange={handleSearch}
                    value={filter}
                />

                <Controls.Button
                    className={classes.newButton}
                    onClick={() => {
                        setRecordForEdit(null);
                        setOpenPopup(true);
                    }}
                    startIcon={<AddCircleOutline />}
                    text='Agregar Nuevo'
                    variant='outlined'
                />
            </Toolbar>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.tramoId}</TableCell>
                            <TableCell>{item.ciudad}</TableCell>
                            <TableCell>{item.estado}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{item.detalles}</TableCell>
                            <TableCell>{item.eta}</TableCell>
                            <TableCell><Moment date={item.fecha} format='D MMM YYYY hh:mm' /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            <TblPagination />

            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Monitoreo'
            >
                <MonitoreoForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                    tramos={tramos}
                />
            </Popup>

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
