export const LOAD_CASETAS_LIQ = 'LOAD_CASETAS_LIQ';
export const SET_CASETAS_LIQ = 'SET_CASETAS_LIQ';

export const loadCasetasLiq = (viajeId) => ({
    type: LOAD_CASETAS_LIQ,
    viajeId,
});

export const setCasetasLiq = (casetas) => ({
    type: SET_CASETAS_LIQ,
    casetas,
});

const initialState = {
    casetas: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_CASETAS_LIQ:
        const { casetas } = action;
        return {
            ...state,
            casetas,
        };

    default:
        return state;
    }
};
