import React from 'react';
// eslint-disable-next-line import/no-named-default
import { default as Mnt } from 'react-moment';

export default function Moment(props) {
    const { date } = props;
    if (date !== null) {
        return <Mnt date={date} {...props} />;
    }
    return <p />;
}
