import React from 'react';
import {
    FormGroup,
    FormControlLabel,
    Checkbox as MuiCheckbox,
} from '@mui/material';

export default function Checkbox(props) {
    const {
        name, value, label, onChange,
    } = props;
    const convertToDefaultEventParameter = (n, v) => ({
        target: {
            name: n,
            value: v,
        },
    });
    return (
        <FormGroup row>
            <FormControlLabel
                control={(
                    <MuiCheckbox
                        checked={value}
                        color='primary'
                        name={name}
                        onChange={(e) => onChange(
                            convertToDefaultEventParameter(
                                name,
                                e.target.checked,
                            ),
                        )}
                    />
                )}
                label={label}
            />
        </FormGroup>
    );
}
