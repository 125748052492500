import {
    Box, Grid,
    IconButton, Input, InputAdornment, Paper, TableBody, TableCell, TableRow, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import Autocomplete from '@mui/lab/Autocomplete';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Search } from '@mui/icons-material';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { loadOperadores } from '../../redux/ducks/operadores';
import { filterOnAnyField } from '../../utils';
import { setIndex } from '../../redux/ducks/sideMenuIndex';

const headCells = [
    { id: 'collapse', label: '' },
    { id: 'fecha', label: 'Fecha Pago' },
    { id: 'operador', label: 'Operador' },
    { id: 'pago', label: 'Pago Operador' },
    { id: 'anticipos', label: 'Anticipos' },
    { id: 'otrosGastos', label: 'Otros Gastos' },
    { id: 'deducciones', label: 'Deducciones' },
    { id: 'Total', label: 'Total' },

];

const headPagos = [
    { id: 'tipoPago', label: 'Tipo Pago' },
    { id: 'cantidad', label: 'Cantidad' },
];
const headTramos = [
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'lugarInicio', label: 'Origen' },
    { id: 'lugarFin', label: 'Destino' },
    { id: 'pago', label: 'Pago Operador' },
    { id: 'anticipos', label: 'Anticipos' },
    { id: 'otrosGastos', label: 'Otros Gastos' },
    { id: 'total', label: 'Total' },
];

const headDescuentos = [
    { id: 'tipoDescuento', label: 'Tipo Descuento' },
    { id: 'saldoActual', label: 'Saldo' },
    { id: 'descuento', label: 'Descuento' },
    { id: 'nuevoSaldo', label: 'Nuevo Saldo' },
];

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
}));

function Row({
    item, updateRecords,
}) {
    const classes = useStyles();

    const setOpen = (open) => {
        updateRecords({
            ...item,
            open,
        });
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label='expand item' onClick={() => setOpen(!item.open)} size='small'>
                        {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{item.fechaPago}</TableCell>
                <TableCell>{item.operador}</TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.pago}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.anticipo}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.otrosGastos}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.totalDeducciones}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.pago + item.otrosGastos - item.anticipo - item.totalDeducciones}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Descuentos:
                            </Typography>
                            <Table aria-label='tramos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headDescuentos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.descuentos.map((descuento) => (
                                            <TableRow key={`descuentos-${descuento.tipoDescuento}-${item.id}`}>
                                                <TableCell>
                                                    {descuento.tipoDescuento}
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={descuento.saldoActual}
                                                    />
                                                </TableCell>
                                                <TableCell>

                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={descuento.descuento}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={descuento.saldoActual - descuento.descuento}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Grid container>
                                <Typography component='div' gutterBottom variant='subtitle1'>
                                    Pagos Extra:
                                </Typography>

                            </Grid>

                            <Table aria-label='pagos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headPagos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.pagos?.map((pago) => (
                                            <TableRow key={pago.id}>
                                                <TableCell>{pago.tipoPago}</TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={pago.cantidad}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Tramos:
                            </Typography>
                            <Table aria-label='tramos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headTramos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.items.map((tramo) => (
                                            <TableRow key={`${item.id}-${tramo.tramoId}`}>
                                                <TableCell>
                                                    <Moment date={tramo.fechaInicio} />
                                                </TableCell>
                                                <TableCell>
                                                    {tramo.lugarInicio}
                                                </TableCell>
                                                <TableCell>
                                                    {tramo.lugarFin}
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.pagoOperador}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.anticipo}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.totalGastos}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.total}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
function CollapsibleRow({ item, updateRecords }) {
    return (
        <>
            <Row
                item={item}
                updateRecords={updateRecords}
            />
        </>
    );
}

export default function Pagos() {
    const [filter, setFilter] = useState();

    const classes = useStyles();
    const [operadoresSeleccionados, setOperadoresSeleccionados] = useState();
    const operadores = useSelector((state) => state.operadores.operadores);
    const dispatch = useDispatch();
    const [records, setRecords] = useState([]);

    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    useEffect(() => {
        dispatch(loadOperadores());
    }, [dispatch]);
    useEffect(() => {
        dispatch(setIndex(3, 'Liq'));
    }, [dispatch]);

    const [id, setId] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            if (id && id !== 0) {
                const data = await http.get(`/operadores/pagos/${id}`);
                setRecords(data.data);
            } else {
                const data = await http.get('/operadores/pagos/');
                if (data) {
                    setRecords(data.data);
                } else {
                    setRecords([]);
                }
            }
        };
        fetchData();
    }, [id, setRecords]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);

    const handleMultipleSelect = (e, newValue) => {
        setOperadoresSeleccionados(newValue);
    };

    const updateRecords = (op) => {
        const idx = records.findIndex((r) => r.id === op.id);
        setRecords([
            ...records.slice(0, idx),
            op,
            ...records.slice(idx + 1),
        ]);
    };

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };
    return (
        <>
            <Box displayPrint='none'>
                <PageHeader
                    icon={<PeopleOutlineTwoToneIcon fontSize='large' />}
                    subtitle=''
                    title='Registro Pago Operadores'
                />
            </Box>
            <Paper className={classes.pageContent}>
                <Box displayPrint='none'>
                    <Grid
                        container
                        justifyContent='flex-start'
                        spacing={1}
                    >

                        <Grid item xs={2}>
                            <Autocomplete
                                getOptionLabel={(option) => `${option.nombre} ${option.apellidos}`}
                                id='tags-standard'
                                onChange={handleMultipleSelect}
                                options={operadores}
                                renderInput={(params) => (
                                    <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label='Operadores'
                                        placeholder='Operadores'
                                        variant='standard'
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Controls.Button
                                className={classes.newButton1}
                                onClick={() => {
                                    setId(operadoresSeleccionados?.id);
                                }}
                                text='Actualizar'
                                variant='outlined'

                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Controls.Input
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='end'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                label='Buscar'
                                onChange={handleSearch}
                                value={filter}
                            />
                        </Grid>
                    </Grid>

                </Box>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => <CollapsibleRow key={item.id} item={item} updateRecords={updateRecords} />)}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>

        </>
    );
}
