import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
    DeleteForeverOutlined, LocationCityOutlined, Search,
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/Edit';

import { useOktaAuth } from '@okta/okta-react';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';

import { setIndex } from '../../redux/ducks/sideMenuIndex';
import http from '../../http-common';
import { filterOnAnyField } from '../../utils';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'numero_economico', label: 'Numero Economico' },
    { id: 'operador', label: 'Operador' },
    { id: 'telefono', label: 'Telefono' },
    { id: 'viaje_id_futuro', label: 'Viaje Futuro' },
    { id: 'viaje_id', label: 'Viaje' },
    { id: 'tramo_id', label: 'Tramo' },
    { id: 'lugar_inicio', label: 'Lugar Inicio' },
    { id: 'lugar_fin', label: 'Lugar Fin' },
    { id: 'fecha_inicio', label: 'Fecha Inicio' },
    { id: 'comentarios', label: 'Comentarios viaje' },
    /* { id: 'ciudad', label: 'ciudad' },
    { id: 'estado', label: 'Estado' },
    { id: 'detalles', label: 'Detalles' },
    { id: 'fecha', label: 'Fecha' },
    { id: 'monitor_status', label: 'Estatus' }, */
    { id: 'detalles_ubicacion', label: 'Detalles Ubicacion' },
    { id: 'full_url_key', label: 'Prueba Entrega' },
    { id: 'nombre_ubicacion', label: 'Nombre Ubicacion' },
    { id: 'telefono_ubicacion', label: 'Telefono Ubicacion' },
    { id: 'contacto_ubicacion', label: 'Contacto Ubicacion' },
    { id: 'url_ubicacion', label: 'Url' },
    { id: 'actions', label: 'Acciones', disableSorting: true },

];

export default function Monitoreo() {
    const [userInfo, setUserInfo] = useState();
    const { authState, oktaAuth } = useOktaAuth();

    const [canViewViaje, setCanViewViaje] = useState(false);
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((ui) => {
                setUserInfo(ui);
                if (ui.groups.includes('Trafico') || ui.groups.includes('Administradores')) {
                    setCanViewViaje(true);
                }
            });
        }
    }, [authState, oktaAuth]);

    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const [data, setData] = useState([]);

    const fetchData = async () => {
        const s = await http.get('/viajes/monitor');
        setData(s.data);
    };
    useEffect(() => {
        fetchData();
    }, []);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(data, headCells, filterFn, 10000);

    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };
    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            fetchData();
        }, 1000 * 60);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        dispatch(setIndex(2, 'Cat'));
    }, [dispatch]);

    return (
        <>
            <PageHeader
                icon={<LocationCityOutlined fontSize='large' />}
                subtitle=''
                title='Monitoreo'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                    />

                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.numero_economico}</TableCell>
                                <TableCell>{item.operador}</TableCell>
                                <TableCell>{item.telefono}</TableCell>
                                <TableCell>
                                    <Link onClick={() => { dispatch(setIndex(1, 'Op')); }} to={`/viaje/${item.viaje_id_futuro}`}>{item.viaje_id_futuro}</Link>
                                </TableCell>
                                <TableCell>{item.viaje_id}</TableCell>
                                <TableCell>{item.tramo_id}</TableCell>
                                <TableCell>{item.lugar_inicio}</TableCell>
                                <TableCell>{item.lugar_fin}</TableCell>
                                <TableCell>
                                    {
                                        item.fecha_inicio ? <Moment date={item.fecha_inicio} /> : null
                                    }
                                </TableCell>
                                <TableCell>{item.comentarios}</TableCell>
                                {/* <TableCell>{item.ciudad}</TableCell>
                                <TableCell>{item.estado}</TableCell>
                                <TableCell>{item.detalles}</TableCell>
                                <TableCell>
                                    {
                                        item.fecha ? <Moment date={item.fecha} /> : null
                                    }
                                </TableCell>
                                <TableCell>{item.monitor_status}</TableCell> */}
                                <TableCell>{item.detalles_ubicacion}</TableCell>
                                <TableCell><a href={item.full_url_key} rel='noreferrer' target='_blank'>{item.file_name}</a></TableCell>
                                <TableCell>{item.nombre_ubicacion}</TableCell>
                                <TableCell>{item.telefono_ubicacion}</TableCell>
                                <TableCell>{item.contacto_ubicacion}</TableCell>
                                <TableCell>
                                    {
                                        item.url_ubicacion
                                            ? <a href={item.url_ubicacion} rel='noreferrer' target='_blank'>Link</a> : <></>
                                    }

                                </TableCell>

                                <TableCell>
                                    {
                                        (canViewViaje && item.viaje_id)
                                        && (
                                            <Link onClick={() => { dispatch(setIndex(1, 'Op')); }} to={`/viaje/${item.viaje_id}`}>
                                                <Controls.ActionButton
                                                    color='primary'
                                                >
                                                    <EditOutlinedIcon fontSize='small' />
                                                </Controls.ActionButton>
                                            </Link>
                                        )
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>

        </>
    );
}
