import http from '../../../http-common';

export function requestLoadViajes() {
    return http.get('/viajes');
}

export function requestLoadViaje(id) {
    return http.get(`/viajes/${id}`);
}

export function requestSaveViaje(viaje, password) {
    return http.post(`/viajes?password=${password}`, viaje, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export function requestLoadViajeFiles(id) {
    return http.get(`/viajes/files/${id}`);
}

export function requestRefreshCombustible(
    vehiculo,
    asignado,
    fechaInicial,
    fechaFinal,
    viajeId,
) {
    return http.get(
        `/gasolinasgen/${vehiculo}?asignado=${asignado}&fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}&viajeId=${viajeId}`,
    );
}

export function deleteCombustible(viajeId, combustibleId) {
    return http.delete(`/gasolinasgen/${viajeId}/${combustibleId}`);
}

export function removeCaseta(viajeId, casetaId) {
    return http.delete(`/casetas/${viajeId}/${casetaId}`);
}

export function requestGpsVehiculo(vehiculo, fechaInicial, fechaFinal) {
    return http.get(
        `/gps/${vehiculo}?fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`,
    );
}

export function requestRefreshCasetas(vehiculo, fechaInicial, fechaFinal) {
    return http.get(
        `/casetas/${vehiculo}?fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`,
    );
}

export function requestCalcularRendimientoViaje(numeroEconomico, combustible) {
    return http.post(`/gps/${numeroEconomico}`, combustible);
}
