import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForever, Search } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import NumberFormat from 'react-number-format';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import { loadGasolinas, saveGasolina } from '../../redux/ducks/gasolinas';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import GasolinaForm from './GasolinaForm';
import Popup from '../../components/Popup';
import ConfirmDialog from '../../components/ConfirmDialog';
import http from '../../http-common';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'vehiculo', label: 'Vehículo' },
    { id: 'fecha', label: 'Fecha/Hora' },
    { id: 'cantidad', label: 'Cantidad' },
    { id: 'monto', label: 'Monto' },
    { id: 'pu', label: 'Precio Unitario' },
    { id: 'gasolinera', label: 'Gasolinera' },
    { id: 'editar', label: 'Editar' },
    { id: 'borrar', label: 'Borrar' },
];

export default function Gasolina() {
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const gasolinas = useSelector((state) => state.gasolinas.gasolinas);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(gasolinas, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return items.filter((x) => x.vehiculo.toLowerCase().includes(target.value.toLowerCase()) || x.gasolinera.toLowerCase().includes(target.value.toLowerCase()) || x.fecha.toLowerCase().includes(target.value.toLowerCase()));
            },
        });
    };

    const [recordForEdit, setRecordForEdit] = useState(null);
    const [openPopupGasolina, setOpenPopupGasolina] = useState(false);

    const dispatch = useDispatch();
    const addOrEdit = (gasolina) => {
        dispatch(saveGasolina(gasolina));
        setOpenPopupGasolina(false);
    };

    const funcSetRecordForEdit = () => {
        setRecordForEdit(null);
    };

    const addSoloFecha = (itemToEdit) => {
        setRecordForEdit({ ...itemToEdit, soloFecha: itemToEdit.fecha, soloHora: itemToEdit.fecha });
    };

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteGasolina = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar este registro de gasolina?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/gasolinasgen/${item.id}`);
                dispatch(loadGasolinas());
            },
        });
    };

    useEffect(() => {
        dispatch(loadGasolinas());
        dispatch(setIndex(0, 'Other'));
    }, [dispatch]);

    return (
        <>
            <PageHeader
                icon={<LocalGasStationIcon fontSize='large' />}
                subtitle=''
                title='Gasolina'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                        // onClick={() => dispatch(loadOUnidades())}
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.vehiculo}</TableCell>
                                <TableCell>{item.fecha}</TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        thousandSeparator
                                        value={item.cantidad}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.monto}
                                    />
                                </TableCell>
                                <TableCell>
                                    {' '}
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.pu}
                                    />
                                </TableCell>
                                <TableCell>{item.gasolinera}</TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            setOpenPopupGasolina(true);
                                            addSoloFecha(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            deleteGasolina(item);
                                        }}
                                    >
                                        <DeleteForever fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />

            <Popup
                openPopup={openPopupGasolina}
                setOpenPopup={setOpenPopupGasolina}
                title='Gasolina'
            >
                <GasolinaForm
                    addOrEdit={addOrEdit}
                    funcSetRecordForEdit={funcSetRecordForEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </>
    );
}
