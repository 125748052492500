import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForever, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Controls } from '../../components/controls/Controls';
import useTable from '../../components/useTable';
import { filterOnAnyField } from '../../utils';
import TramoForm from './TramoForm';
import Popup from '../../components/Popup';
import Moment from '../../components/Moment';
import { setSnackbar } from '../../redux/ducks/snackbar';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

export default function Tramos({ setIsDirty, values, setValues }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openPopupTramos, setOpenPopupTramos] = useState(false);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const headCells = [
        { id: 'id', label: 'Numero de Tramo' },
        { id: 'cliente', label: 'Cliente' },
        { id: 'inicio', label: 'Inicio' },
        { id: 'fin', label: 'Fin' },
        { id: 'fechaInicio', label: 'Fecha Inicio' },
        { id: 'fechaFin', label: 'Fecha Fin' },
        { id: 'kilometros', label: 'Kilometros' },
        { id: 'flete', label: 'Flete' },
        { id: 'pagoOperador', label: 'Pago Operador' },
        { id: 'anticipo', label: 'Anticipo' },
        { id: 'factura', label: 'Factura' },
        { id: 'statusPagoOperador', label: 'Status Pago Operador' },
        { id: 'statusFactura', label: 'Status Factura' },
        { id: 'pagoEfectivo', label: 'Tramo Pagado en Efectivo' },
        { id: 'ordenDeCompra', label: 'Referencia' },
        { id: 'comentarios', label: 'Comentarios' },
        { id: 'actions', label: 'Acciones', disableSorting: true },
    ];
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(values.tramos, headCells, filterFn);

    const addOrEdit = (tramo) => {
        const editedTramoArray = values.tramos.filter((a) => (a.id === tramo.id));
        const editedTramo = editedTramoArray[0];

        if (!editedTramo) {
            let addedTramo;
            if (values.tramos.length === 0) {
                addedTramo = {
                    ...tramo,
                    id: -1,
                };
            } else {
                addedTramo = {
                    ...tramo,
                    id: -1 * (values.tramos.length + 1),
                };
            }

            const tramoList = values.tramos.concat(addedTramo);
            tramoList.sort((a, b) => ((a.fechaInicio > b.fechaInicio) ? 1 : -1));
            setValues({
                ...values,
                tramos: tramoList,
            });
        } else {
            const listWithNoEditedTramos = values.tramos.filter((a) => (a.id !== tramo.id));
            const listWithEditedTramos = listWithNoEditedTramos.concat(tramo);
            listWithEditedTramos.sort((a, b) => ((a.fechaInicio > b.fechaInicio) ? 1 : -1));
            setValues({
                ...values,
                tramos: listWithEditedTramos,
            });
        }
        setIsDirty(true);
        setOpenPopupTramos(false);
    };

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };
    const deleteTramo = (item) => {
        const hasGastos = values.otrosGastos.filter((f) => f.tramoId === item.id).length > 0;
        if (hasGastos) {
            dispatch(setSnackbar(true, 'error', 'Tramo tiene gastos asignados'));
            return;
        }
        const tramosNuevos = values.tramos.filter((f) => f.id !== item.id);
        setValues({
            ...values,
            tramos: tramosNuevos,
        });
        setIsDirty(true);
    };
    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopupTramos(true);
    };
    const [userInfo, setUserInfo] = useState();
    const { authState, oktaAuth } = useOktaAuth();
    const [canViewTramoInfo, setCanViewTramoInfo] = useState(false);
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((ui) => {
                setUserInfo(ui);
                if (ui.groups.includes('Trafico') || ui.groups.includes('Administradores')) {
                    setCanViewTramoInfo(true);
                }
            });
        }
    }, [authState, oktaAuth]);

    return (
        <>
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopupTramos(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>
                                    {
                                        canViewTramoInfo && item.cliente?.nombre
                                    }
                                </TableCell>
                                <TableCell>{item.lugarInicio}</TableCell>
                                <TableCell>{item.lugarFin}</TableCell>
                                <TableCell>
                                    <Moment date={item.fechaInicio} />
                                </TableCell>
                                <TableCell>
                                    <Moment date={item.fechaFin} />
                                </TableCell>
                                <TableCell>{item.kilometros}</TableCell>
                                <TableCell>
                                    {
                                        canViewTramoInfo
                                        && (
                                            <NumberFormat
                                                decimalScale={2}
                                                displayType='text'
                                                prefix='$'
                                                thousandSeparator
                                                value={item.flete}
                                            />
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        canViewTramoInfo
                                        && (
                                            <NumberFormat
                                                decimalScale={2}
                                                displayType='text'
                                                prefix='$'
                                                thousandSeparator
                                                value={item.pagoOperador}
                                            />
                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        canViewTramoInfo
                                        && (
                                            <NumberFormat
                                                decimalScale={2}
                                                displayType='text'
                                                prefix='$'
                                                thousandSeparator
                                                value={item.anticipo}
                                            />
                                        )
                                    }
                                </TableCell>
                                <TableCell>{item.factura ? item.factura.numeroFactura : ''}</TableCell>
                                <TableCell>{item.statusPagoOperador}</TableCell>
                                <TableCell>{item.factura ? item.factura.status : 'Sin Factura'}</TableCell>
                                <TableCell>
                                    {item.pagoEfectivo ? 'SI' : 'NO'}
                                </TableCell>
                                <TableCell>
                                    {item.ordenDeCompra}
                                </TableCell>
                                <TableCell>
                                    {item.comentarios}
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            openInPopup(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>

                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            deleteTramo(item);
                                        }}
                                    >
                                        <DeleteForever fontSize='small' />
                                    </Controls.ActionButton>

                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopupTramos}
                setOpenPopup={setOpenPopupTramos}
                title='Tramo'
            >
                <TramoForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </>
    );
}
