import http from '../../../http-common';

export function requestLoadFacturas() {
    return http.get('/facturas');
}

export function requestSaveFactura(factura) {
    console.log(factura);
    return http.post('/facturas', factura);
}
