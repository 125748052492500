import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

const initialFieldValues = {
    tipoPago: '',
    cantidad: 0.0,
};

export default function PagosExtraForm(props) {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        return true;
    }

    const { addOrEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        addOrEdit(values, resetForm);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.tipoPago}
                        label='Tipo'
                        name='tipoPago'
                        onChange={handleInputChange}
                        value={values.tipoPago}
                    />

                    <Controls.NumericInput
                        error={errors.cantidad}
                        label='cantidad'
                        name='cantidad'
                        onChange={handleInputChange}
                        value={values.cantidad}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
