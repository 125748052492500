import React, { useState } from 'react';

import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TablePagination,
    TableSortLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },

        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
}));
export default function useTable(records, headCells, filterFn, initialRowsPerPage = 100) {
    const classes = useStyles();
    const pages = [50, 100, 200, 10000];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    function TblContainer({ children }) {
        return <Table className={classes.table}>{children}</Table>;
    }

    function TblHead() {
        const handleSortRequest = (cellId) => {
            const isAsc = orderBy === cellId && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(cellId);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((head) => (
                        <TableCell
                            key={head.id}
                            sortDirection={orderBy === head.id ? order : false}
                        >
                            {head.disableSorting ? (
                                head.label
                            ) : (
                                <TableSortLabel
                                    active={orderBy === head.id}
                                    direction={
                                        orderBy === head.id ? order : 'asc'
                                    }
                                    onClick={() => handleSortRequest(head.id)}
                                >
                                    {head.label}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    function TblPagination() {
        return (
            <TablePagination
                component='div'
                count={records ? records.length : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={pages}
            />
        );
    }

    const recordsAfterPagingAndSorting = () => {
        if (!records) {
            return [];
        }
        try {
            const r = _.orderBy(filterFn.fn(records), [(item) => _.get(item, orderBy)?.toLowerCase()], [order])
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
            return r;
        } catch (err) {
            console.log(err);
            return _.orderBy(filterFn.fn(records), [(item) => _.get(item, orderBy)], [order])
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        }
    };
    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    };
}
