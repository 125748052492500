import axios from 'axios';
import store from './redux/configureStore';
import { setSnackbar } from './redux/ducks/snackbar';

export const BASE_URL = process.env.REACT_APP_API_URL || '';

const http = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

http.interceptors.request.use((config) => {
    const oktaToken = JSON.parse(window.localStorage.getItem('okta-token-storage'));
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${oktaToken.accessToken.accessToken}`;
    return config;
}, null, { synchronous: true });
export default http;

http.interceptors.response.use(
    (response) => response,
    (error) => {
        switch (error.response.status) {
        case 400:
            console.log(error.response.data);
            if (typeof error.response.data === 'string') {
                store.dispatch(setSnackbar(true, 'error', error.response.data));
            } else {
                store.dispatch(setSnackbar(true, 'error', error.response.data.message));
            }
            break;
        case 401:
        case 403:
            store.dispatch(setSnackbar(true, 'error', 'Acceso no permitido'));
            break;
        case 500:
            store.dispatch(setSnackbar(true, 'error', 'Error en el sistema'));
            break;
        default:
        }
    },
);
