import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CloseIcon from '@mui/icons-material/Close';
import { Controls } from './controls/Controls';

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
        minWidth: '60vw',
        maxWidth: '60vw',
    },
    dialogTitle: {
        paddingRight: '0px',
    },
}));

export default function Popup(props) {
    const classes = useStyles();
    const {
        title, children, openPopup, setOpenPopup,
    } = props;
    return (
        <Dialog classes={{ paper: classes.dialogWrapper }} open={openPopup}>
            <DialogTitle className={classes.dialogTitle}>
                <div style={{ display: 'flex' }}>
                    <Typography
                        component='div'
                        style={{ flexGrow: 1 }}
                        variant='h6'
                    >
                        {title}
                    </Typography>
                    <Controls.ActionButton
                        color='secondary'
                        onClick={() => setOpenPopup(false)}
                    >
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
        </Dialog>
    );
}
