import moment from 'moment';

export const UPDATE_VIAJE = 'UPDATE_VIAJE';
export const NEW_VIAJE = 'NEW_VIAJE';
export const LOAD_VIAJES = 'LOAD_VIAJES';
export const LOAD_VIAJE = 'LOAD_VIAJE';
export const SET_VIAJES = 'SET_VIAJES';
export const SET_VIAJE = 'SET_VIAJE';
export const SAVE_VIAJE = 'SAVE_VIAJE';
export const SAVED_VIAJE = 'SAVED_VIAJE';
export const LOAD_VIAJE_FILES = 'LOAD_VIAJE_FILES';
export const SET_VIAJE_FILES = 'SET_VIAJE_FILES';
export const REFRESH_COMBUSTIBLE = 'REFRESH_COMBUSTIBLE';
export const SET_VIAJE_COMBUSTIBLE = 'SET_VIAJE_COMBUSTIBLE';
export const SET_VIAJE_COMBUSTIBLE_AUTOMATIC = 'SET_VIAJE_COMBUSTIBLE_AUTOMATIC';
export const REMOVE_COMBUSTIBLE = 'REMOVE_COMBUSTIBLE';
export const REMOVE_CASETA = 'REMOVE_CASETA';
export const REFRESH_VIAJE_GPS = 'REFRESH_VIAJE_GPS';
export const UPDATE_VIAJE_KMS = 'UPDATE_VIAJE_KMS';
export const REFRESH_VIAJE_CASETAS = 'REFRESH_VIAJE_CASETAS';
export const SET_VIAJE_CASETAS = 'SET_VIAJE_CASETAS';
export const CALCULAR_RENDIMIENTO_VIAJE = 'CALCULAR_RENDIMIENTO_VIAJE';

const initialState = {
    viajes: [],
    selectedViaje: {
        id: 0,
        comentarios: '',
        cliente: null,
        operador: null,
        fechaInicio: null,
        fechaInicioHora: null,
        fechaFinHora: null,
        fechaFin: null,
        anticipos: [],
        casetasIda: '',
        casetasRegreso: '',
        origen: '',
        destino: '',
        unidad: null,
        tramos: [],
        otrosGastos: [],
        archivos: [],
        combustible: [],
        kilometros: null,
        casetas: [],
        referencia: '',
    },
};
export const calcularRendimientoViaje = (numeroEconomico, combustible) => ({
    type: CALCULAR_RENDIMIENTO_VIAJE,
    numeroEconomico,
    combustible,
});
export const refreshViajeCombustible = (
    vehiculo,
    asignado,
    fechaInicial,
    fechaFinal,
    viajeId,
) => ({
    type: REFRESH_COMBUSTIBLE,
    vehiculo,
    asignado,
    fechaInicial,
    fechaFinal,
    viajeId,
});
export const setViajeCombustible = (combustible) => ({
    type: SET_VIAJE_COMBUSTIBLE,
    combustible,
});

export const setViajeCombustibleAutomatic = (combustible) => ({
    type: SET_VIAJE_COMBUSTIBLE_AUTOMATIC,
    combustible,
});

export const loadViajes = () => ({
    type: LOAD_VIAJES,
});

export const loadViajeFiles = (viajeId) => ({
    type: LOAD_VIAJE_FILES,
    viajeId,
});

export const newViaje = () => ({
    type: NEW_VIAJE,
    selectedViaje: initialState.selectedViaje,
});

export const loadViaje = (id) => ({
    type: LOAD_VIAJE,
    id,
});

export const removeCombustible = (viajeId, combustibleId) => ({
    type: REMOVE_COMBUSTIBLE,
    viajeId,
    combustibleId,
});

export const removeCaseta = (viajeId, casetaId) => ({
    type: REMOVE_CASETA,
    viajeId,
    casetaId,
});

export const refreshViajeGps = (vehiculo, fechaInicial, fechaFinal) => ({
    type: REFRESH_VIAJE_GPS,
    vehiculo,
    fechaInicial,
    fechaFinal,
});

export const refreshViajeCasetas = (
    vehiculo,
    asignado,
    fechaInicial,
    fechaFinal,
) => ({
    type: REFRESH_VIAJE_CASETAS,
    vehiculo,
    fechaInicial,
    fechaFinal,
    asignado,
});

export const setViajeCasetas = (casetas) => ({
    type: SET_VIAJE_CASETAS,
    casetas,
});
export const setViajeGps = (kms) => ({
    type: UPDATE_VIAJE_KMS,
    kms,
});

export function saveViaje(viaje, password) {
    const viajeCopy = {
        ...viaje,
    };

    if (viajeCopy.fechaInicioHora !== null) {
        const fechaInicio = moment(viaje.fechaInicio).format('YYYY-MM-DD');
        const horaInicio = moment(viaje.fechaInicioHora).format('HH:mm');
        viajeCopy.fechaInicio = moment.utc(`${fechaInicio} ${horaInicio}:00`);
    } else if (viaje.fechaInicio !== null) {
        const fechaInicio = moment(viaje.fechaInicio).format('YYYY-MM-DD');
        viajeCopy.fechaInicio = moment.utc(`${fechaInicio} 00:00:00`);
    }

    if (viajeCopy.fechaFinHora !== null) {
        const fechaFin = moment(viaje.fechaFin).format('YYYY-MM-DD');
        const horaInicio = moment(viaje.fechaFinHora).format('HH:mm');
        viajeCopy.fechaFin = moment.utc(`${fechaFin} ${horaInicio}:00`);
    } else if (viaje.fechaFin !== null) {
        const fechaFin = moment(viaje.fechaFin).format('YYYY-MM-DD');
        viajeCopy.fechaFin = moment.utc(`${fechaFin} 00:00:00`);
    }

    return {
        type: SAVE_VIAJE,
        viaje: viajeCopy,
        password,
    };
}

export const savedViaje = (viaje) => ({
    type: SAVED_VIAJE,
    viaje,
});

export const setViajes = (viajes) => ({
    type: SET_VIAJES,
    viajes,
});
export const setViaje = (viaje) => ({
    type: SET_VIAJE,
    viaje,
});

export const updateViaje = (viaje) => ({
    type: UPDATE_VIAJE,
    viaje,
});

export const setViajeFiles = (files) => ({
    type: SET_VIAJE_FILES,
    files,
});

export default (state = initialState, action) => {
    switch (action.type) {
    case NEW_VIAJE:
        return {
            ...state,
            selectedViaje: action.selectedViaje,
        };
    case SAVED_VIAJE:
    case SET_VIAJE:
        const { viaje } = action;
        if (!viaje) {
            return {
                ...state,
                selectedViaje: initialState.selectedViaje,
            };
        }
        viaje.fechaInicioHora = viaje.fechaInicio;
        viaje.fechaFinHora = viaje.fechaFin;
        return {
            ...state,
            selectedViaje: viaje,
        };
    case SET_VIAJE_COMBUSTIBLE:
        return {
            ...state,
            selectedViaje: {
                ...state.selectedViaje,
                combustible: state.selectedViaje.combustible.concat(action.combustible),
            },
        };
    case SET_VIAJE_COMBUSTIBLE_AUTOMATIC:
        return {
            ...state,
            selectedViaje: {
                ...state.selectedViaje,
                combustible: action.combustible,
            },
        };
    case SET_VIAJE_CASETAS:
        return {
            ...state,
            selectedViaje: {
                ...state.selectedViaje,
                casetas: state.selectedViaje.casetas.concat(action.casetas),
            },
        };
    case SET_VIAJE_FILES:
        const { files } = action;
        return {
            ...state,
            selectedViaje: {
                ...state.selectedViaje,
                archivos: files,
            },
        };
    case SET_VIAJES:
        const { viajes } = action;
        return {
            ...state,
            viajes,
        };
    case UPDATE_VIAJE:
        return {
            ...state,
            selectedViaje: action.viaje,
        };
    case UPDATE_VIAJE_KMS:
        return {
            ...state,
            selectedViaje: {
                ...state.selectedViaje,
                kilometros: action.kms,
            },
        };

    default:
        return state;
    }
};
