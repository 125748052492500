import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import { loadOperadores } from '../../redux/ducks/operadores';
import { loadUnidades } from '../../redux/ducks/unidades';
import 'date-fns';

export default function TopViaje({
    setIsDirty, values, handleInputChange, errors,
}) {
    const operadores = useSelector((state) => state.operadores.operadores);
    const unidades = useSelector((state) => state.unidades.unidades);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadOperadores(false, false, false));
    }, [dispatch]);
    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    return (
        <Grid container>
            <Grid item xs={6}>
                <Grid
                    container
                    justifyContent='flex-start'
                    spacing={0}
                >
                    <Grid item xs={6}>
                        <Controls.DatePicker
                            error={errors.fechaInicio}
                            label='Fecha Inicio Viaje *'
                            name='fechaInicio'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            value={values.fechaInicio}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.TimePicker
                            error={errors.fechaInicioHora}
                            label='Hora Inicio Viaje *'
                            name='fechaInicioHora'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            value={values.fechaInicioHora}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.Autocomplete
                            displayField='nombre'
                            displayField1='apellidos'
                            error={errors.operador}
                            label='Operador *'
                            name='operador'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            options={operadores}
                            value={values.operador}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controls.Autocomplete
                            displayField='numeroEconomico'
                            error={errors.unidad}
                            label='Unidad *'
                            name='unidad'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            options={unidades}
                            value={values.unidad}
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    justifyContent='center'
                    spacing={0}
                />

                <Grid
                    container
                    justifyContent='flex-start'
                    spacing={1}
                >
                    <Grid item />
                    <Grid item>
                        <Controls.Input
                            error={errors.referencia}
                            label='Referencia Viaje'
                            name='referencia'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            value={values.referencia}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controls.DatePicker
                            label='Fecha Fin Viaje'
                            name='fechaFin'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            value={values.fechaFin}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controls.TimePicker
                            label='Hora Fin Viaje'
                            name='fechaFinHora'
                            onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                            value={values.fechaFinHora}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    );
}
