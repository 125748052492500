export const LOAD_FACTURAS = 'LOAD_FACTURAS';
export const SET_FACTURAS = 'SET_FACTURAS';

export const SAVE_FACTURA = 'SAVE_FACTURA';
export const SAVED_FACTURA = 'SAVED_FACTURA';

export const loadFacturas = () => ({
    type: LOAD_FACTURAS,
});

export const setFacturas = (facturas) => ({
    type: SET_FACTURAS,
    facturas,
});

export const saveFactura = (factura) => ({
    type: SAVE_FACTURA,
    factura,
});

export const savedFactura = (factura) => ({
    type: SAVED_FACTURA,
    factura,
});

const initialState = {
    facturas: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_FACTURAS:
        const { facturas } = action;
        return {
            ...state,
            facturas,
        };
    case SAVED_FACTURA:
        const { factura } = action;
        const index = state.facturas.findIndex((item) => item.id === factura.id);
        if (index === -1) {
            return {
                ...state,
                facturas: state.facturas.concat(factura),
            };
        }
        const f = state.facturas
            .slice(0, index)
            .concat([factura])
            .concat(state.facturas.slice(index + 1));
        return {
            ...state,
            facturas: f,
        };

    default:
        return state;
    }
};
