import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { Search } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import useTable from '../../components/useTable';
import { filterOnAnyField, dateAndTimeToMoment } from '../../utils';
import { refreshViajeCasetas, removeCaseta } from '../../redux/ducks/viajes';
import CasetasForm from './CasetasForm';
import Popup from '../../components/Popup';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConfirmDialogSimple from '../../components/ConfirmDialogSimple';
import { setButtonCasetas } from '../../redux/ducks/casetasButton';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
        width: '13%',
    },
    refreshButton: {},
}));

export default function Casetas({ setIsDirty, values, setValues }) {
    const [openPopupCasetas, setOpenPopupCasetas] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const [confirmDialogSimple, setConfirmDialogSimple] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });
    const dispatch = useDispatch();
    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    if (values.casetas != null) {
        values.casetas.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
    }

    const headCells = [
        { id: 'fecha', label: 'Fecha' },
        { id: 'caseta', label: 'Caseta' },
        { id: 'clase', label: 'Clase' },
        { id: 'tipoCaseta', label: 'Tipo Caseta' },
        { id: 'importe', label: 'Importe' },
        { id: 'editar', label: 'Editar' },
        { id: 'borrar', label: 'Borrar', disableSorting: true },
    ];
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(values.casetas, headCells, filterFn);
    const [filter, setFilter] = useState();

    function checkNewAddedCaseta(recentCaseta) {
        if (recentCaseta != null) {
            const newRecords = recentCaseta.filter((b) => (b.id < 0));
            const newRecord = newRecords[0];
            if (!newRecord) {
                return false;
            }

            return true;
        }

        return false;
    }

    const addSoloFecha = (itemToEdit) => {
        setRecordForEdit({ ...itemToEdit, soloFecha: itemToEdit.fecha, soloHora: itemToEdit.fecha });
    };

    const showDialog = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar la entrada?',
            isOpen: true,
            onConfirm: () => {
                const idx = values.casetas.findIndex(
                    (o) => o.id === item.id,
                );
                const casetaList = values.casetas
                    .slice(0, idx)
                    .concat(values.casetas.slice(idx + 1));

                if (item.viaje) {
                    dispatch(removeCaseta(item.viaje, item.id));
                }

                setValues({
                    ...values,
                    casetas: casetaList,
                });
            },
        });
    };

    const showDialogSimple = () => {
        setConfirmDialogSimple({
            subtitle: '',
            title: 'Antes de actualizar, necesitas guardar los nuevos datos de combustible que agregaste manualmente, usa el botón de guardar viaje en la parte de arriba de la pantalla',
            isOpen: true,
        });
    };
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };

    const refreshCasetas = () => {
        if (values.unidad) {
            if (!(checkNewAddedCaseta(values.casetas))) {
                setIsDirty(true);
                const fechaInicial = dateAndTimeToMoment(
                    values.fechaInicio,
                    values.fechaInicioHora,
                );
                const fechaFinal = dateAndTimeToMoment(
                    values.fechaFin,
                    values.fechaFinHora,
                );
                dispatch(
                    refreshViajeCasetas(
                        values.unidad.numeroEconomico,
                        false,
                        fechaInicial !== undefined
                            ? fechaInicial.toISOString(true)
                            : '',
                        fechaFinal !== undefined ? fechaFinal.toISOString(true) : '',
                    ),
                );
                if (values.casetas != null) {
                    values.casetas.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
                    dispatch(setButtonCasetas(true));
                }
            } else {
                showDialogSimple();
            }
        }
    };

    const addOrEdit = (caseta) => {
        const editedCasetaArray = values.casetas.filter((a) => (a.id === caseta.id));
        const editedCaseta = editedCasetaArray[0];
        if (!editedCaseta) {
            let addedCaseta = null;
            if (values.casetas.length === 0) {
                addedCaseta = {
                    ...caseta,
                    id: -1,
                    numeroEconomico: values.unidad.numeroEconomico,
                };
            } else {
                addedCaseta = {
                    ...caseta,
                    id: -1 * (values.casetas.length + 1),
                    numeroEconomico: values.unidad.numeroEconomico,
                };
            }
            const casetaList = values.casetas.concat(addedCaseta);
            casetaList.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
            setValues({
                ...values,
                casetas: casetaList,
            });
        } else {
            const listWithNoEditedCasetas = values.casetas.filter((a) => (a.id !== caseta.id));
            const listWithEditedCasetas = listWithNoEditedCasetas.concat(caseta);
            listWithEditedCasetas.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
            setValues({
                ...values,
                casetas: listWithEditedCasetas,
            });
        }
        setOpenPopupCasetas(false);
        setIsDirty(true);
    };

    const funcSetRecordForEdit = () => {
        setRecordForEdit(null);
    };

    const [total, setTotal] = useState({
        importe: 0.0,
    });

    useEffect(() => {
        let importe = 0;
        values.casetas.forEach((c) => {
            importe += Number(c.importe);
        });
        setTotal({
            importe,
        });
    }, [values.casetas]);
    return (
        <>
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.newButton1}
                        onClick={() => {
                            refreshCasetas();
                        }}
                        startIcon={<RefreshIcon />}
                        text='Actualizar Casetas desde Archivo'
                        variant='outlined'
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            funcSetRecordForEdit();
                            setOpenPopupCasetas(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    <Moment date={item.fecha} format='D MMM YYYY HH:mm:ss' />
                                </TableCell>
                                <TableCell>{item.caseta}</TableCell>
                                <TableCell>{item.clase}</TableCell>
                                <TableCell>{item.tipoCaseta}</TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.importe}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            setOpenPopupCasetas(true);
                                            addSoloFecha(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            showDialog(item);
                                        }}
                                    >
                                        <DeleteForeverIcon fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>

                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>
                                <Typography variant='h5'>
                                    Total
                                </Typography>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />

                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    thousandSeparator
                                    value={total.importe}
                                />
                            </TableCell>

                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <ConfirmDialogSimple
                confirmDialogSimple={confirmDialogSimple}
                setConfirmDialogSimple={setConfirmDialogSimple}
            />
            <Popup
                openPopup={openPopupCasetas}
                setOpenPopup={setOpenPopupCasetas}
                title='Casetas'
            >
                <CasetasForm
                    addOrEdit={addOrEdit}
                    funcSetRecordForEdit={funcSetRecordForEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </>
    );
}
