import http from '../../../http-common';

export function requestLoadOperadores(incluirAdministrativos, soloAdmins, inactivos) {
    return http.get(`/operadores?administrativos=${incluirAdministrativos}&soloAdmins=${soloAdmins}&inactivos=${inactivos}`);
}

export function requestSaveOperador(operador) {
    console.log(operador);
    return http.post('/operadores', operador);
}
