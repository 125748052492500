/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import getBancos from '../../services/operatorsService';
// import '../Gasolina/node_modules/react-dropzone-uploader/dist/styles.css'

const initialFieldValues = {
    id: undefined,
    tipoDescuento: '',
    saldoInicial: 0,
    saldoActual: 0,
    descuento: 0,
    recurrente: false,
};

export default function DescuentosForm(props) {
    const validate = (fieldValues = values) => {
        const temp = { ...errors };
        if ('tipoDescuento' in fieldValues) {
            temp.tipoDescuento = fieldValues.tipoDescuento ? '' : 'Campo Requerido';
        }

        if ('descuento' in fieldValues) {
            temp.descuento = fieldValues.descuento ? '' : 'Campo Requerido';
        }

        // eslint-disable-next-line no-use-before-define
        setErrors({
            ...temp,
        });
        // eslint-disable-next-line no-use-before-define
        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        body.append('operadorId', values.id);
        body.append('file', file);
        return { url: '/operadores/files', body };
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.tipoDescuento}
                        label='Tipo Descuento'
                        name='tipoDescuento'
                        onChange={handleInputChange}
                        value={values.tipoDescuento}
                    />

                    <Controls.NumericInput
                        error={errors.saldoInicial}
                        label='Saldo Inicial'
                        name='saldoInicial'
                        onChange={handleInputChange}
                        value={values.saldoInicial}
                    />

                    <Controls.NumericInput
                        error={errors.saldoActual}
                        label='Saldo Actual'
                        name='saldoActual'
                        onChange={handleInputChange}
                        value={values.saldoActual}
                    />
                    <Controls.NumericInput
                        error={errors.descuento}
                        label='Descuento'
                        name='descuento'
                        onChange={handleInputChange}
                        value={values.descuento}
                    />
                    <Controls.Checkbox
                        label='Recurrente'
                        name='recurrente'
                        onChange={handleInputChange}
                        value={values.recurrente}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>

                </Grid>
            </Grid>
        </Form>
    );
}
