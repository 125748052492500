import React, { useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PublishIcon from '@mui/icons-material/Publish';
import {
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { setSnackbar } from '../../redux/ducks/snackbar';
import { Controls } from '../../components/controls/Controls';
import UploadService from '../../services/FileUploadService';
import { loadViajeFiles } from '../../redux/ducks/viajes';

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

export default function Archivos(props) {
    const classes = useStyles();
    const { values } = props;
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const selectFile = (event) => {
        setSelectedFiles(event.target.files);
    };
    const dispatch = useDispatch();

    const remove = (file) => {
        UploadService.remove(values.id, 'viajes', file.id).then(() => {
            dispatch(setSnackbar(true, 'success', 'Archivo Eliminado'));
            dispatch(loadViajeFiles(values.id));
        });
    };
    const upload = () => {
        const c = selectedFiles[0];
        setProgress(0);
        setCurrentFile(c);
        UploadService.upload(c, 'tramos', values.id, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then(() => {
                dispatch(
                    setSnackbar(
                        true,
                        'success',
                        'Archivo cargado correctamente',
                    ),
                );
                dispatch(loadViajeFiles(values.id));
            })
            .catch(() => {
                setProgress(0);
                dispatch(setSnackbar(true, 'error', 'Error subiendo archivo'));
                setCurrentFile(undefined);
            });
        setSelectedFiles(undefined);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    {currentFile && (
                        <Box alignItems='center' display='flex'>
                            <Box mr={1} width='100%'>
                                <LinearProgress
                                    value={progress}
                                    variant='determinate'
                                />
                            </Box>
                            <Box>
                                <Typography
                                    color='textSecondary'
                                    variant='body2'
                                >
                                    {`${Math.round(progress)}%`}

                                </Typography>
                            </Box>
                        </Box>
                    )}
                    <Box display='flex'>
                        <input onChange={selectFile} type='file' />
                        <Controls.ActionButton
                            color='primary'
                            disabled={!selectedFiles}
                            onClick={() => {
                                upload();
                            }}
                        >
                            <PublishIcon fontSize='small' />
                        </Controls.ActionButton>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.title} variant='h6'>
                        Archivos Almacenados
                    </Typography>
                    <List dense>
                        {values.archivos.map((f) => (
                            <ListItem
                                key={f.id}
                                button
                                component='a'
                                href={f.presignedUrl}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={f.nombre} />
                                <ListItemSecondaryAction>
                                    <Controls.ActionButton
                                        onClick={() => {
                                            remove(f);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </Controls.ActionButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </>
    );
}
