import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
} from '@mui/material';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { makeStyles } from '@mui/styles';
import { Controls } from './controls/Controls';

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
    },
    dialogContent: {
        textAlign: 'center',
    },
    dialogTitle: {
        textAlign: 'center',
    },
    dialogActions: {
        justifyContent: 'center',
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem',
        },
    },
}));
export default function ConfirmDialogSimple(props) {
    const classes = useStyles();
    const { confirmDialogSimple, setConfirmDialogSimple } = props;
    return (
        <Dialog classes={{ paper: classes.dialog }} open={confirmDialogSimple.isOpen}>
            <DialogTitle className={classes.dialogTitle}>
                <IconButton className={classes.titleIcon} disableRipple>
                    <NotListedLocationIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant='h6'>{confirmDialogSimple.title}</Typography>
                <Typography variant='h5'>{confirmDialogSimple.subTitle}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Controls.Button
                    color='default'
                    onClick={() => setConfirmDialogSimple({ ...confirmDialogSimple, isOpen: false })}
                    text='Ok'
                />
            </DialogActions>
        </Dialog>
    );
}
