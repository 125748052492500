import React from 'react';
import { TextField } from '@mui/material';

export default function Input(props) {
    const {
        name, label, value, onChange, error = null, ...others
    } = props;
    return (
        <TextField
            label={label}
            name={name}
            onChange={onChange}
            value={value || ''}
            variant='outlined'
            {...(error && {
                error: true,
                helperText: error,
            })}
            {...others}
        />
    );
}
