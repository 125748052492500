import axios from 'axios';
import http from '../../../http-common';

export function requestLoadClientes() {
    return http.get('/clientes');
}

export function requestSaveCliente(cliente) {
    return http.post('/clientes', cliente);
}
