import http from '../http-common';

const upload = (file, prefix, id, onUploadProgress, extraData, pathId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id);
    if (extraData !== undefined) {
        Object.entries(extraData).forEach(([key, value]) => {
            formData.append(key, value);
        });
    }
    if (!pathId) {
        return http.post(`/${prefix}/files/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
    }
    return http.post(`/${prefix}/files/upload/${pathId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
};

export default {
    upload,
};
