import { call, put } from 'redux-saga/effects';
import { setCasetasLiq } from '../../ducks/casetasLiq';
import requestLoadCasetasLiq from '../requests/casetasLiq';

export default function* handleLoadCasetasLiq(action) {
    try {
        const response = yield call(requestLoadCasetasLiq, action.viajeId);
        const { data } = response;
        yield put(setCasetasLiq(data));
    } catch (error) {
        console.log(error);
    }
}
