/* eslint-disable react-hooks/exhaustive-deps */
import {
    InputAdornment, Paper, TableBody, TableCell, TableRow, Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForever, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { filterOnAnyField } from '../../utils';
import DescuentosForm from './DescuentosForm';
import ConfirmDialog from '../../components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    fab: {
        margin: theme.spacing(2),
    },
}));
const headCells = [
    { id: 'id', label: 'Id' },
    { id: 'tipoDescuento', label: 'Tipo' },
    { id: 'saldoInicial', label: 'Saldo Inicial' },
    { id: 'saldoActual', label: 'Saldo Actual' },
    { id: 'descuento', label: 'Descuento' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

export default function DescuentoOperador() {
    const { operadorId } = useParams();

    const [openPopup, setOpenPopup] = useState(false);
    const [operador, setOperador] = useState();
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const operadores = useSelector((state) => state.operadores.operadores);
    const [records, setRecords] = useState([]);
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };

    const addOrEdit = async (record, resetForm) => {
        let tmpOp;
        if (record.id === undefined) {
            tmpOp = {
                ...operador,
                descuentos: [
                    ...operador.descuentos,
                    record,
                ],
            };
        } else {
            const idx = operador.descuentos.findIndex((f) => f.id === record.id);
            if (idx !== -1) {
                tmpOp = {
                    ...operador,
                    descuentos: [
                        ...operador.descuentos.slice(0, idx),
                        record,
                        ...operador.descuentos.slice(idx + 1),
                    ],
                };
            }
        }
        if (tmpOp) {
            const result = await http.post('/operadores', tmpOp);
            setOperador(result.data);

            setOpenPopup(false);
            resetForm();
        }
    };
    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    useEffect(() => {
        if (operador) {
            setRecords(operador.descuentos);
        }
    }, [operador]);
    async function fetchData() {
        const response = await http.get(`/operadores/${operadorId}`);
        setOperador(response.data);
    }
    useEffect(() => {
        fetchData();
    }, [operadorId]);

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteDescuento = (item) => {
        console.log(item);
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar el Descuento?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/operadores/${operadorId}/descuento/${item.id}`);
                fetchData();
            },
        });
    };

    return (
        <>
            <PageHeader
                icon={<PeopleOutlineTwoToneIcon fontSize='large' />}
                subtitle=''
                title={`${operador?.nombre} ${operador?.apellidos}`}
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopup(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        {item.id}
                                    </TableCell>
                                    <TableCell>
                                        {item.tipoDescuento}
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            decimalScale={2}
                                            displayType='text'
                                            prefix='$'
                                            thousandSeparator
                                            value={item.saldoInicial}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            decimalScale={2}
                                            displayType='text'
                                            prefix='$'
                                            thousandSeparator
                                            value={item.saldoActual}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            decimalScale={2}
                                            displayType='text'
                                            prefix='$'
                                            thousandSeparator
                                            value={item.descuento}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Controls.ActionButton
                                            color='primary'
                                            onClick={() => {
                                                openInPopup(item);
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize='small' />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            color='primary'
                                            onClick={() => {
                                                deleteDescuento(item);
                                            }}
                                        >
                                            <DeleteForever fontSize='small' />
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Operador'
            >
                <DescuentosForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
