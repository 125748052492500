import {
    createTheme,
    CssBaseline,
    ThemeProvider,
    Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useState } from 'react';
import {
    BrowserRouter as Router, Route, Switch, useHistory,
} from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import Header from '../components/Header';
import SideMenu from '../components/SideMenu';
import Snackbar from '../components/Snackbar';
import Home from '../Home';
import Carga from '../pages/CargaArchivos/Carga';
import Clientes from '../pages/Clientes/Clientes';
import Gasolina from '../pages/Gasolina/Gasolina';
import Casetas from '../pages/Casetas_New/Casetas_New';
import Operators from '../pages/Operators/Operators';
import Unidades from '../pages/Unidades/Unidades';
import Facturas from '../pages/Facturas/Facturas';
import GPS from '../pages/GPS/GPS';
import Viaje from '../pages/Viaje/Viaje';
import Viajes from '../pages/Viajes/Viajes';
import LiquidacionOperadores from '../pages/liquidacion/Operadores';
import LiquidacionViajes from '../pages/liquidacion/Viajes';
import Usuarios from '../pages/usuarios/usuarios';
import DescuentoOperador from '../pages/Operators/DescuentoOperador';
import Referenciados from '../pages/liquidacion/Referenciados';
import Admin from '../pages/usuarios/Admin';
import Pagos from '../pages/Operators/Pagos';
import LiquidacionAdmin from '../pages/liquidacion/Admin';
import PagosAdmin from '../pages/Operators/PagosAdmin';
import { ReporteUtilidad } from '../pages/Reportes/ReporteUtilidad';
import { GastosExtra } from '../pages/gastosextra/GastosExtra';
import Ubicaciones from '../pages/Ubicaciones/Ubicaciones';
import Monitoreo from '../pages/Monitoreo/Monitoreo';

const useStyles = makeStyles({
    appMain: {
        paddingLeft: ' 0px',
        width: '100%',
        display: 'flex',
    },
});
const theme = createTheme({
    palette: {
        primary: {
            main: '#333996',
            light: '#3c44b126',
        },
        secondary: {
            main: '#f83245',
            light: '#f8324526',
        },
        background: {
            default: '#f4f5fd',
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                transform: 'translateZ(0)',
            },
        },
    },
    props: {
        MuiIconButton: {
            disableRipple: true,
        },
    },
});

export default function App() {
    const classes = useStyles();
    const [clickOnViaje, setClickOnViaje] = useState(true);

    return (

        <>
            <ThemeProvider theme={theme}>
                <div className={classes.appMain}>

                    <SideMenu />

                    <Grid container spacing={3}>
                        <Snackbar />
                        <Grid item xs={12}>
                            <Header />
                        </Grid>
                        <Grid item xs={12}>
                            <Switch>
                                <SecureRoute path='/operadores/:operadorId'>
                                    <DescuentoOperador />
                                </SecureRoute>
                                <SecureRoute path='/operadores'>
                                    <Operators />
                                </SecureRoute>
                                <SecureRoute path='/unidades'>
                                    <Unidades />
                                </SecureRoute>
                                <SecureRoute path='/clientes'>
                                    <Clientes />
                                </SecureRoute>
                                <SecureRoute path='/ubicaciones'>
                                    <Ubicaciones />
                                </SecureRoute>
                                <SecureRoute path='/monitoreo'>
                                    <Monitoreo />
                                </SecureRoute>
                                <SecureRoute path='/viajes'>
                                    <Viajes />
                                </SecureRoute>
                                <SecureRoute path='/viaje/:viajeId'>
                                    <Viaje />
                                </SecureRoute>
                                <SecureRoute path='/viaje'>
                                    <Viaje />
                                </SecureRoute>
                                <SecureRoute path='/carga'>
                                    <Carga />
                                </SecureRoute>
                                <SecureRoute path='/gasolina'>
                                    <Gasolina />
                                </SecureRoute>
                                <SecureRoute path='/gps'>
                                    <GPS />
                                </SecureRoute>
                                <SecureRoute path='/casetas'>
                                    <Casetas />
                                </SecureRoute>
                                <SecureRoute path='/liquidacionop'>
                                    <LiquidacionOperadores />
                                </SecureRoute>
                                <SecureRoute path='/liquidacionvi'>
                                    <LiquidacionViajes />
                                </SecureRoute>
                                <SecureRoute path='/facturas'>
                                    <Facturas />
                                </SecureRoute>
                                <SecureRoute path='/usuarios'>
                                    <Usuarios />
                                </SecureRoute>
                                <SecureRoute path='/referenciados'>
                                    <Referenciados />
                                </SecureRoute>
                                <SecureRoute path='/pagoOperadores'>
                                    <Pagos />
                                </SecureRoute>
                                <SecureRoute path='/admin'>
                                    <Admin />
                                </SecureRoute>
                                <SecureRoute path='/liquidacionAdmin'>
                                    <LiquidacionAdmin />
                                </SecureRoute>
                                <SecureRoute path='/pagoAdmin'>
                                    <PagosAdmin />
                                </SecureRoute>
                                <SecureRoute path='/reporteUtilidad'>
                                    <ReporteUtilidad />
                                </SecureRoute>
                                <SecureRoute path='/gastosExtra'>
                                    <GastosExtra />
                                </SecureRoute>

                                <SecureRoute path='/'>
                                    <Home />
                                </SecureRoute>

                            </Switch>
                        </Grid>
                    </Grid>

                </div>
                <CssBaseline />
            </ThemeProvider>
        </>

    );
}
