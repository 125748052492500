import { call, put } from 'redux-saga/effects';
import { setUnidades, savedUnidad } from '../../ducks/unidades';
import { requestLoadUnidades, requestSaveUnidad } from '../requests/unidades';

export function* handleLoadUnidades() {
    try {
        const response = yield call(requestLoadUnidades);
        const { data } = response;
        yield put(setUnidades(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveUnidad(action) {
    try {
        const response = yield call(requestSaveUnidad, action.unidad);
        const { data } = response;
        yield put(savedUnidad(data));
    } catch (error) {
        console.log(error);
    }
}
