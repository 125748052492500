import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import NumberFormat from 'react-number-format';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import { loadCasetasNew } from '../../redux/ducks/casetas_New';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { filterOnAnyField, dateAndTimeToMoment } from '../../utils';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'numeroEconomico', label: 'Vehiculo' },
    { id: 'fecha', label: 'Fecha/Hora' },
    { id: 'caseta', label: 'Caseta' },
    { id: 'clase', label: 'Clase' },
    { id: 'tipoCaseta', label: 'Tipo' },
    { id: 'importe', label: 'Importe' },
];

export default function Gasolina() {
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const casetas = useSelector((state) => state.casetasNew.casetas);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(casetas, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCasetasNew());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(2, 'Other'));
    }, [dispatch]);

    return (
        <>
            <PageHeader
                icon={<LocalGasStationIcon fontSize='large' />}
                subtitle=''
                title='Casetas'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                        // onClick={() => dispatch(loadOUnidades())}
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.numeroEconomico}</TableCell>
                                <TableCell>{item.fecha}</TableCell>
                                <TableCell>{item.caseta}</TableCell>
                                <TableCell>{item.clase}</TableCell>
                                <TableCell>{item.tipoCaseta}</TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.importe}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
        </>
    );
}
