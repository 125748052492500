import {
    InputAdornment, TableBody, TableCell, TableRow, Toolbar, Typography, Box,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
    AddCircleOutline, AddIcCallTwoTone, DeleteOutlineOutlined, Search, UploadFileOutlined,
} from '@mui/icons-material';
import moment from 'moment';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { filterOnAnyField } from '../../utils';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import Popup from '../../components/Popup';
import UbicacionesViajeForm from './UbicacionesViajeForm';
import ConfirmDialog from '../../components/ConfirmDialog';
import FileUploadService from '../../services/FileUploadService';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'tramoId', label: 'Tramo' },
    { id: 'descripcionUbicacion', label: 'Ubicacion' },
    { id: 'eta', label: 'Tiempo estimado llegada' },
    { id: 'ata', label: 'Tiempo real de llegada' },
    { id: 'detalles', label: 'Detalles' },
    { id: 'origenDestino', label: 'Origen/Destino' },
    { id: 'ordenEntrega', label: 'Orden de entrega' },
    { id: 'entregaTerminada', label: 'Entrega Terminada' },
    { id: 'actions', label: 'Acciones', disableSorting: true },

    // { id: 'actions', label: 'Acciones', disableSorting: true },
];
export default function UbicacionesViaje({
    viajeId, tramos,
}) {
    const [ubicaciones, setUbicaciones] = useState([]);
    const [records, setRecords] = useState([]);
    const fetchData = async () => {
        const data = await http.get(`/viajes/ubicacionViaje/${viajeId}`);
        setRecords(data.data);
        console.log(records);
    };
    const fetchDataUbicaciones = async () => {
        const data = await http.get('/ubicaciones');
        setUbicaciones(data.data);
    };
    useEffect(() => {
        fetchData();
        fetchDataUbicaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viajeId]);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);

    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };
    const classes = useStyles();
    const [openPopup, setOpenPopup] = useState(false);
    const hiddenFileInput = useRef(null);
    const currentItem = useRef(null);
    const repeatControl = useRef(null);

    const uploadFile = (item) => {
        if (repeatControl.current === null) {
            repeatControl.current = item.id;
            currentItem.current = item;
        }

        hiddenFileInput.current.click();

        if (repeatControl.current !== null) {
            repeatControl.current = null;
        }
        console.log({ a: currentItem.current });
    };

    const handleFileChange = async (event) => {
        console.log({ a: currentItem.current });
        const fileUploaded = event.target.files[0];
        await FileUploadService.upload(fileUploaded, 'ubicaciones', currentItem.current.id, () => {}, undefined, currentItem.current.id);
        await fetchData();
    };

    const [recordForEdit, setRecordForEdit] = useState();
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteUbicacion = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar la Ubicacion?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/viajes/ubicacionViaje/${item.id}`);
                await fetchData();
            },
        });
    };
    const addOrEdit = async (ubicacion, resetForm) => {
        await http.post('/viajes/ubicacionViaje', {
            ...ubicacion,
            viajeId,
            tramoId: ubicacion.tramoId.id,
            ubicacionId: ubicacion.ubicacionId.id,
            eta: ubicacion.eta ? moment.utc(ubicacion.eta).toISOString() : null,
            ata: ubicacion.ata ? moment.utc(ubicacion.ata).toISOString() : null,
        });
        // dispatch(saveCliente(cliente));
        setOpenPopup(false);
        resetForm();
        fetchData();
    };

    const openInPopup = (item) => {
        const tramoActual = tramos.find((x) => (x.id === item.tramoId));
        console.log(tramos);
        console.log(item);
        console.log(tramoActual);
        setRecordForEdit({ ...item, tramoId: tramoActual });
        setOpenPopup(true);
    };

    return (
        <>
            <Toolbar>
                <Controls.Input
                    className={classes.searchInput}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='end'>
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    label='Buscar'
                    onChange={handleSearch}
                    value={filter}
                />

                <Controls.Button
                    className={classes.newButton}
                    onClick={() => {
                        setRecordForEdit(null);
                        setOpenPopup(true);
                    }}
                    startIcon={<AddCircleOutline />}
                    text='Agregar Nuevo'
                    variant='outlined'
                />
            </Toolbar>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.tramoId}</TableCell>
                            <TableCell>{item.descripcionUbicacion}</TableCell>
                            <TableCell><Moment date={item.eta} format='D MMM YYYY hh:mm' /></TableCell>
                            <TableCell><Moment date={item.ata} format='D MMM YYYY hh:mm' /></TableCell>
                            <TableCell>{item.detalles}</TableCell>
                            <TableCell>{item.origenDestino}</TableCell>
                            <TableCell>{item.ordenEntrega}</TableCell>
                            <TableCell>
                                {item.entregaTerminada ? 'SI' : 'NO'}
                            </TableCell>
                            <TableCell>

                                <Controls.ActionButton
                                    color='primary'
                                    onClick={() => {
                                        openInPopup(item);
                                    }}
                                >
                                    <EditOutlinedIcon fontSize='small' />
                                </Controls.ActionButton>

                                <Controls.ActionButton
                                    color='primary'
                                    onClick={() => {
                                        deleteUbicacion(item);
                                    }}
                                >
                                    <DeleteOutlineOutlined fontSize='small' />
                                </Controls.ActionButton>
                                <Controls.ActionButton
                                    color='primary'
                                    onClick={() => {
                                        uploadFile(item);
                                    }}
                                >
                                    <UploadFileOutlined fontSize='small' />
                                    <input
                                        ref={hiddenFileInput}
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        type='file'
                                    />
                                </Controls.ActionButton>
                                {
                                    item.fileName ? <Box><a href={item.url} rel='noreferrer' target='_blank'>{item.fileName}</a></Box> : <></>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TblContainer>
            <TblPagination />

            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Ubicación'
            >
                <UbicacionesViajeForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                    tramos={tramos}
                    ubicaciones={ubicaciones}
                />
            </Popup>

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
