/* eslint-disable import/no-named-default */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AdapterDateFns from '@date-io/date-fns';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        '&.MuiFormControl-root': {
            width: '100%',
            paddingLeft: theme.spacing(0),
        },
    },
}));

export default function DatePicker(props) {
    const classes = useStyles();
    const {
        name, label, value, onChange, error = null,
    } = props;

    const convertToDefEventParameter = (n, v) => ({
        target: {
            name: n,
            value: v,
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
                autoOk
                format='MMM/dd/yyyy'
                inputVariant='outlined'
                label={label}
                name={name}
                onChange={(date) => onChange(convertToDefEventParameter(name, date))}
                renderInput={(params) => <TextField {...params} />}
                value={value || null}
                {...(error && {
                    error: true,
                    helperText: error,
                })}
                className={classes.root}
            />
        </LocalizationProvider>
    );
}
