import { call, put } from 'redux-saga/effects';
import { setTramos } from '../../ducks/tramos';
import requestLoadTramos from '../requests/tramos';

export default function* handleLoadTramos() {
    try {
        const response = yield call(requestLoadTramos);
        const { data } = response;
        yield put(setTramos(data));
    } catch (error) {
        console.log(error);
    }
}
