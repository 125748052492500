import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { Search } from '@mui/icons-material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import { loadGpsall } from '../../redux/ducks/gpsall';
import { setIndex } from '../../redux/ducks/sideMenuIndex';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'dt', label: 'DT' },
    { id: 'lat', label: 'Latitud' },
    { id: 'lng', label: 'Longitud' },
    { id: 'altitude', label: 'Altitud' },
    { id: 'speed', label: 'Velocidad' },
];

export default function GPS() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const gpsall = useSelector((state) => state.gpsall.gpsall);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(gpsall, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return items.filter((x) => x.dt.toLowerCase().includes(target.value.toLowerCase()));
            },
        });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIndex(1, 'Other'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(loadGpsall());
    }, [dispatch]);

    return (
        <>
            <PageHeader
                icon={<GpsFixedIcon fontSize='large' />}
                subtitle=''
                title='GPS Information'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        onClick={() => dispatch(loadGpsall())}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.dt}</TableCell>
                                <TableCell>{item.lat}</TableCell>
                                <TableCell>{item.lng}</TableCell>
                                <TableCell>{item.altitude}</TableCell>
                                <TableCell>{item.speed}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='GPS'
            />
        </>
    );
}
