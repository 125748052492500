import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0.5),
        padding: '1',
    },
    label: {
        textTransform: 'none',
    },
}));
export default function Button(props) {
    const classes = useStyles();

    const {
        text, size, color, variant, onClick, ...other
    } = props;

    return (
        <MuiButton
            color='primary'
            onClick={onClick}
            size={size || 'large'}
            variant={variant || 'contained'}
            {...other}
            classes={{ root: classes.root, label: classes.label }}
            sx={{ padding: '1' }}
        >
            {text}
        </MuiButton>
    );
}
