import moment from 'moment';

function findVal(object, value) {
    if (object === null) {
        return false;
    }
    const x = Object.values(object).some((k) => {
        let compare = k;
        if (typeof k === 'string') {
            const fecha = moment(k, moment.ISO_8601, true);
            if (fecha.isValid()) {
                compare = fecha.format('D MMM YYYY HH:mm:ss');
            }
        }

        if (compare && typeof compare === 'object') {
            const r = findVal(compare, value);
            if (r) {
                return true;
            }
        } else if (compare && compare.toString().toLowerCase().includes(value)) {
            return true;
        }
        return false;
    });
    return x;
}
export function filterOnAnyField(arr, searchKey) {
    const itm = arr.filter((obj) => findVal(obj, searchKey));

    return itm;
}

export function dateAndTimeToMoment(date, time) {
    let fecha;
    if (!date) return undefined;
    if (time !== null) {
        const fechaInicio = moment(date).format('YYYY-MM-DD');
        const horaInicio = moment(time).format('HH:mm');
        fecha = moment(`${fechaInicio} ${horaInicio}:00`);
    } else if (date !== null) {
        const fechaInicio = moment(date).format('YYYY-MM-DD');
        fecha = moment(`${fechaInicio} 00:00:00`);
    }
    return fecha;
}
