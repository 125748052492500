/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm, Form } from '../../components/useForm';
import { Controls } from '../../components/controls/Controls';

const initialFieldValues = {
    id: 0,
    numeroEconomico: '',
    ano: '',
    numeroEje: '',
    tagZacatecas: '',
    tagGasolina: '',
    codigoAsignado: '',
    tag: '',
    activa: true,
    rendimiento: '',
    monitoreo: true,
};

export default function UnidadesForm(props) {
    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ('numeroEconomico' in fieldValues) {
            temp.numeroEconomico = fieldValues.numeroEconomico
                ? ''
                : 'Campo Requerido';
        }

        if ('ano' in fieldValues) {
            temp.ano = fieldValues.ano ? '' : 'Año Incorrecto';
        }
        if ('numeroEje' in fieldValues) {
            temp.numeroEje = fieldValues.numeroEje ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return false;
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(values);
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Checkbox
                        label='Activa'
                        name='activa'
                        onChange={handleInputChange}
                        value={values.activa}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.numeroEconomico}
                        label='Numero Economico'
                        name='numeroEconomico'
                        onChange={handleInputChange}
                        value={values.numeroEconomico}
                    />

                    <Controls.Input
                        error={errors.ano}
                        label='Año'
                        name='ano'
                        onChange={handleInputChange}
                        value={values.ano}
                    />

                    <Controls.Input
                        error={errors.numeroEje}
                        label='Numero de Ejes'
                        name='numeroEje'
                        onChange={handleInputChange}
                        value={values.numeroEje}
                    />

                    <Controls.Input
                        label='Rendimiento'
                        name='rendimiento'
                        onChange={handleInputChange}
                        value={values.rendimiento}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.codigoAsignado}
                        label='Codigo Asignado'
                        name='codigoAsignado'
                        onChange={handleInputChange}
                        value={values.codigoAsignado}
                    />
                    <Controls.Input
                        label='Tag ID México'
                        name='tag'
                        onChange={handleInputChange}
                        value={values.tag}
                    />
                    <Controls.Input
                        label='Tag Zacatecas'
                        name='tagZacatecas'
                        onChange={handleInputChange}
                        value={values.tagZacatecas}
                    />
                    <Controls.Input
                        label='Tag Gasolina'
                        name='tagGasolina'
                        onChange={handleInputChange}
                        value={values.tagGasolina}
                    />
                    <Controls.Checkbox
                        label='Mostrar En Monitoreo'
                        name='monitoreo'
                        onChange={handleInputChange}
                        value={values.monitoreo}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
