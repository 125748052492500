/* eslint-disable no-plusplus */
import { PeopleOutlineTwoTone } from '@mui/icons-material';
import {
    Grid, Box, Autocomplete, Paper, TextField,

    TableBody, TableCell, TableRow, TableHead,
} from '@mui/material';
import NumberFormat from 'react-number-format';

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Moment from 'react-moment';
// import { Table } from 'react-virtualized';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import { Prompt, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { Controls } from '../../components/controls/Controls';
import { useForm } from '../../components/useForm';
import { loadUnidades } from '../../redux/ducks/unidades';
import http from '../../http-common';
import useTableNoPagination from '../../components/useTableNoPagination';
import { filterOnAnyField } from '../../utils';
import ConfirmDialogSimple from '../../components/ConfirmDialogSimple';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
    tableTotal: {
        background: '#fffacd',
    },
}));

const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const yrs = [];
const currentYear = new Date().getFullYear();

for (let i = 2021; i <= currentYear; i++) {
    yrs.push(i);
}

const initialData = {
    unidad: null,
    mes: '',
    year: currentYear,
};

const headCells = [
    { id: 'fecha', label: 'Fecha' },
    { id: 'folio', label: 'Folio' },
    { id: 'referenciado', label: 'Referenciado' },
    { id: 'unidad', label: 'Unidad' },
    { id: 'operador', label: 'Operador' },
    { id: 'rendimiento', label: 'Rendimiento' },
    { id: 'precioUnitario', label: 'Precio Unitario' },
    { id: 'kilometros', label: 'Kilometros' },
    { id: 'gastos', label: 'Gastos' },
    { id: 'precio', label: 'Precio' },
    { id: 'utilidad', label: 'Utilidad' },
    { id: 'porcentajeUtilidad', label: 'Porcentaje de Utilidad' },
    { id: 'faltante', label: 'Faltante' },
];

const headCellsTotales = [
    { id: 'unidad', label: 'Unidad' },
    { id: 'gastos', label: 'Gastos' },
    { id: 'precio', label: 'Precio' },
    { id: 'porcentajeUtilidad', label: 'Porcentaje Utilidad' },
    { id: 'faltante', label: 'Faltante' },
    { id: 'utilidad', label: 'Utilidad' },
];

export function ReporteUtilidad() {
    const classes = useStyles();

    const {
        values, setValues, errors, setErrors, resetForm,
    } = useForm(initialData, false, undefined);

    const [records, setRecords] = useState([]);
    const [totalesUtilidades, setTotalesUtilidades] = useState([]);
    const [finalRow, setFinalRow] = useState([]);
    const [showPrompt, setShowPrompt] = useState(false);

    const handleUnidadInputChange = (e, v) => {
        setValues({
            ...values,
            unidad: v,
        });
    };

    const [confirmDialogSimple, setConfirmDialogSimple] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const handleMesInputChange = (e, v) => {
        setValues({
            ...values,
            mes: v,
        });
    };

    const handleYearInputChange = (e, v) => {
        setValues({
            ...values,
            year: v,
        });
    };

    const unidades = useSelector((state) => state.unidades.unidades);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    const showDialogSimple = () => {
        setConfirmDialogSimple({
            subtitle: '',
            title: 'Selecciona un Mes',
            isOpen: true,
        });
    };

    const refresh = async () => {
        if (values.mes) {
            const data = await http.get(`/viajes/utilidad?mes=${values.mes}&year=${values.year}`);
            if (data.data) {
                const result = [];
                const totales = {
                    unidad: 'TOTAL', utilidad: 0, gastos: 0, precio: 0, count: 0, porcentajeUtilidad: 0, faltante: 0,
                };
                const utilidad = data.data;
                const allReferenciados = new Set(utilidad.filter((f) => f.referenciado).map((f) => f.referenciado));
                const allReferenciadosMap = utilidad.filter((f) => f.referenciado);

                const allFoliosReferenciados = new Set(utilidad.filter((f) => f.referenciado).map((f) => f.folio));
                console.log(allFoliosReferenciados);
                console.log(allReferenciados);
                utilidad.reduce((res, value) => {
                    if (!allReferenciados.has(value.folio) && !allFoliosReferenciados.has(value.folio)) {
                        if (!res[value.unidad]) {
                            res[value.unidad] = {
                                unidad: value.unidad, utilidad: 0, gastos: 0, precio: 0, count: 0, porcentajeUtilidad: 0, faltante: 0,
                            };
                            result.push(res[value.unidad]);
                        }
                        res[value.unidad].utilidad += value.utilidad;
                        res[value.unidad].gastos += value.gastos;
                        res[value.unidad].precio += value.precio;
                        res[value.unidad].faltante += (value.faltante ? value.faltante : 0);
                        res[value.unidad].count += 1;
                        res[value.unidad].porcentajeUtilidad += value.porcentajeUtilidad;
                    }
                    totales.utilidad += value.utilidad;
                    totales.gastos += value.gastos;
                    totales.precio += value.precio;
                    totales.faltante += (value.faltante ? value.faltante : 0);
                    totales.count += 1;
                    totales.porcentajeUtilidad += value.porcentajeUtilidad;
                    return res;
                }, {});

                allReferenciados.forEach((folioReferenciado) => {
                    const referenciados = allReferenciadosMap.filter((f) => f.referenciado === folioReferenciado);
                    const folio = utilidad.filter((f) => f.folio === folioReferenciado)[0];

                    const temp = {
                        unidad: folio.unidad, utilidad: folio.utilidad, gastos: folio.gastos, precio: folio.precio, count: 1, porcentajeUtilidad: folio.porcentajeUtilidad, faltante: folio.faltante,
                    };
                    referenciados.forEach((value) => {
                        temp.unidad = `${temp.unidad} - ${value.unidad}`;
                        temp.utilidad += value.utilidad;
                        temp.gastos += value.gastos;
                        temp.precio += value.precio;
                        temp.faltante += value.faltante;
                        temp.count += 1;
                        temp.porcentajeUtilidad += value.porcentajeUtilidad;
                    });
                    result.push(temp);
                });

                // result.push(totales);
                setFinalRow(totales);
                setTotalesUtilidades(result);
                setRecords(data.data);
            }
        } else {
            showDialogSimple();
        }
    };

    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [filterFnTotales, setFilterFnTotales] = useState({
        fn: (items) => items,
    });
    const [filterFnFinalRow, setFilterFnFinalRow] = useState({
        fn: (items) => items,
    });
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTableNoPagination(records, headCells, filterFn);

    const headCellsFinalRow = [
        { id: 'fecha', label: 'TOTAL' },
        { id: 'folio', label: '********' },
        { id: 'referenciado', label: '************' },
        { id: 'unidad', label: '************' },
        { id: 'operador', label: '*********************************' },
        { id: 'rendimiento', label: '**********' },
        { id: 'precioUnitario', label: '**************' },
        { id: 'kilometros', label: '************' },
        { id: 'espacio1', label: '******' },
        { id: 'gastos', label: (finalRow.gastos ? (Math.round(finalRow.gastos * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
        { id: 'precio', label: (finalRow.precio ? (Math.round(finalRow.precio * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
        { id: 'utilidad', label: (finalRow.utilidad ? (Math.round(finalRow.utilidad * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
        { id: 'porcentajeUtilidad', label: (finalRow.porcentajeUtilidad ? `  ${Math.round((finalRow.porcentajeUtilidad * 100) / 100 / finalRow.count)}%  ` : '') },
        { id: 'espacio2', label: '********' },
        { id: 'faltante', label: (finalRow.faltante ? (Math.round(finalRow.faltante * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
    ];

    const {
        TblContainer: TblContainerTotales,
        TblHead: TblHeadTotales,
        recordsAfterPagingAndSorting: recordsAfterPagingAndSortingTotales,
    } = useTableNoPagination(totalesUtilidades, headCellsTotales, filterFnTotales);

    const headCellsTotalesFinalRow = [
        { id: 'unidad', label: 'TOTAL *****' },
        { id: 'gastos', label: (finalRow.gastos ? (Math.round(finalRow.gastos * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
        { id: 'precio', label: (finalRow.precio ? (Math.round(finalRow.precio * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
        { id: 'porcentajeUtilidad', label: (finalRow.porcentajeUtilidad ? `  ${Math.round((finalRow.porcentajeUtilidad * 100) / 100 / finalRow.count)}%  ` : '') },
        { id: 'espacio', label: '************' },
        { id: 'faltante', label: (finalRow.faltante ? (Math.round(finalRow.faltante * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
        { id: 'utilidad', label: (finalRow.utilidad ? (Math.round(finalRow.utilidad * 100) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '') },
    ];

    return (

        <div>

            <Box displayPrint='none'>
                <PageHeader
                    icon={<PeopleOutlineTwoTone fontSize='large' />}
                    subtitle=''
                    title='Utilidades por Unidad'
                />
            </Box>
            <Paper className={classes.pageContent}>
                <Box displayPrint='none'>
                    <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        spacing={1}
                    >
                        <Grid item xs={3}>
                            <Autocomplete
                                getOptionLabel={(option) => `${option} `}
                                id='tags-standard'
                                name='mes'
                                onChange={handleMesInputChange}
                                options={meses}
                                renderInput={(params) => (
                                    <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label='Mes'
                                        placeholder='Mes'
                                        variant='standard'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                defaultValue={currentYear}
                                getOptionLabel={(option) => `${option} `}
                                id='tags-standard'
                                name='year'
                                onChange={handleYearInputChange}
                                options={yrs}
                                renderInput={(params) => (
                                    <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label='Año'
                                        placeholder='Año'
                                        variant='standard'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Controls.Button
                                onClick={() => { refresh(); }}
                                text='Actualizar'
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Paper>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {
                        recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.folio}>
                                <TableCell>
                                    <Moment date={item.fecha} format='D MMM YYYY HH:mm' />
                                </TableCell>
                                <TableCell>
                                    {item.folio}
                                </TableCell>
                                <TableCell>
                                    {item.referenciado}
                                </TableCell>
                                <TableCell>
                                    {item.unidad}
                                </TableCell>
                                <TableCell>
                                    {item.operador}
                                </TableCell>
                                <TableCell>
                                    {Math.round(item.rendimiento * 100) / 100}
                                </TableCell>
                                <TableCell>
                                    {Math.round(item.precioUnitario * 100) / 100}
                                </TableCell>
                                <TableCell>
                                    {Math.round(item.kilometros * 100) / 100}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.gastos}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.precio}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.utilidad}
                                    />
                                </TableCell>
                                <TableCell>
                                    {Math.round(item.porcentajeUtilidad * 100) / 100}
                                    %
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.faltante}
                                    />
                                </TableCell>

                            </TableRow>
                        ))
                    }
                </TableBody>

            </TblContainer>
            <Table>
                <TableHead className={classes.tableTotal}>
                    <TableRow>
                        {headCellsFinalRow.map((head) => (
                            <TableCell key={head.unidad}>
                                {head.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            </Table>
            <TblPagination />
            <TblContainerTotales>
                <TblHeadTotales />
                <TableBody>
                    {
                        recordsAfterPagingAndSortingTotales().map((item) => (
                            <TableRow key={item.unidad}>
                                <TableCell>
                                    {item.unidad}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.gastos}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.precio}
                                    />
                                </TableCell>
                                <TableCell>
                                    <div style={{ marginLeft: 50 }}>
                                        {Math.round((item.porcentajeUtilidad * 100) / 100 / item.count)}
                                        %
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.faltante}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.utilidad}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </TblContainerTotales>
            <Table>
                <TableHead className={classes.tableTotal}>
                    <TableRow>
                        {headCellsTotalesFinalRow.map((head) => (
                            <TableCell key={head.unidad}>
                                {head.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            </Table>
            <ConfirmDialogSimple
                confirmDialogSimple={confirmDialogSimple}
                setConfirmDialogSimple={setConfirmDialogSimple}
            />

        </div>
    );
}
