/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Prompt, useParams } from 'react-router-dom';
import { Form, useForm } from '../../components/useForm';
import Tramos from './Tramos';
import Generales from './Generales';
import ConfirmDialog from '../../components/ConfirmDialog';
import TopViaje from './TopViaje';
import Toolbar from './Toolbar';
import Gastos from './Gastos';
import Archivos from './Archivos';
import { Controls } from '../../components/controls/Controls';
import Combustible from './Combustible';
import Casetas from './Casetas';

import { loadViaje, updateViaje } from '../../redux/ducks/viajes';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { setButton } from '../../redux/ducks/updateButton';
import { setButtonCasetas } from '../../redux/ducks/casetasButton';
import Monitoreo from './Monitoreo';
import UbicacionesViaje from './UbicacionesViaje';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Viaje(props) {
    const { viajeId } = useParams();

    const [isDirty, setIsDirty] = useState(false);
    const dispatch = useDispatch();
    const selectedViaje = useSelector((state) => state.viajes.selectedViaje);
    const updateButton = useSelector((state) => state.updateButton);
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ('operador' in fieldValues) {
            temp.operador = fieldValues.operador ? '' : 'Campo Requerido';
        }
        if ('fechaInicio' in fieldValues) {
            temp.fechaInicio = fieldValues.fechaInicio ? '' : 'Campo Requerido';
        }
        if ('fechaInicioHora' in fieldValues) {
            temp.fechaInicioHora = fieldValues.fechaInicioHora ? '' : 'Campo Requerido';
        }
        if ('unidad' in fieldValues) {
            temp.unidad = fieldValues.unidad ? '' : 'Campo Requerido';
        }
        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    };
    const {
        values, setValues, errors, setErrors, handleInputChange, resetForm,
    // eslint-disable-next-line no-use-before-define
    } = useForm(selectedViaje, true, validate);

    useEffect(() => {
        setValues(selectedViaje);
    }, [selectedViaje, setValues]);

    useEffect(() => {
        dispatch(updateViaje(values));
    }, [values, dispatch]);

    const [currentTab, setCurrentTab] = useState(0);
    const { addOrEdit, recordForEdit } = props;

    useEffect(() => {
        if (viajeId) {
            dispatch(loadViaje(viajeId));
            setIsDirty(false);
        }
    }, [viajeId, dispatch, setIsDirty]);

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
    }, [recordForEdit, setValues]);

    const handleChangeIndex = (index, value) => {
        setCurrentTab(value);
    };

    useEffect(() => {
        dispatch(setIndex(1, 'Op'));
        dispatch(setButton(true, false));
        dispatch(setButtonCasetas(false));
    }, [dispatch]);

    useEffect(() => {
        if (isDirty) {
            dispatch(setButton(false, updateButton.apagadoCombustible));
        }
    }, [isDirty, dispatch, updateButton.apagadoCombustible]);

    const classes = useStyles();
    return (
        <>
            <Prompt
                message={`No has guardado los cambios:
            1 - 'OK' para continuar SIN guardar los cambios.
            2 - 'Cancel' para regresar a la pagina de viaje para que puedas guardarlos`}
                when={isDirty}
            />
            <Form>
                <div className={classes.root}>
                    <Grid container>
                        <Toolbar
                            errors={errors}
                            handleInputChange={handleInputChange}
                            isDirty={isDirty}
                            setIsDirty={setIsDirty}
                            setValues={setValues}
                            validate={validate}
                            values={values}
                        />
                        <TopViaje
                            errors={errors}
                            handleInputChange={handleInputChange}
                            setIsDirty={setIsDirty}
                            values={values}
                        />
                        <Grid container>
                            <Tabs
                                aria-label='full width tabs example'
                                indicatorColor='primary'
                                onChange={handleChangeIndex}
                                textColor='primary'
                                value={currentTab}
                                variant='fullWidth'
                            >
                                <Tab label='Generales' />
                                <Tab label='Tramos' />
                                <Tab label='Combustible' />
                                <Tab label='Casetas' />
                                <Tab label='Otros Gastos' />
                                <Tab label='Monitoreo' />
                                <Tab label='Ubicaciones Carga-Descarga' />
                            </Tabs>
                        </Grid>

                        <Controls.TabPanel index={0} value={currentTab}>
                            <Generales
                                errors={errors}
                                handleInputChange={handleInputChange}
                                setIsDirty={setIsDirty}
                                setValues={setValues}
                                values={values}
                            />
                        </Controls.TabPanel>

                        <Controls.TabPanel index={2} value={currentTab}>
                            <Combustible
                                setIsDirty={setIsDirty}
                                setValues={setValues}
                                values={values}
                            />
                        </Controls.TabPanel>

                        <Controls.TabPanel index={3} value={currentTab}>
                            <Casetas
                                setIsDirty={setIsDirty}
                                setValues={setValues}
                                values={values}
                            />
                        </Controls.TabPanel>

                        <Controls.TabPanel index={1} value={currentTab}>
                            <Tramos setIsDirty={setIsDirty} setValues={setValues} values={values} />
                        </Controls.TabPanel>

                        <Controls.TabPanel index={4} value={currentTab}>
                            <Gastos setIsDirty={setIsDirty} setValues={setValues} values={values} />
                        </Controls.TabPanel>

                        <Controls.TabPanel index={5} value={currentTab}>
                            <Monitoreo tramos={values.tramos} viajeId={values.id} />
                        </Controls.TabPanel>
                        <Controls.TabPanel index={6} value={currentTab}>
                            <UbicacionesViaje tramos={values.tramos} viajeId={values.id} />
                        </Controls.TabPanel>
                    </Grid>
                </div>
            </Form>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
