export const LOAD_CASETAS_NEW = 'LOAD_CASETAS_NEW';
export const SET_CASETAS_NEW = 'SET_CASETAS_NEW';

export const loadCasetasNew = () => ({
    type: LOAD_CASETAS_NEW,
});

export const setCasetasNew = (casetas) => ({
    type: SET_CASETAS_NEW,
    casetas,
});

const initialState = {
    casetas: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_CASETAS_NEW:
        const { casetas } = action;
        return {
            ...state,
            casetas,
        };

    default:
        return state;
    }
};
