/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { findAllByDisplayValue } from '@testing-library/react';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

const tipoCasetaOptions = [{
    id: 'ID Mexico',
    title: 'ID Mexico',
}, {
    id: 'Zacatecas',
    title: 'Zacatecas',
}, {
    id: 'Efectivo',
    title: 'Efectivo',
},
];
export default function CombustibleForm(props) {
    const initialFieldValues = {
        id: 0,
        caseta: null,
        fecha: null,
        soloFecha: '',
        soloHora: null,
        clase: null,
        importe: 0,
        tipoCaseta: 'Efectivo',
    };

    const [soloFecha, setSoloFecha] = useState(null);
    const [soloHora, setSoloHora] = useState(null);

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('soloFecha' in fieldValues) {
            temp.soloFecha = fieldValues.soloFecha ? '' : 'Campo Requerido';
        }

        if ('soloHora' in fieldValues) {
            temp.soloHora = fieldValues.soloHora ? '' : 'Campo Requerido';
        }
        if ('caseta' in fieldValues) {
            temp.caseta = fieldValues.caseta ? '' : 'Campo Requerido';
        }
        if ('importe' in fieldValues) {
            temp.importe = fieldValues.importe ? '' : 'Campo Requerido';
        }

        if ('tipoCaseta' in fieldValues) {
            temp.tipoCaseta = fieldValues.tipoCaseta ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit, funcSetRecordForEdit } = props;

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
            setSoloFecha(recordForEdit.fecha);
            setSoloHora(recordForEdit.fecha);
        }
    }, [recordForEdit]);

    const handleInputFecha = (e) => {
        setSoloFecha(e.target.value);
    };
    const handleInputHora = (e) => {
        setSoloHora(e.target.value);
    };

    const [disableButton, setDisableButton] = useState(false);
    const handleSubmit = (e) => {
        setDisableButton(true);
        e.preventDefault();
        const fechaCom = moment(values.soloFecha).format('YYYY-MM-DD');
        const horaCom = moment(values.soloHora).format('HH:mm');
        const fechaFinal = `${fechaCom}T${horaCom}:00`;
        values.fecha = fechaFinal;
        if (validate()) {
            addOrEdit(values, resetForm);
            funcSetRecordForEdit();
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Controls.DatePicker
                        error={errors.soloFecha}
                        label='Fecha *'
                        name='soloFecha'
                        onChange={handleInputChange}
                        value={values.soloFecha}
                    />
                    <Controls.TimePicker
                        error={errors.soloHora}
                        label='Hora *'
                        name='soloHora'
                        onChange={handleInputChange}
                        value={values.soloHora}
                    />
                    <Controls.Input
                        error={errors.caseta}
                        label='Caseta *'
                        name='caseta'
                        onChange={handleInputChange}
                        value={values.caseta}
                    />
                    <Controls.Input
                        label='Clase'
                        name='clase'
                        onChange={handleInputChange}
                        value={values.clase}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controls.NumericInput
                        error={errors.importe}
                        label='Importe *'
                        name='importe'
                        onChange={handleInputChange}
                        value={values.importe}
                    />

                    <Controls.Select
                        label='Tipo De Caseta'
                        name='tipoCaseta'
                        onChange={handleInputChange}
                        options={tipoCasetaOptions}
                        value={values.tipoCaseta}
                    />

                </Grid>
                <Grid align='center' item xs={12}>
                    <div>
                        <Controls.Button disabled={disableButton} text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
