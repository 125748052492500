/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import {
    InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Toolbar,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Search } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import useTable from '../../components/useTable';
import { filterOnAnyField, dateAndTimeToMoment } from '../../utils';
import {
    calcularRendimientoViaje,
    refreshViajeCombustible,
    removeCombustible,
} from '../../redux/ducks/viajes';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConfirmDialogSimple from '../../components/ConfirmDialogSimple';
import CombustibleForm from './CombustibleForm';
import Popup from '../../components/Popup';
import { setButton } from '../../redux/ducks/updateButton';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
    },
    refreshButton: {},
    colorTableRow: {
        fontWeight: 'bold',
        background: 'lightsalmon',
        lineHeight: 1,
    },
}));

export default function Combustible({ values, setValues, setIsDirty }) {
    const [openPopupCombustible, setOpenPopupCombustible] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const [confirmDialogSimple, setConfirmDialogSimple] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const [recordForEdit, setRecordForEdit] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const updateButton = useSelector((state) => state.updateButton);
    const gasolinas = useSelector((state) => state.gasolinas.gasolinas);

    if (values.combustible != null) {
        values.combustible.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
    }

    const headCells = [
        { id: 'fecha', label: 'Fecha' },
        { id: 'cantidad', label: 'Cantidad Litros' },
        { id: 'monto', label: 'Monto' },
        { id: 'pu', label: 'Precio Unitario' },
        { id: 'gasolinera', label: 'Gasolinera' },
        { id: 'kilometros', label: 'Kilometros' },
        { id: 'rendimiento', label: 'Rendimiento' },
        { id: 'litrosExtra', label: 'Litros Faltantes' },
        { id: 'faltantePesos', label: 'Faltantes En Pesos' },
        { id: 'editar', label: 'Editar' },
        { id: 'borrar', label: 'Borrar', disableSorting: true },
    ];
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(values.combustible, headCells, filterFn);

    const [filter, setFilter] = useState();

    function checkNewAddedCombustible(recentCombustible) {
        if (recentCombustible != null) {
            const newRecords = recentCombustible.filter((b) => (b.id < 0));
            const newRecord = newRecords[0];
            if (!newRecord) {
                return false;
            }

            return true;
        }

        return false;
    }

    const addSoloFecha = (itemToEdit) => {
        setRecordForEdit({ ...itemToEdit, soloFecha: itemToEdit.fecha, soloHora: itemToEdit.fecha });
    };

    const showDialog = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar la entrada?',
            isOpen: true,
            onConfirm: () => {
                const idx = values.combustible.findIndex(
                    (o) => o.id === item.id,
                );
                const combustibleList = values.combustible
                    .slice(0, idx)
                    .concat(values.combustible.slice(idx + 1));

                if (item.viaje) {
                    dispatch(removeCombustible(item.viaje, item.id));
                }

                setValues({
                    ...values,
                    combustible: combustibleList,
                });
                setIsDirty(true);
            },
        });
    };

    const showDialogSimple = () => {
        setConfirmDialogSimple({
            subtitle: '',
            title: 'Antes de actualizar, necesitas guardar los nuevos datos de combustible que agregaste, usa el botón de guardar viaje en la parte de arriba de la pantalla',
            isOpen: true,
        });
    };
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };
    const [total, setTotal] = useState({
        cantidad: 0,
        monto: 0,
        precioUnitario: 0,
        kilometros: 0,
        litrosFaltantes: 0,
        rendimiento: 0,
    });

    useEffect(() => {
        const t = {
            cantidad: 0,
            monto: 0,
            precioUnitario: 0,
            kilometros: 0,
            litrosFaltantes: 0,
            rendimiento: 0,
            faltanteEnPesos: 0,
        };
        const gas = values.combustible.filter((f) => f.noIncluirEnLiquidacion !== true);
        if (gas.length > 0) {
            gas.forEach((c) => {
                t.cantidad += c.cantidad;
                t.monto += Number(c.monto);
                t.precioUnitario += Number(c.pu);
                t.kilometros += Number(c.kilometros);
                t.rendimiento += Number(c.rendimiento);
                // t.litrosFaltantes += Number(c.litrosExtra);
                // t.faltanteEnPesos += Number(c.faltanteEnPesos);
            });
            t.precioUnitario /= gas.length;
            t.rendimiento = t.kilometros / t.cantidad;
            t.precioUnitario = Math.round(t.precioUnitario * 100) / 100;
            if (t.rendimiento < values.unidad.rendimiento) {
                t.litrosFaltantes = Math.round(
                    ((t.cantidad - t.kilometros / values.unidad.rendimiento) + Number.EPSILON) * 100,
                ) / 100;
                t.faltanteEnPesos = t.litrosFaltantes * t.precioUnitario;
            }

            t.rendimiento = Math.round(t.rendimiento * 100) / 100;
        }

        setTotal(t);
    }, [values.combustible]);

    const addOrEdit = (combustible) => {
        const editedCombustibleArray = values.combustible.filter((a) => (a.id === combustible.id));
        const editedCombustible = editedCombustibleArray[0];
        if (!editedCombustible) {
            let addedCombustible = null;
            if (values.combustible.length === 0) {
                addedCombustible = {
                    ...combustible,
                    id: -1,
                    vehiculo: values.unidad.numeroEconomico,
                };
            } else {
                addedCombustible = {
                    ...combustible,
                    id: -1 * (values.combustible.length + 1),
                    vehiculo: values.unidad.numeroEconomico,
                };
            }

            const combustibleList = values.combustible.concat(addedCombustible);
            combustibleList.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
            setValues({
                ...values,
                combustible: combustibleList,
            });
        } else {
            const listWithNoEditedCombustible = values.combustible.filter((a) => (a.id !== combustible.id));
            const listWithEditedCombustible = listWithNoEditedCombustible.concat(combustible);
            listWithEditedCombustible.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
            setValues({
                ...values,
                combustible: listWithEditedCombustible,
            });
        }
        setOpenPopupCombustible(false);
        setIsDirty(true);
    };

    const funcSetRecordForEdit = () => {
        setRecordForEdit(null);
    };

    const calcularRendimiento = () => {
        dispatch(calcularRendimientoViaje(values.unidad.numeroEconomico, values.combustible));
    };

    const calcularRendimientoManual = () => {
        setIsDirty(true);
        const newCombustibleManual = values.combustible.map((items) => {
            let litrosExtra = 0; let
                rendimiento = 0; let
                faltanteEnPesos = 0;
            if (items.cantidad !== 0) {
                rendimiento = Math.round((items.kilometros / items.cantidad) * 100) / 100;
                if (rendimiento < values.unidad.rendimiento) {
                    litrosExtra = Math.round(
                        ((items.cantidad - items.kilometros / values.unidad.rendimiento) + Number.EPSILON) * 100,
                    ) / 100;
                    faltanteEnPesos = litrosExtra * items.pu;
                }
            }
            return {
                ...items,
                faltanteEnPesos,
                rendimiento,
                litrosExtra,
            };
        });
        setValues({
            ...values,
            combustible: newCombustibleManual,
        });
    };

    const refreshCombustible = () => {
        if (values.unidad) {
            if (!(checkNewAddedCombustible(values.combustible))) {
                const fechaInicial = dateAndTimeToMoment(
                    values.fechaInicio,
                    values.fechaInicioHora,
                );
                const fechaFinal = dateAndTimeToMoment(
                    values.fechaFin,
                    values.fechaFinHora,
                );

                dispatch(
                    refreshViajeCombustible(
                        values.unidad.numeroEconomico,
                        false,
                        fechaInicial !== undefined
                            ? fechaInicial.toISOString(true)
                            : '',
                        fechaFinal !== undefined ? fechaFinal.toISOString(true) : '',
                        values.id,
                    ),
                );
                if (values.combustible != null) {
                    values.combustible.sort((a, b) => ((a.fecha > b.fecha) ? 1 : -1));
                    setIsDirty(true);
                    dispatch(setButton(false, true));
                }
            } else {
                showDialogSimple();
            }
        }
    };

    return (
        <>
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    {' '}
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.newButton1}
                        disabled={updateButton.apagadoCombustible}
                        onClick={() => {
                            refreshCombustible();
                        }}
                        startIcon={<RefreshIcon />}
                        text='Actualizar Combustible desde Archivo'
                        variant='outlined'

                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            funcSetRecordForEdit();
                            setOpenPopupCombustible(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />

                    <Controls.Button
                        className={classes.newButton1}
                        onClick={() => {
                            calcularRendimiento();
                        }}
                        startIcon={<AddIcon />}
                        text='Calcular Kms y Rendimiento (Automatico)'
                        variant='outlined'
                    />

                    <Controls.Button
                        className={classes.newButton1}
                        onClick={() => {
                            calcularRendimientoManual();
                        }}
                        startIcon={<AddIcon />}
                        text='Calcular Rendimiento (Manual)'
                        variant='outlined'

                    />

                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow
                                key={item.id}
                                // className={(item.rendimiento < values.unidad.rendimiento && item.rendimiento !== null && item.kilometros !== null) ? classes.colorTableRow : ''}
                            >
                                <TableCell>
                                    <Moment date={item.fecha} format='D MMM YYYY HH:mm:ss' />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        thousandSeparator
                                        value={item.cantidad}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.monto}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.pu}
                                    />
                                </TableCell>
                                <TableCell>
                                    {item.gasolinera}
                                </TableCell>
                                <TableCell>
                                    {item.kilometros}
                                </TableCell>
                                <TableCell>
                                    {item.rendimiento}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        thousandSeparator
                                        value={item.litrosExtra}
                                    />
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.faltanteEnPesos}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            setOpenPopupCombustible(true);
                                            addSoloFecha(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            showDialog(item);
                                        }}
                                    >
                                        <DeleteForeverIcon fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow
                            className={(total?.rendimiento < values?.unidad?.rendimiento && total?.rendimiento !== null && total?.kilometros !== null) ? classes.colorTableRow : ''}
                        >
                            <TableCell>
                                <Typography variant='h5'>
                                    Total
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    thousandSeparator
                                    value={total.cantidad}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={total.monto}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={total.precioUnitario}
                                />
                            </TableCell>
                            <TableCell />
                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    thousandSeparator
                                    value={total.kilometros}
                                />
                            </TableCell>

                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    thousandSeparator
                                    value={total.rendimiento}
                                />
                            </TableCell>

                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    thousandSeparator
                                    value={total.litrosFaltantes}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={total.faltanteEnPesos}
                                />
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableBody>

                </TblContainer>
                <TblPagination />
            </Paper>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <ConfirmDialogSimple
                confirmDialogSimple={confirmDialogSimple}
                setConfirmDialogSimple={setConfirmDialogSimple}
            />
            <Popup
                openPopup={openPopupCombustible}
                setOpenPopup={setOpenPopupCombustible}
                title='Combustible'
            >
                <CombustibleForm
                    addOrEdit={addOrEdit}
                    funcSetRecordForEdit={funcSetRecordForEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </>
    );
}
