export const LOAD_CLIENTES = 'LOAD_CLIENTES';
export const SET_CLIENTES = 'SET_CLIENTES';

export const SAVE_CLIENTE = 'SAVE_CLIENTE';
export const SAVED_CLIENTE = 'SAVED_CLIENTE';

export const loadClientes = () => ({
    type: LOAD_CLIENTES,
});

export const setClientes = (clientes) => ({
    type: SET_CLIENTES,
    clientes,
});

export const saveCliente = (cliente) => ({
    type: SAVE_CLIENTE,
    cliente,
});

export const savedCliente = (cliente) => ({
    type: SAVED_CLIENTE,
    cliente,
});

const initialState = {
    clientes: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SAVED_CLIENTE:
        const { cliente } = action;
        const index = state.clientes.findIndex((item) => item.id === cliente.id);
        if (index === -1) {
            return {
                ...state,
                clientes: state.clientes.concat(cliente),
            };
        }
        const c = state.clientes
            .slice(0, index)
            .concat([cliente])
            .concat(state.clientes.slice(index + 1));
        return {
            ...state,
            clientes: c,
        };

    case SET_CLIENTES:
        const { clientes } = action;
        return {
            ...state,
            clientes,
        };
    default:
        return state;
    }
};
