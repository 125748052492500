export const LOAD_GPSALL = 'LOAD_GPSALL';
export const SET_GPSALL = 'SET_GPSALL';

export const loadGpsall = () => ({
    type: LOAD_GPSALL,
});

export const setGpsall = (gpsall) => ({
    type: SET_GPSALL,
    gpsall,
});

const initialState = {
    gpsall: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_GPSALL:
        const { gpsall } = action;
        return {
            ...state,
            gpsall,
        };

    default:
        return state;
    }
};
