/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';

function InternalTabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            aria-labelledby={`full-width-tab-${index}`}
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            role='tabpanel'
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export default function TabPanel(props) {
    return (
        <Grid item xs={12}>
            <InternalTabPanel {...props} />
        </Grid>
    );
}
