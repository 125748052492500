import React, { useState, useEffect } from 'react';
import {
    Paper,
    TableBody,
    TableRow,
    TableCell,
    Toolbar,
    InputAdornment,
    Tooltip,
    Fab,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import { DeleteForever, Search, Today } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from '../../components/Popup';
import { loadOperadores, saveOperador } from '../../redux/ducks/operadores';
import { Controls } from '../../components/controls/Controls';
import useTable from '../../components/useTable';
import PageHeader from '../../components/PageHeader';
import OperatorsForm from './OperatorsForm';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { filterOnAnyField } from '../../utils';
import ConfirmDialog from '../../components/ConfirmDialog';
import http from '../../http-common';
import Moment from '../../components/Moment';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    fab: {
        margin: theme.spacing(2),
    },
}));
const headCells = [
    { id: 'nombre', label: 'Nombre' },
    { id: 'apellidos', label: 'Apellido' },
    { id: 'celular', label: 'Telefono' },
    { id: 'fechaIngreso', label: 'Fecha de Ingreso' },
    { id: 'fechaSalida', label: 'Fecha de Salida' },
    { id: 'diasEmpresa', label: 'Antigüedad (Dias)' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

export default function Operators() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const operadores = useSelector((state) => state.operadores.operadores);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(operadores, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                return filterOnAnyField(items, target.value.toLowerCase());
            },
        });
    };
    const dispatch = useDispatch();

    const addOrEdit = (operador, resetForm) => {
        dispatch(saveOperador(operador));
        setOpenPopup(false);
        resetForm();
    };
    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    const getAntiguedad = (dateFromItem) => {
        if (dateFromItem !== null) {
            const daysBetween = new Date().getDate() - new Date(dateFromItem).getDate();
            return daysBetween;
        }

        return null;
    };

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteOperador = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar el operador?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/operadores/${item.id}`);
                dispatch(loadOperadores(true));
            },
        });
    };

    useEffect(() => {
        dispatch(loadOperadores(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(4, 'Cat'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <PageHeader
                icon={<PeopleOutlineTwoToneIcon fontSize='large' />}
                subtitle=''
                title='Operadores'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        onClick={() => dispatch(loadOperadores(true))}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopup(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.nombre}</TableCell>
                                <TableCell>{item.apellidos}</TableCell>
                                <TableCell>{item.celular}</TableCell>
                                <TableCell>
                                    <Moment date={item.fechaIngreso} />
                                </TableCell>
                                <TableCell>
                                    <Moment date={item.fechaSalida} />
                                </TableCell>
                                <TableCell>
                                    {getAntiguedad(item.fechaIngreso)}
                                </TableCell>
                                <TableCell>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}
                                    >
                                        <Controls.ActionButton
                                            color='primary'
                                            onClick={() => {
                                                openInPopup(item);
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize='small' />
                                        </Controls.ActionButton>
                                        <Tooltip title='Descuentos'>
                                            <Link to={`/operadores/${item.id}`}>
                                                <Controls.ActionButton
                                                    color='primary'
                                                >
                                                    <LocalAtmIcon fontSize='small' />
                                                </Controls.ActionButton>
                                            </Link>
                                        </Tooltip>
                                        <Controls.ActionButton
                                            color='primary'
                                            onClick={() => {
                                                deleteOperador(item);
                                            }}
                                        >
                                            <DeleteForever fontSize='small' />
                                        </Controls.ActionButton>

                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Operador'
            >
                <OperatorsForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </>
    );
}
