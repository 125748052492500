import {
    Grid, InputAdornment,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { Search } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Controls } from '../../components/controls/Controls';
import Moment from '../../components/Moment';
import PageHeader from '../../components/PageHeader';
import useTable from '../../components/useTable';
import http from '../../http-common';
import { loadViajes } from '../../redux/ducks/viajes';
import { dateAndTimeToMoment, filterOnAnyField } from '../../utils';
import { setIndex } from '../../redux/ducks/sideMenuIndex';

function calculaCombustible(viaje) {
    const currentGasolinas = viaje.combustible;

    let totalGasolinas = 0.00;

    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            totalGasolinas += g.monto;
        });
    }

    return totalGasolinas;
}

function calculaCasetas(viaje) {
    const { casetas } = viaje;
    let totalCasetas = 0.0;
    if (casetas) {
        casetas.forEach((c) => {
            totalCasetas += c.importe;
        });
    }

    return totalCasetas;
}

function calculaFleteTramo(f) {
    let impuesto = 1;
    if (f.cliente && f.cliente.impuesto && f.cliente.impuesto > 0) {
        impuesto = 1 + (f.cliente.impuesto / 100.00);
    }
    return Math.round((f.flete * impuesto) * 100) / 100;
}

function calculateTotalVentas(viaje) {
    const { tramos } = viaje;
    let totalFlete = 0.0;
    let totalVentas = 0.0;
    if (tramos) {
        tramos.forEach((f) => {
            totalFlete += calculaFleteTramo(f);
        });
    }

    const { otrosGastos } = viaje;
    let totalOtrosGastos = 0.0;

    if (otrosGastos) {
        otrosGastos.forEach((o) => {
            if (o.tipoPago.id === 'COBRO_AL_CLIENTE') {
                totalOtrosGastos += o.cantidad * 1.16;
            }
        });
    }

    totalVentas = totalFlete + totalOtrosGastos;

    return Math.round((totalVentas) * 100) / 100;
}

function calculateExtras(viaje) {
    const { otrosGastos } = viaje;
    let totalOtrosGastos = 0.0;

    if (otrosGastos) {
        otrosGastos.forEach((o) => {
            if (o.tipoPago.id === 'COBRO_AL_CLIENTE') {
                totalOtrosGastos += o.cantidad * 1.16;
            }
        });
    }
    return totalOtrosGastos;
}

const opcionesFacturas = [
    {
        id: '---',
        title: '---',
    },
    {
        id: 'Sin Factura',
        title: 'Sin Factura',
    },
    {
        id: 'Generada',
        title: 'Generada',
    },
    {
        id: 'Enviada',
        title: 'Enviada',
    },
    {
        id: 'Pagada',
        title: 'Pagada',
    },
];

function calculateTotalGastos(viaje) {
    const { otrosGastos } = viaje;
    let totalOtrosGastos = 0.0;

    if (otrosGastos) {
        otrosGastos.forEach((o) => {
            if ((o.tipoPago.id === 'GENERALES' || o.tipoPago.id === 'PAGO_OPERADOR') && o.noLiquidacion === false) {
                totalOtrosGastos += o.cantidad;
            }
        });
    }

    let pagoOperador = 0.0;
    viaje.tramos.forEach((t) => {
        pagoOperador += t.pagoOperador;
    });

    const currentGasolinas = viaje.combustible;
    let totalGasolinas = 0.00;

    if (currentGasolinas) {
        const gas = currentGasolinas.filter((f) => f.noIncluirEnLiquidacion !== true);
        gas.forEach((g) => {
            totalGasolinas += g.monto;
        });
    }

    const { casetas } = viaje;
    let totalCasetas = 0.0;
    if (casetas) {
        casetas.forEach((c) => {
            totalCasetas += c.importe;
        });
    }

    return Math.round((totalOtrosGastos + pagoOperador + totalGasolinas + totalCasetas) * 100) / 100;
}

function calculaUtilidad(viaje) {
    const utilidad = calculateTotalVentas(viaje)
    - calculateTotalGastos(viaje);

    return Math.round((utilidad) * 100) / 100;
}

function calculaGastos(viaje, tramo) {
    const gastosTramo = viaje.otrosGastos.filter((f) => f.tramoId === tramo.id);
    let totalOtrosGastos = 0.0;
    if (gastosTramo) {
        gastosTramo.forEach((o) => {
            if ((o.tipoPago.id === 'GENERALES' || o.tipoPago.id === 'PAGO_OPERADOR') && o.noLiquidacion === false) {
                totalOtrosGastos += o.cantidad;
            }
        });
    }

    return Math.round(totalOtrosGastos);
}

function calculaExtrasCliente(viaje, tramo) {
    const gastosTramo = viaje.otrosGastos.filter((f) => f.tramoId === tramo.id && f.noLiquidacion === false);
    let totalOtrosGastos = 0.0;
    if (gastosTramo) {
        gastosTramo.forEach((o) => {
            if (o.tipoPago.id === 'COBRO_AL_CLIENTE') {
                totalOtrosGastos += o.cantidad;
            }
        });
    }
    return Math.round((totalOtrosGastos * 1.16) * 100) / 100;
}
const getHeaderTramoStyle = (tramos) => {
    const c = 'facturaPagada';

    const facturas = tramos.map((t) => t.factura);
    if (facturas.includes(null)) {
        return 'sinFactura';
    }
    const status = facturas.map((s) => s.status);

    if (status.includes('Generada')) {
        return 'facturaGenerada';
    }
    if (status.includes('Enviada')) {
        return 'facturaEnviada';
    }

    return 'facturaPagada';
};
const getBackgroundStyle = (tramo) => {
    if (!tramo.factura) {
        return 'sinFactura';
    }

    switch (tramo.factura.status) {
    case 'Generada':
        return 'facturaGenerada';
    case 'Enviada':
        return 'facturaEnviada';
    case 'Pagada':
        return 'facturaPagada';
    default:
        return 'facturaPagada';
    }
};

function processData(data) {
    const allTramos = [];
    data.forEach((v) => allTramos.push(
        ...v.tramos.map((t) => ({
            ...t,
            rowStyle: getBackgroundStyle(t),
        })),
    ));
    const otrosGastos = [];
    data.filter((v) => v.otrosGastos).forEach((v) => otrosGastos.push(...v.otrosGastos));

    const viajes = data.map((viaje) => ({
        id: viaje.id,
        status: viaje.status,
        fechaInicio: viaje.fechaInicio,
        fechaFin: viaje.fechaFin,
        operador: viaje.operador,
        unidad: viaje.unidad,
        combustible: calculaCombustible(viaje),
        casetas: calculaCasetas(viaje),
        ventas: calculateTotalVentas(viaje),
        extras: calculateExtras(viaje),
        gastos: calculateTotalGastos(viaje),
        utilidad: calculaUtilidad(viaje),
        rowStyle: getHeaderTramoStyle(viaje.tramos),
        tramos: [],
    }));

    viajes[viajes.length - 1].tramos = allTramos;
    viajes[viajes.length - 1].totalCombustible = viajes.map((m) => m.combustible).reduce((prev, next) => prev + next);
    viajes[viajes.length - 1].totalCasetas = viajes.map((m) => m.casetas).reduce((prev, next) => prev + next);
    viajes[viajes.length - 1].totalVentas = viajes.map((m) => m.ventas).reduce((prev, next) => prev + next);
    viajes[viajes.length - 1].totalUtilidad = viajes.map((m) => m.utilidad).reduce((prev, next) => prev + next).toFixed(2);
    viajes[viajes.length - 1].totalGastos = viajes.map((m) => m.gastos).reduce((prev, next) => prev + next);

    viajes[viajes.length - 1].otrosGastos = otrosGastos;

    return viajes;
}
const headCells = [
    { id: '', label: '' },
    { id: 'id', label: 'Folio' },
    { id: 'status', label: 'Status' },
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'fechaFin', label: 'Fecha Fin' },
    { id: 'operador', label: 'Operador' },
    { id: 'unidad', label: 'Unidad' },

    { id: 'gasolinas', label: 'Total de Combustible' },
    { id: 'casetas', label: 'Total de Casetas' },

    { id: 'totalFlete', label: 'Total de Venta' },

    { id: 'totalGastos', label: 'Total de Gastos' },
    { id: 'utilidad', label: 'Utilidad del Viaje' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

const headCellsTramo = [
    { id: 'cliente', label: 'Cliente' },
    { id: 'inicio', label: 'Inicio' },
    { id: 'fin', label: 'Fin' },
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'fechaFin', label: 'Fecha Fin' },
    { id: 'kilometros', label: 'Kilometros' },
    { id: 'flete', label: 'Flete' },
    { id: 'fleteIva', label: 'Flete con Iva' },
    { id: 'extras', label: 'Extras Cliente' },
    { id: 'gastos', label: 'Gastos' },
    { id: 'pagoOp', label: 'Pago Operador' },
    { id: 'factura', label: 'Factura' },
    { id: 'statusPagoOperador', label: 'Status Pago Operador' },
    { id: 'statusPagoTramo', label: 'Status Factura' },
];

const BoldCell = withStyles((theme) => ({
    root: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
    sinFactura: {
        background: '#F5B7B1',
        lineHeight: 1,
    },
    facturaGenerada: {
        background: '#FCF3CF',
        lineHeight: 1,
    },
    facturaEnviada: {
        background: '#AED6F1',
        lineHeight: 1,
    },
    facturaPagada: {
        background: '#ABEBC6',
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody tr:hover': {
            // background: 'unset', //backgroundColor:'fffbf2'
            cursor: 'pointer',
        },
    },
    amarillo: { backgroundColor: '#f5f5dc' },

}));

function Row({ item, orderBy }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
        <>
            <TableRow key={item.id} className={classes[item.rowStyle]}>
                <TableCell>
                    { item.tramos.length > 0
                        && (
                            <IconButton aria-label='expand item' onClick={() => setOpen(!open)} size='small'>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        )}
                </TableCell>
                <TableCell>
                    {item.id}
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>
                    <Moment date={item.fechaInicio} />
                </TableCell>
                <TableCell>
                    <Moment date={item.fechaFin} />
                </TableCell>
                <TableCell>
                    {item.operador?.nombre}
                    {' '}
                    {item.operador?.apellidos}
                    {' '}
                </TableCell>
                <TableCell>
                    {item.unidad.numeroEconomico}
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.combustible}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.casetas}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.ventas}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.gastos}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.utilidad}
                    />
                </TableCell>
                <TableCell>
                    <Link to={`/viaje/${item.id}`}>
                        <Controls.ActionButton
                            color='primary'
                        >
                            <EditOutlinedIcon fontSize='small' />
                        </Controls.ActionButton>
                    </Link>
                </TableCell>
            </TableRow>
            { item.tramos.length > 0
                && (
                    <>
                        <TableRow>
                            <BoldCell>
                                Totales
                            </BoldCell>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <BoldCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={item.totalCombustible}
                                />
                            </BoldCell>

                            <BoldCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={item.totalCasetas}
                                />
                            </BoldCell>

                            <BoldCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={item.totalVentas}
                                />
                            </BoldCell>

                            <BoldCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={item.totalGastos}
                                />
                            </BoldCell>
                            <BoldCell>
                                <NumberFormat
                                    decimalScale={2}
                                    displayType='text'
                                    prefix='$'
                                    thousandSeparator
                                    value={item.totalUtilidad}
                                />
                            </BoldCell>
                            <TableCell>
                                <Link to={`/viaje/${item.id}`}>
                                    <Controls.ActionButton
                                        color='primary'
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>
                                </Link>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <Collapse in={open} timeout='auto' unmountOnExit>
                                    <Box margin={1}>
                                        <Typography component='div' gutterBottom variant='subtitle1'>
                                            Tramos:
                                        </Typography>
                                        <Table aria-label='purchases' className={classes.subTable} size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    {headCellsTramo.map((head) => (
                                                        <TableCell
                                                            key={head.id}
                                                        >
                                                            {head.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {item.tramos.map((tramo) => (
                                                    <TableRow
                                                        key={tramo.id}
                                                        className={classes[tramo.rowStyle]}
                                                    >
                                                        <TableCell component='th' display='block' scope='row'>{tramo.cliente?.nombre}</TableCell>
                                                        <TableCell>{tramo.lugarInicio}</TableCell>
                                                        <TableCell>{tramo.lugarFin}</TableCell>
                                                        <TableCell>
                                                            <Moment date={tramo.fechaInicio} />
                                                        </TableCell>
                                                        <TableCell><Moment date={tramo.fechaFin} /></TableCell>
                                                        <TableCell>
                                                            <NumberFormat
                                                                decimalScale={2}
                                                                displayType='text'
                                                                prefix=''
                                                                thousandSeparator
                                                                value={tramo.kilometros}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <NumberFormat
                                                                decimalScale={2}
                                                                displayType='text'
                                                                prefix='$'
                                                                thousandSeparator
                                                                value={tramo.flete}
                                                            />
                                                        </TableCell>

                                                        <TableCell>
                                                            <NumberFormat
                                                                displayType='text'
                                                                prefix='$'
                                                                thousandSeparator
                                                                value={calculaFleteTramo(tramo)}
                                                            />
                                                        </TableCell>

                                                        <TableCell>
                                                            <NumberFormat
                                                                decimalScale={2}
                                                                displayType='text'
                                                                prefix='$'
                                                                thousandSeparator
                                                                value={calculaExtrasCliente(item, tramo)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <NumberFormat
                                                                decimalScale={2}
                                                                displayType='text'
                                                                prefix='$'
                                                                thousandSeparator
                                                                value={calculaGastos(item, tramo)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{tramo.pagoOperador}</TableCell>
                                                        <TableCell>{tramo.factura ? tramo.factura.numeroFactura : ''}</TableCell>
                                                        <TableCell>{tramo.statusPagoOperador}</TableCell>
                                                        <TableCell>{tramo.factura ? tramo.factura.status : ''}</TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </>
                )}
        </>
    );
}
export default function Referenciados() {
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });

    const [viajes, setViajes] = useState(null);

    const [id, setId] = useState(0);
    const handleChangeFolio = (e) => {
        setId(e.target.value);
    };
    async function buscar() {
        const response = await http.get(`/viajes/referenciados/${id}`);

        console.log(response);
        if (response.data.length !== 0) {
            const data = processData(response.data);
            if (data) {
                setViajes(data);
            }
        } else {
            setViajes(null);
        }
    }

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(viajes, headCells, filterFn);
    const [filter, setFilter] = useState();

    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };

    const dispatch = useDispatch();

    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    const handleSortRequest = (cellId) => {
        const isAsc = orderBy === cellId && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(cellId);
    };

    useEffect(() => {
        dispatch(setIndex(2, 'Liq'));
    }, [dispatch]);

    return (
        <>
            <Box component='span' displayPrint='none'>
                <PageHeader
                    displayPrint='none'
                    icon={<AccountBalanceWalletIcon fontSize='large' />}
                    subtitle=''
                    title='Viajes Referenciados'
                />
                <Grid
                    container
                    justifyContent='left'
                    margin='20px'
                >

                    <Controls.NumericInput
                        label='Folio'
                        name='id'
                        onChange={handleChangeFolio}
                        value={id}
                    />

                    <Controls.Button
                        className={classes.refreshButton}
                        onClick={() => buscar()}
                        startIcon={<RefreshIcon />}
                        text='Buscar'
                        variant='outlined'
                    />

                </Grid>

                <Grid
                    container
                    justifyContent='center'
                />

                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={12}>

                        <Grid
                            alignContent='space-around'
                            container
                            justifyContent='center'
                            spacing={1}
                        >

                            <Grid item xs={8}>
                                <Controls.Input
                                    className={classes.searchInput}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='end'>
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label='Buscar'
                                    onChange={handleSearch}
                                    value={filter}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Box>
            <TblContainer component={Paper}>
                <TblHead>
                    <TableRow>
                        <TableCell />
                        {headCells.map((head) => (

                            <TableCell
                                key={head.id}
                                sortDirection={orderBy === head.id ? order : false}
                            >
                                {head.disableSorting ? (
                                    head.label
                                ) : (
                                    <TableSortLabel
                                        active={orderBy === head.id}
                                        direction={
                                            orderBy === head.id ? order : 'asc'
                                        }
                                        onClick={() => handleSortRequest(head.id)}
                                    >
                                        {head.label}
                                    </TableSortLabel>
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TblHead>
                <TableBody>
                    {recordsAfterPagingAndSorting().map((item) => (
                        <Row key={item.id} item={item} />
                    ))}
                </TableBody>
            </TblContainer>
            <Box component='span' displayPrint='none'>
                <TblPagination />
            </Box>
        </>
    );
}
