/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import getBancos from '../../services/operatorsService';

const initialFieldValues = {
    id: '',
    email: '',
    lastName: '',
    firstName: '',
    groups: null,
};

export default function UsuariosForm(props) {
    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ('email' in fieldValues) {
            temp.nombre = fieldValues.email ? '' : 'Campo Requerido';
        }
        if ('lastName' in fieldValues) {
            temp.lastName = fieldValues.lastName ? '' : 'Campo Requerido';
        }
        if ('firstName' in fieldValues) {
            temp.firstName = fieldValues.firstName.length !== 0 ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };
    const [nuevo, setNuevo] = useState(true);
    useEffect(() => {
        if (recordForEdit != null) {
            setNuevo(false);
            setValues({
                ...recordForEdit,
                id: recordForEdit.email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const groupOptions = [
        {
            id: 'Administradores',
            title: 'Administradores',
        },
        {
            id: 'Trafico',
            title: 'Trafico',
        },
        {
            id: 'Contabilidad',
            title: 'Contabilidad',
        },

    ];

    return (
        <Form onSubmit={handleSubmit}>

            <Grid container>
                <Grid item xs={6}>

                    <Controls.Input
                        disabled={!nuevo}
                        error={errors.email}
                        label='email'
                        name='email'
                        onChange={handleInputChange}
                        value={values.email}
                    />

                    <Controls.Input
                        error={errors.firstName}
                        label='Nombre'
                        name='firstName'
                        onChange={handleInputChange}
                        value={values.firstName}
                    />

                    <Controls.Input
                        error={errors.lastName}
                        label='Apellidos'
                        name='lastName'
                        onChange={handleInputChange}
                        value={values.lastName}
                    />
                    <Controls.Select
                        label='Grupos'
                        name='groups'
                        onChange={handleInputChange}
                        options={groupOptions}
                        value={values.groups}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
