import React from 'react';
import { TextField } from '@mui/material';

export default function NumericInput(props) {
    const {
        name, label, value, onChange, error = null, ...others
    } = props;
    return (
        <TextField
            label={label}
            name={name}
            onChange={onChange}
            type='number'
            value={value}
            variant='outlined'
            {...(error && {
                error: true,
                helperText: error,
            })}
            {...others}
        />
    );
}
