import { call, put } from 'redux-saga/effects';
import {
    requestLoadViajes,
    requestLoadViaje,
    requestSaveViaje,
    requestLoadViajeFiles,
    requestRefreshCasetas,
    requestRefreshCombustible,
    deleteCombustible,
    removeCaseta,
    requestGpsVehiculo,
    requestCalcularRendimientoViaje,
} from '../requests/viajes';
import {
    setViajes,
    setViaje,
    savedViaje,
    setViajeFiles,
    setViajeCasetas,
    setViajeCombustible,
    setViajeGps,
    setViajeCombustibleAutomatic,
} from '../../ducks/viajes';
import { setSnackbar } from '../../ducks/snackbar';

export function* handleLoadViajes() {
    try {
        const response = yield call(requestLoadViajes);
        const { data } = response;
        yield put(setViajes(data));
    } catch (error) {
        console.log(error);
    }
}

function calcularPago(viaje) {
    if (!viaje) {
        return;
    }
    let pago = 0;
    viaje.tramos.forEach((tramo) => {
        if (tramo.statusPagoOperador === 'PENDIENTE') {
            pago += tramo.pagoOperador - tramo.anticipo;
        }
    });

    viaje.otrosGastos.forEach((gasto) => {
        if (gasto.tipoPago && gasto.tipoPago.id === 'PAGO_OPERADOR') {
            pago += gasto.cantidad;
        }
    });
}
export function* handleLoadViaje(action) {
    try {
        const response = yield call(requestLoadViaje, action.id);
        const { data } = response;

        calcularPago(data);

        yield put(setViaje(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveViaje(action) {
    try {
        const response = yield call(requestSaveViaje, action.viaje, action.password);

        const { data } = response;

        yield put(savedViaje(data));
        yield put(setSnackbar(true, 'success', 'Folio Guardado exitosamente'));
    } catch (error) {
        console.log(error);
    }
}

export function* handleLoadViajeFiles(action) {
    try {
        const response = yield call(requestLoadViajeFiles, action.viajeId);
        const { data } = response;
        yield put(setViajeFiles(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleRefreshCombustible(action) {
    try {
        const response = yield call(
            requestRefreshCombustible,
            action.vehiculo,
            action.asignado,
            action.fechaInicial,
            action.fechaFinal,
            action.viajeId,
        );
        const { data } = response;
        if (data.length !== 0) {
            yield put(setViajeCombustible(data));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleRefreshViajeGps(action) {
    try {
        const response = yield call(
            requestGpsVehiculo,
            action.vehiculo,
            action.fechaInicial,
            action.fechaFinal,
        );
        const { data } = response;
        yield put(setViajeGps(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleRefreshCasetas(action) {
    try {
        const response = yield call(
            requestRefreshCasetas,
            action.vehiculo,
            action.fechaInicial,
            action.fechaFinal,
        );
        const { data } = response;
        yield put(setViajeCasetas(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleDeleteCombustible(action) {
    try {
        yield call(deleteCombustible, action.viajeId, action.combustibleId);
    } catch (error) {
        console.log(error);
    }
}

export function* handleRemoveCaseta(action) {
    try {
        yield call(removeCaseta, action.viajeId, action.casetaId);
    } catch (error) {
        console.log(error);
    }
}

export function* calcularRendimientoViaje(action) {
    try {
        const { data } = yield call(
            requestCalcularRendimientoViaje,
            action.numeroEconomico,

            action.combustible,
        );
        yield put(setViajeCombustibleAutomatic(data));
    } catch (error) {
        console.log(error);
    }
}
