import React from 'react';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));

export default function TextArea(props) {
    const classes = useStyles();
    const {
        name, label, value, onChange, error = null, ...others
    } = props;

    return (
        <TextareaAutosize
            className={classes.root}
            minRows={3}
            name={name}
            onChange={onChange}
            placeholder={label}
            value={value || ''}
            variant='outlined'
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(error && {
                error: true,
                helperText: error,
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...others}
        />
    );
}
