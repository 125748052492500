import { call, put } from 'redux-saga/effects';
import { setCasetasNew } from '../../ducks/casetas_New';
import requestLoadCasetasNew from '../requests/casetas_New';

export default function* handleLoadCasetasNew() {
    try {
        const response = yield call(requestLoadCasetasNew);
        const { data } = response;
        yield put(setCasetasNew(data));
    } catch (error) {
        console.log(error);
    }
}
