/* eslint-disable import/no-named-default */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AdapterDateFns from '@date-io/date-fns';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        '&.MuiFormControl-root': {
            width: '100%',
            paddingLeft: theme.spacing(0),
        },
    },
}));

export default function TimePicker(props) {
    const classes = useStyles();
    const {
        name, label, value, onChange, error = null,
    } = props;

    const convertToDefEventParameter = (n, v) => ({
        target: {
            name: n,
            value: v,
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiTimePicker
                ampm={false}
                autoOk={false}
                inputVariant='outlined'
                label={label}
                mode='24h'
                name={name}
                onChange={(time) => onChange(convertToDefEventParameter(name, time))}
                value={value || null}
                {...(error && {
                    error: true,
                    helperText: error,
                })}
                className={classes.root}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
}
