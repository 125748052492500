import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import Button from './Button';
import ActionButton from './ActionButton';
import Autocomplete from './Autocomplete';
import NumericInput from './NumericInput';
import Textarea from './Textarea';
import TimePicker from './TimePicker';
import TabPanel from './TabPanel';

export const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    Autocomplete,
    NumericInput,
    Textarea,
    TimePicker,
    TabPanel,
};
