import { call, put } from 'redux-saga/effects';
import { setGpsall } from '../../ducks/gpsall';
import requestLoadGpsall from '../requests/gpsall';

export default function* handleLoadGpsall() {
    try {
        const response = yield call(requestLoadGpsall);
        const { data } = response;
        yield put(setGpsall(data));
    } catch (error) {
        console.log(error);
    }
}
