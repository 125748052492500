/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import getTipoDePagos from '../../services/tipoDePagosService';

export default function GastosForm(props) {
    const initialFieldValues = {
        tramoId: null,
        id: 0,
        tipo: '',
        tipoPago: null,
        fechaPago: null,
        cantidad: 0,
        referencia: '',
        descripcion: '',
        imprimir: false,
        noLiquidacion: false,
    };
    const [disableButton, setDisableButton] = useState(false);
    const {
        values, setValues, errors, setErrors, handleInputChange, resetForm,
    } = useForm(
        initialFieldValues,
        true,
        // eslint-disable-next-line no-use-before-define
        validate,
    );

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('fechaPago' in fieldValues) {
            temp.fechaPago = fieldValues.fechaPago ? '' : 'Campo Requerido';
        }

        if ('tipoPago' in fieldValues) {
            temp.tipoPago = fieldValues.tipoPago ? '' : 'Campo Requerido';
        }
        if ('tramoId' in fieldValues) {
            temp.tramoId = fieldValues.tramoId ? '' : 'Campo Requerido';
        }
        if ('cantidad' in fieldValues) {
            temp.cantidad = fieldValues.cantidad ? '' : 'Campo Requerido';
        }
        if ('descripcion' in fieldValues) {
            temp.descripcion = fieldValues.descripcion ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { valuesViaje, addOrEdit, recordForEdit } = props;

    useEffect(() => {
        if (recordForEdit != null) {
            const tramoActual = valuesViaje.find((a) => (a.id === recordForEdit.tramoId));
            const recordForEdit2 = { ...recordForEdit, tramoId: tramoActual };
            setValues({
                ...recordForEdit2,
            });
        }
    }, [recordForEdit, setValues]);

    const handleSubmit = (e) => {
        setDisableButton(true);
        e.preventDefault();
        if (validate()) {
            values.tramoId = values.tramoId.id;
            addOrEdit(values, resetForm);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Controls.DatePicker
                        error={errors.fechaPago}
                        label='Fecha Del Gasto *'
                        name='fechaPago'
                        onChange={handleInputChange}
                        value={values.fechaPago}
                    />
                    <Controls.Autocomplete
                        displayField='text'
                        error={errors.tipoPago}
                        label='Tipo de Gasto *'
                        name='tipoPago'
                        onChange={handleInputChange}
                        options={getTipoDePagos()}
                        value={values.tipoPago}
                    />
                    <Controls.Autocomplete
                        displayField='lugarInicio'
                        displayField1='lugarFin'
                        error={errors.tramoId}
                        label='Numero de Tramo *'
                        name='tramoId'
                        onChange={handleInputChange}
                        options={valuesViaje}
                        value={values.tramoId}
                    />

                </Grid>
                <Grid item xs={6}>
                    <Controls.NumericInput
                        error={errors.cantidad}
                        label='Cantidad *'
                        name='cantidad'
                        onChange={handleInputChange}
                        value={values.cantidad}
                    />
                    <Controls.Input
                        error={errors.referencia}
                        label='Referencia'
                        name='referencia'
                        onChange={handleInputChange}
                        value={values.referencia}
                    />
                    <Controls.Input
                        error={errors.descripcion}
                        label='Descripcion *'
                        name='descripcion'
                        onChange={handleInputChange}
                        value={values.descripcion}
                    />
                    <Controls.Checkbox
                        error={errors.imprimir}
                        label='Imprimir'
                        name='imprimir'
                        onChange={handleInputChange}
                        value={values.imprimir}
                    />
                    <Controls.Checkbox
                        error={errors.noLiquidacion}
                        label='No Afectar Liquidacion'
                        name='noLiquidacion'
                        onChange={handleInputChange}
                        value={values.noLiquidacion}
                    />
                    <div>
                        <Controls.Button disabled={disableButton} text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
