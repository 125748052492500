import React from 'react';
import { Grid } from '@mui/material';
import { Controls } from '../../components/controls/Controls';

export default function Generales({
    setIsDirty, values, errors, handleInputChange,
}) {
    return (
        <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
                <Controls.Textarea
                    error={errors.casetasIda}
                    label='Casetas ida'
                    minRows='5'
                    name='casetasIda'
                    onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                    value={values.casetasIda}
                />
                <Controls.Textarea
                    error={errors.casetasRegreso}
                    label='Casetas Regreso'
                    minRows='5'
                    name='casetasRegreso'
                    onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                    value={values.casetasRegreso}
                />
            </Grid>
            <Grid item xs={6}>
                <Controls.Textarea
                    error={errors.origen}
                    label='Origen'
                    minRows='5'
                    name='origen'
                    onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                    value={values.origen}
                />
                <Controls.Textarea
                    error={errors.destino}
                    label='Destino'
                    minRows='5'
                    name='destino'
                    onChange={(e) => { handleInputChange(e); setIsDirty(true); }}
                    value={values.destino}
                />
            </Grid>
        </Grid>
    );
}
