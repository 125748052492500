import http from '../../../http-common';

export function requestLoadGasolinas() {
    return http.get('/gasolinasgen');
}

export function requestSaveGasolina(gasolina) {
    console.log(gasolina);
    return http.post('/gasolinasgen', gasolina);
}
