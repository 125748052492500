export const LOAD_OPERADORES = 'LOAD_OPERADORES';
export const SET_OPERADORES = 'SET_OPERADORES';

export const SAVE_OPERADOR = 'SAVE_OPERADOR';
export const SAVED_OPERADOR = 'SAVED_OPERADOR';

export const loadOperadores = (incluirAdministrativos = false, soloAdmins = false, inactivos = true) => ({
    type: LOAD_OPERADORES,
    incluirAdministrativos,
    soloAdmins,
    inactivos,
});
export const setOperadores = (operadores) => ({
    type: SET_OPERADORES,
    operadores,
});

export const saveOperador = (operador) => ({
    type: SAVE_OPERADOR,
    operador,
});

export const savedOperador = (operador) => ({
    type: SAVED_OPERADOR,
    operador,
});

const initialState = {
    operadores: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_OPERADORES:
        const { operadores } = action;
        return {
            ...state,
            operadores,
        };
    case SAVED_OPERADOR:
        const { operador } = action;
        const index = state.operadores.findIndex((item) => item.id === operador.id);
        if (index === -1) {
            return {
                ...state,
                operadores: state.operadores.concat(operador),
            };
        }
        const op = state.operadores
            .slice(0, index)
            .concat([operador])
            .concat(state.operadores.slice(index + 1));
        return {
            ...state,
            operadores: op,
        };

    default:
        return state;
    }
};
