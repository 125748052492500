import React from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {
    HashRouter,
    Route, Switch, useHistory,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { Security as OktaSecurity, LoginCallback } from '@okta/okta-react';
import store from '../redux/configureStore';
import App from './App';

const CALLBACK_PATH = '/login/callback';

const clientId = process.env.REACT_APP_OKTA_CLIENT_ID || '0oa19y439ox2POWVB5d7';
const issuer = process.env.REACT_APP_OKTA_ISSUER || 'https://dev-86593202.okta.com/oauth2/default';

export default function Security() {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    const onAuthRequired = () => {
        history.push('/login');
    };

    const config = {
        clientId,
        issuer,
        redirectUri: `${window.location.origin}/login/callback`,
        onAuthRequired,
        scopes: ['openid', 'profile', 'email', 'groups'],
        pkce: true,
    };
    const oktaAuth = new OktaAuth(config);
    return (
        <Provider store={store}>
            <OktaSecurity oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Switch>
                    <Route component={LoginCallback} path={CALLBACK_PATH} />
                </Switch>
                <HashRouter>
                    <App />
                </HashRouter>
            </OktaSecurity>
        </Provider>
    );
}
