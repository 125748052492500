/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import getBancos from '../../services/operatorsService';
// import '../Gasolina/node_modules/react-dropzone-uploader/dist/styles.css'

const initialFieldValues = {
    id: 0,
    nombre: '',
    apellidos: '',
    cuentaBancaria: '',
    celular: '',
    banco: '',
    activo: true,
    licencia: '',
    rfc: '',
    tarjetaGasolina: '',
    administrativo: false,
    salarioBase: 0,
    fechaIngreso: '',
    fechaSalida: '',
    vigenciaLicencia: null,
};

export default function OperatorsForm(props) {
    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ('nombre' in fieldValues) {
            temp.nombre = fieldValues.nombre ? '' : 'Campo Requerido';
        }
        if ('apellidos' in fieldValues) {
            temp.apellidos = fieldValues.apellidos ? '' : 'Campo Requerido';
        }
        if ('celular' in fieldValues) {
            temp.celular = fieldValues.celular.length === 10
                ? ''
                : 'Celular invalido deben ser 10 caracteres';
        }
        if ('banco' in fieldValues) {
            temp.banco = fieldValues.banco.length !== 0 ? '' : 'Campo Requerido';
        }
        if ('licencia' in fieldValues) {
            temp.licencia = fieldValues.licencia.length !== 0 ? '' : 'Campo Requerido';
        }

        if ('rfc' in fieldValues) {
            temp.rfc = fieldValues.rfc.length !== 0 ? '' : 'Campo Requerido';
        }
        // eslint-disable-next-line no-use-before-define
        setErrors({
            ...temp,
        });
        // eslint-disable-next-line no-use-before-define
        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        body.append('operadorId', values.id);
        body.append('file', file);
        return { url: '/operadores/files', body };
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Controls.Checkbox
                    label='Administrativo'
                    name='administrativo'
                    onChange={handleInputChange}
                    value={values.administrativo}
                />

                <Controls.Checkbox
                    label='Activo'
                    name='activo'
                    onChange={handleInputChange}
                    value={values.activo}
                />
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.nombre}
                        label='Nombre'
                        name='nombre'
                        onChange={handleInputChange}
                        value={values.nombre}
                    />

                    <Controls.Input
                        error={errors.apellidos}
                        label='Apellidos'
                        name='apellidos'
                        onChange={handleInputChange}
                        value={values.apellidos}
                    />

                    <Controls.Input
                        error={errors.licencia}
                        label='Licencia'
                        name='licencia'
                        onChange={handleInputChange}
                        value={values.licencia}
                    />
                    <Controls.Input
                        error={errors.rfc}
                        label='Rfc'
                        name='rfc'
                        onChange={handleInputChange}
                        value={values.rfc}
                    />
                    <Controls.DatePicker
                        error={errors.fechaIngreso}
                        label='Fecha de Ingreso'
                        name='fechaIngreso'
                        onChange={handleInputChange}
                        value={values.fechaIngreso}
                    />
                    <Controls.DatePicker
                        error={errors.fechaSalida}
                        label='Fecha de Salida'
                        name='fechaSalida'
                        onChange={handleInputChange}
                        value={values.fechaSalida}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.banco}
                        label='Banco'
                        name='banco'
                        onChange={handleInputChange}
                        value={values.banco}
                    />
                    <Controls.Input
                        label='Cuenta'
                        name='cuentaBancaria'
                        onChange={handleInputChange}
                        value={values.cuentaBancaria}
                    />
                    <Controls.Input
                        error={errors.celular}
                        label='Telefono'
                        name='celular'
                        onChange={handleInputChange}
                        value={values.celular}
                    />

                    <Controls.DatePicker
                        error={errors.vigenciaLicencia}
                        label='Vigencia Licencia'
                        name='vigenciaLicencia'
                        onChange={handleInputChange}
                        value={values.vigenciaLicencia}
                    />
                    <Controls.Input
                        label='Tarjeta Gasolina'
                        name='tarjetaGasolina'
                        onChange={handleInputChange}
                        value={values.tarjetaGasolina}
                    />
                    { values.administrativo
                        ? (
                            <Controls.NumericInput
                                label='Salario Base'
                                name='salarioBase'
                                onChange={handleInputChange}
                                value={values.salarioBase}
                            />
                        )
                        : <></>}

                    {values.id > 0 && (
                        <Dropzone
                            autoUpload
                            getUploadParams={getUploadParams}
                            inputContent='Agregar Archivos'
                            inputWithFilesContent='Agregar archivos'
                            multiple
                        />
                    )}
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
