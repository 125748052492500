import { call, put } from 'redux-saga/effects';
import { setGasolinas, loadGasolinas } from '../../ducks/gasolinas';
import { requestLoadGasolinas, requestSaveGasolina } from '../requests/gasolinas';

export function* handleLoadGasolinas() {
    try {
        const response = yield call(requestLoadGasolinas);
        const { data } = response;
        yield put(setGasolinas(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveGasolina(action) {
    try {
        const response = yield call(requestSaveGasolina, action.gasolina);
        const response2 = yield call(requestLoadGasolinas);
        const { data } = response2;
        yield put(setGasolinas(data));
    } catch (error) {
        console.log(error);
    }
}
