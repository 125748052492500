/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Form } from '../../components/useForm';
import { Controls } from '../../components/controls/Controls';
import { loadClientes } from '../../redux/ducks/clientes';

const initialFieldValues = {
    id: 0,
    numeroFactura: '',
    cliente: null,
    fecha: '',
    importe: '',
    status: '',
    referenciaFactura: '',
};

export default function FacturasForm(props) {
    const dispatch = useDispatch();

    const clientes = useSelector((state) => state.clientes.clientes);

    useEffect(() => {
        dispatch(loadClientes());
    }, [dispatch]);

    const statuses = [
        { id: 'Generada', title: 'Generada' },
        { id: 'Enviada', title: 'Enviada' },
        { id: 'Pagada', title: 'Pagada' },
        { id: 'Cancelada', title: 'Cancelada' },
    ];

    const validate = (fieldValues = values) => {
        const temp = { ...errors };

        if ('numeroFactura' in fieldValues) {
            temp.numeroFactura = fieldValues.numeroFactura
                ? ''
                : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return false;
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12} />

                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.numeroFactura}
                        label='Numero de Factura'
                        name='numeroFactura'
                        onChange={handleInputChange}
                        value={values.numeroFactura}
                    />

                    <Controls.Autocomplete
                        displayField='nombre'
                        displayField1='rfc'
                        error={errors.cliente}
                        label='Cliente'
                        name='cliente'
                        onChange={handleInputChange}
                        options={clientes}
                        value={values.cliente}
                    />

                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.importe}
                        label='Importe'
                        name='importe'
                        onChange={handleInputChange}
                        value={values.importe}
                    />

                    <Controls.Select
                        label='Status'
                        name='status'
                        onChange={handleInputChange}
                        options={statuses}
                        value={values.status}
                    />

                    <Controls.Input
                        label='Referencia Factura'
                        name='referenciaFactura'
                        onChange={handleInputChange}
                        value={values.referenciaFactura}
                    />

                    <Controls.DatePicker
                        label='Fecha'
                        name='fecha'
                        onChange={handleInputChange}
                        value={values.fecha}
                    />

                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
