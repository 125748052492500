import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Moment from 'react-moment';
import { BrowserRouter as Router } from 'react-router-dom';
import Security from './App/Security';
import reportWebVitals from './reportWebVitals';

Moment.globalFormat = 'D MMM YYYY';
Moment.globalLocale = 'mx';

ReactDOM.render(<Router><Security /></Router>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
