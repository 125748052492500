import React, { useState, useEffect } from 'react';
import {
    Paper,
    TableBody,
    TableRow,
    TableCell,
    Toolbar,
    InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import { DeleteForeverOutlined, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../../components/Popup';
import { loadOperadores, saveOperador } from '../../redux/ducks/operadores';
import { Controls } from '../../components/controls/Controls';
import useTable from '../../components/useTable';
import PageHeader from '../../components/PageHeader';
import http from '../../http-common';
import { filterOnAnyField } from '../../utils';
import UsuariosForm from './UsuariosForm';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import ConfirmDialog from '../../components/ConfirmDialog';
// import OperatorsForm from './OperatorsForm';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));
const headCells = [
    { id: 'nombre', label: 'Nombre' },
    { id: 'apellidos', label: 'Apellido' },
    { id: 'email', label: 'email' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

export default function Usuarios() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const [usuarios, setUsuarios] = useState([]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(usuarios, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);

        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };
    const dispatch = useDispatch();

    const refreshUsuarios = () => {
        http.get('/usuarios/').then((response) => {
            setUsuarios(response.data);
        });
    };
    const addOrEdit = (usuario, resetForm) => {
        let p;
        if (usuario.id !== '') {
            p = http.put('/usuarios/', usuario);
        } else {
            p = http.post('/usuarios/', usuario);
        }

        p.then((data) => {
            if (data) {
                setOpenPopup(false);
                resetForm();
                refreshUsuarios();
            }
        });
    };
    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteUsuario = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar el usuario?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/usuarios/${item.email}`);
                refreshUsuarios();
            },
        });
    };

    useEffect(() => {
        dispatch(setIndex(6, 'Cat'));
    }, [dispatch]);

    useEffect(() => {
        refreshUsuarios();
    }, [dispatch]);

    return (
        <>
            <PageHeader
                icon={<PeopleOutlineTwoToneIcon fontSize='large' />}
                subtitle=''
                title='Usuarios'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />

                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopup(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.email}>
                                <TableCell>{item.firstName}</TableCell>
                                <TableCell>{item.lastName}</TableCell>
                                <TableCell>{item.email}</TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            openInPopup(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            deleteUsuario(item);
                                        }}
                                    >
                                        <DeleteForeverOutlined fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Usuario'
            >
                <UsuariosForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
