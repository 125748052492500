/* eslint-disable no-restricted-globals */
import {
    Divider,
    Drawer as MUIDrawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme,
    Box,
    Collapse,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import AssignmentIcon from '@mui/icons-material/Assignment';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import CommuteIcon from '@mui/icons-material/Commute';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AddLocationAltOutlined, PeopleOutlined, ShareLocationOutlined } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector, useDispatch } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
/* const useStyles = makeStyles({
    drawer: {
        width: '320px', // aqui se ajusta el espacio entre el sidemenu y el Grid con las formas
    },
});
*/

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

function SideMenu(props) {
    const { history } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openLiq, setOpenLiq] = React.useState(false);
    const [selectedIndexCat, setSelectedIndexCat] = React.useState(null);
    const [selectedIndexOp, setSelectedIndexOp] = React.useState(null);
    const [selectedIndexOther, setSelectedIndexOther] = React.useState(null);
    const [selectedIndexLiq, setSelectedIndexLiq] = React.useState(null);
    const { authState, oktaAuth } = useOktaAuth();

    const theme = useTheme();
    const [openMenu, setOpenMenu] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpenMenu(true);
    };

    const handleDrawerClose = () => {
        setOpenMenu(false);
    };

    const indexInfo = useSelector((state) => state.sideMenuIndex);

    const operaciones = [
        {
            text: 'Historial',
            icon: <CardTravelOutlinedIcon />,
            onClick: () => history.push('/viajes'),
            groups: ['Trafico', 'Administradores', 'Contabilidad', 'Monitoreo'],
        },
        {
            text: 'Viaje',
            icon: <CardTravelOutlinedIcon />,
            onClick: () => history.push('/viaje'),
            groups: ['Trafico', 'Administradores', 'Monitoreo'],
        },
        {
            text: 'Carga Archivos',
            icon: <BackupOutlinedIcon />,
            onClick: () => history.push('/carga'),
            groups: ['Trafico', 'Administradores'],
        },
    ];

    const catalogos = [
        {
            text: 'Clientes',
            icon: <EmojiPeopleOutlinedIcon />,
            onClick: () => history.push('/clientes'),
            groups: ['Contabilidad', 'Administradores'],
        },
        {
            text: 'Ubicaciones',
            icon: <AddLocationAltOutlined />,
            onClick: () => history.push('/ubicaciones'),
            groups: ['Contabilidad', 'Administradores', 'Monitoreo'],
        },
        {
            text: 'Monitoreo',
            icon: <ShareLocationOutlined />,
            onClick: () => history.push('/monitoreo'),
            groups: ['Contabilidad', 'Administradores', 'Monitoreo'],
        },
        {
            text: 'Unidades',
            icon: <LocalShippingOutlinedIcon />,
            onClick: () => history.push('/unidades'),
            groups: ['Contabilidad', 'Administradores', 'Monitoreo'],
        },
        {
            text: 'Operadores',
            icon: <PersonOutlineOutlinedIcon />,
            onClick: () => history.push('/operadores'),
            groups: ['Contabilidad', 'Administradores', 'Monitoreo'],
        },
        {
            text: 'Facturas',
            icon: <AttachMoneyIcon />,
            onClick: () => history.push('/facturas'),
            groups: ['Contabilidad', 'Administradores'],
        },
        {
            text: 'Usuarios',
            icon: <PeopleOutlined />,
            onClick: () => history.push('/usuarios'),
            groups: ['Administradores'],
        },
        {
            text: 'Admin',
            icon: <SettingsIcon />,
            onClick: () => history.push('/admin'),
            groups: ['Administradores'],
        },
        {
            text: 'Cerrar Sesion',
            icon: <ExitToAppIcon />,
            onClick: async () => {
                oktaAuth.tokenManager.clear();
                location.href = `https://dev-86593202.okta.com/oauth2/default/v1/logout?id_token_hint=${authState.idToken.idToken}`;
            },
            groups: ['Administradores', 'Monitoreo'],
        },
    ];

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickLiq = () => {
        setOpenLiq(!openLiq);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleListItemClick = (idx, idxType) => {
        let index = idx;
        let indexType = idxType;
        if (indexInfo.indexGeneral !== null) {
            index = indexInfo.indexGeneral;
            indexType = indexInfo.indexTypeGeneral;
        }

        if (indexType === 'Op') {
            setSelectedIndexOp(index);
            setSelectedIndexCat(null);
            setSelectedIndexOther(null);
            setSelectedIndexLiq(null);
        }
        if (indexType === 'Cat') {
            setSelectedIndexCat(index);
            setSelectedIndexOp(null);
            setSelectedIndexOther(null);
            setSelectedIndexLiq(null);
        }
        if (indexType === 'Other') {
            setSelectedIndexCat(null);
            setSelectedIndexOp(null);
            setSelectedIndexOther(index);
            setSelectedIndexLiq(null);
        }
        if (indexType === 'Liq') {
            setSelectedIndexCat(null);
            setSelectedIndexOp(null);
            setSelectedIndexOther(null);
            setSelectedIndexLiq(index);
        }
    };

    useEffect(() => {
        handleListItemClick(indexInfo.indexGeneral, indexInfo.indexTypeGeneral);
    }, [indexInfo, handleListItemClick]);

    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((ui) => {
                setUserInfo(ui);
            });
        }
    }, [authState, oktaAuth]);

    if (!userInfo) {
        return (<></>);
    }
    return (
        <div className={classes.root}>
            <Box component='span' displayPrint='none'>
                <CssBaseline />
                <AppBar
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: openMenu,
                    })}
                    position='fixed'
                >
                    <Toolbar>
                        <IconButton
                            aria-label='open drawer'
                            className={clsx(classes.menuButton, openMenu && classes.hide)}
                            color='inherit'
                            edge='start'
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography noWrap variant='h6'>
                            SIMET
                        </Typography>
                    </Toolbar>
                </AppBar>
                <MUIDrawer
                    anchor='left'
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    className={(openMenu ? classes.drawer : classes.hide)}
                    open={openMenu}
                    variant='permanent'
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {operaciones.map((item, index) => {
                            const {
                                text, icon, onClick, groups,
                            } = item;
                            if (groups.some((u) => userInfo.groups.includes(u))) {
                                return (
                                    <ListItem
                                        key={text}
                                        button
                                        onClick={(event) => {
                                            handleListItemClick(event, index, 'Op');
                                            onClick();
                                        }}
                                        selected={selectedIndexOp === index}
                                    >
                                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                        <ListItemText primary={text} />
                                    </ListItem>

                                );
                            }
                            return null;
                        })}
                    </List>

                    <Divider />
                    <List>
                        {catalogos.map((item, index) => {
                            const {
                                text, icon, onClick, groups,
                            } = item;
                            if (groups.some((u) => userInfo.groups.includes(u))) {
                                return (
                                    <ListItem
                                        key={text}
                                        button
                                        onClick={(event) => {
                                            handleListItemClick(event, index, 'Cat');
                                            onClick();
                                        }}
                                        selected={selectedIndexCat === index}
                                    >
                                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                        <ListItemText primary={text} />
                                    </ListItem>
                                );
                            }
                            return null;
                        })}
                    </List>
                    <Divider />
                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores') || userInfo.groups.includes('Monitoreo'))
                    && (
                        <List>
                            <ListItem button onClick={handleClick}>
                                <ListItemIcon>
                                    <AssignmentIcon />
                                </ListItemIcon>
                                <ListItemText primary='Información Cargada' />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={open} timeout='auto' unmountOnExit>
                                <List component='div' disablePadding>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 0, 'Other');
                                            history.push('/gasolina');
                                        }}
                                        selected={selectedIndexOther === 0}
                                    >
                                        <ListItemIcon>
                                            <LocalGasStationIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Gasolina' />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 1, 'Other');
                                            history.push('/gps');
                                        }}
                                        selected={selectedIndexOther === 1}
                                    >
                                        <ListItemIcon>
                                            <GpsFixedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='GPS' />
                                    </ListItem>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 2, 'Other');
                                            history.push('/casetas');
                                        }}
                                        selected={selectedIndexOther === 2}
                                    >
                                        <ListItemIcon>
                                            <CommuteIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Casetas' />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    )}
                    <Divider />
                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores'))
                    && (
                        <List>
                            <ListItem button onClick={handleClickLiq}>
                                <ListItemIcon>
                                    <AccountBalanceIcon />
                                </ListItemIcon>
                                <ListItemText primary='Liquidación' />
                                {openLiq ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={openLiq} timeout='auto' unmountOnExit>
                                <List component='div' disablePadding>

                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 0, 'Liq');
                                            history.push('/liquidacionvi');
                                        }}
                                        selected={selectedIndexLiq === 0}
                                    >
                                        <ListItemIcon>
                                            <AccountBalanceWalletIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Liquidación Viajes' />
                                    </ListItem>

                                    { userInfo.groups.includes('Administradores')
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 1, 'Liq');
                                            history.push('/liquidacionop');
                                        }}
                                        selected={selectedIndexLiq === 1}
                                    >
                                        <ListItemIcon>
                                            <PaymentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Liquidación Operadores' />
                                    </ListItem>
                                )}

                                    { userInfo.groups.includes('Administradores')
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 1, 'LiqAdmin');
                                            history.push('/liquidacionAdmin');
                                        }}
                                        selected={selectedIndexLiq === 1}
                                    >
                                        <ListItemIcon>
                                            <AccountBalanceIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Pago Administrativos' />
                                    </ListItem>
                                )}
                                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores'))
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 2, 'Liq');
                                            history.push('/referenciados');
                                        }}
                                        selected={selectedIndexLiq === 2}
                                    >
                                        <ListItemIcon>
                                            <AccountBalanceWalletIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Viajes Referenciados' />
                                    </ListItem>
                                )}

                                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores'))
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 2, 'uti');
                                            history.push('/reporteUtilidad');
                                        }}
                                        selected={selectedIndexLiq === 2}
                                    >
                                        <ListItemIcon>
                                            <AccountBalanceWalletIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Reporte Utilidad' />
                                    </ListItem>
                                )}

                                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores'))
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 2, 'uti');
                                            history.push('/gastosExtra');
                                        }}
                                        selected={selectedIndexLiq === 2}
                                    >
                                        <ListItemIcon>
                                            <AccountBalanceWalletIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Gastos Extra' />
                                    </ListItem>
                                )}

                                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores'))
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 3, 'pagos');
                                            history.push('/pagoOperadores');
                                        }}
                                        selected={selectedIndexLiq === 3}
                                    >
                                        <ListItemIcon>
                                            <ReceiptIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Pagos Operadores' />
                                    </ListItem>
                                )}

                                    { (userInfo.groups.includes('Trafico') || userInfo.groups.includes('Administradores'))
                                && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        onClick={(event) => {
                                            handleListItemClick(event, 3, 'pagoAdmin');
                                            history.push('/pagoAdmin');
                                        }}
                                        selected={selectedIndexLiq === 3}
                                    >
                                        <ListItemIcon>
                                            <ReceiptIcon />
                                        </ListItemIcon>
                                        <ListItemText primary='Historial Pago Administrativos' />
                                    </ListItem>
                                )}

                                </List>
                            </Collapse>
                        </List>
                    )}
                </MUIDrawer>
            </Box>
        </div>
    );
}

export default withRouter(SideMenu);
