import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

export default function CombustibleForm(props) {
    const initialFieldValues = {
        id: null,
        vehiculo: null,
        fecha: null,
        soloFecha: '',
        soloHora: null,
        cantidad: 0,
        monto: 0,
        pu: null,
        gasolinera: null,
        kilometros: null,
        noIncluirEnLiquidacion: false,
    };

    const [soloFecha, setSoloFecha] = useState(null);
    const [soloHora, setSoloHora] = useState(null);

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('soloFecha' in fieldValues) {
            temp.soloFecha = fieldValues.soloFecha ? '' : 'Campo Requerido';
        }

        if ('soloHora' in fieldValues) {
            temp.soloHora = fieldValues.soloHora ? '' : 'Campo Requerido';
        }
        if ('gasolinera' in fieldValues) {
            temp.gasolinera = fieldValues.gasolinera ? '' : 'Campo Requerido';
        }
        if ('cantidad' in fieldValues) {
            temp.cantidad = fieldValues.cantidad ? '' : 'Campo Requerido';
        }

        if ('monto' in fieldValues) {
            temp.monto = fieldValues.monto ? '' : 'Campo Requerido';
        }

        if ('pu' in fieldValues) {
            temp.pu = fieldValues.pu ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit, funcSetRecordForEdit } = props;

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
            // setSoloFecha(recordForEdit.fecha);
            // setSoloHora(recordForEdit.fecha);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const handleInputFecha = (e) => {
        setSoloFecha(e.target.value);
    };
    const handleInputHora = (e) => {
        setSoloHora(e.target.value);
    };

    const [disableButton, setDisableButton] = useState(false);
    const handleSubmit = (e) => {
        setDisableButton(true);
        e.preventDefault();
        const fechaCom = moment(values.soloFecha).format('YYYY-MM-DD');
        const horaCom = moment(values.soloHora).format('HH:mm');
        const fechaFinal = `${fechaCom}T${horaCom}:00`;
        values.fecha = fechaFinal;
        if (validate()) {
            addOrEdit(values, resetForm);
            funcSetRecordForEdit();
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Controls.DatePicker
                        error={errors.soloFecha}
                        label='Fecha *'
                        name='soloFecha'
                        onChange={handleInputChange}
                        value={values.soloFecha}
                    />
                    <Controls.TimePicker
                        error={errors.soloHora}
                        label='Hora *'
                        name='soloHora'
                        onChange={handleInputChange}
                        value={values.soloHora}
                    />
                    <Controls.Input
                        error={errors.gasolinera}
                        label='Gasolinera *'
                        name='gasolinera'
                        onChange={handleInputChange}
                        value={values.gasolinera}
                    />
                    <Controls.NumericInput
                        label='Kilometros'
                        name='kilometros'
                        onChange={handleInputChange}
                        value={values.kilometros}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Controls.NumericInput
                        error={errors.cantidad}
                        label='Cantidad de litros *'
                        name='cantidad'
                        onChange={handleInputChange}
                        value={values.cantidad}
                    />
                    <Controls.NumericInput
                        error={errors.monto}
                        label='Monto *'
                        name='monto'
                        onChange={handleInputChange}
                        value={values.monto}
                    />
                    <Controls.NumericInput
                        error={errors.pu}
                        label='Precio Unitario *'
                        name='pu'
                        onChange={handleInputChange}
                        value={values.pu}
                    />
                    <Controls.Checkbox
                        error={errors.noIncluirEnLiquidacion}
                        label='No Incluir en Liquidacion'
                        name='noIncluirEnLiquidacion'
                        onChange={handleInputChange}
                        value={values.noIncluirEnLiquidacion}
                    />
                </Grid>
                <Grid align='center' item xs={12}>
                    <div>
                        <Controls.Button disabled={disableButton} text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
