import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AdapterDateFns from '@date-io/date-fns';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

const statuses = [{ id: 'CARGANDO', title: 'Cargando' },
    { id: 'ENTREGADO', title: 'Entregado' },
    { id: 'TRANSITO', title: 'Transito' },
    { id: 'LIBRE', title: 'Libre' },
];
const initialFieldValues = {
    id: 0,
    ciudad: '',
    estado: '',
    detalles: '',
    eta: '',
    fecha: null,
    tramoId: null,
    status: '',
};

export default function MonitoreoForm({ tramos, addOrEdit, recordForEdit }) {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('ciudad' in fieldValues) {
            temp.ciudad = fieldValues.ciudad ? '' : 'Campo Requerido';
        }

        if ('estado' in fieldValues) {
            temp.estado = fieldValues.estado ? '' : 'Campo Requerido';
        }

        if ('detalles' in fieldValues) {
            temp.detalles = fieldValues.detalles ? '' : 'Campo Requerido';
        }

        if ('eta' in fieldValues) {
            temp.eta = fieldValues.eta ? '' : 'Campo Requerido';
        }

        if ('fecha' in fieldValues) {
            temp.fecha = fieldValues.fecha ? '' : 'Campo Requerido';
        }

        if ('status' in fieldValues) {
            temp.status = fieldValues.status ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const handleChange = (newValue) => {
        setValues({
            ...values,
            fecha: newValue,
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.ciudad}
                        label='Ciudad'
                        name='ciudad'
                        onChange={handleInputChange}
                        value={values.ciudad}
                    />

                    <Controls.Input
                        error={errors.estado}
                        label='Estado'
                        name='estado'
                        onChange={handleInputChange}
                        value={values.estado}
                    />

                    <Controls.Input
                        error={errors.detalles}
                        label='Detalles'
                        name='detalles'
                        onChange={handleInputChange}
                        value={values.detalles}
                    />

                    <Controls.Input
                        error={errors.eta}
                        label='ETA'
                        name='eta'
                        onChange={handleInputChange}
                        value={values.eta}
                    />

                    <Controls.Select
                        error={errors.status}
                        label='Estatus'
                        name='status'
                        onChange={handleInputChange}
                        options={statuses}
                        value={values.status}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label='Fecha'
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} />}
                            value={values.fecha}
                        />
                    </LocalizationProvider>
                    <Controls.Autocomplete
                        displayField='lugarInicio'
                        displayField1='lugarFin'
                        error={errors.tramoId}
                        label='Numero de Tramo *'
                        name='tramoId'
                        onChange={handleInputChange}
                        options={tramos}
                        value={values.tramoId}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />

                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
