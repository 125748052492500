/* eslint-disable no-plusplus */
import {
    AddIcCallOutlined, DeleteForeverOutlined, EditOffOutlined, EditOutlined, PeopleOutlineTwoTone, PlusOneSharp,
} from '@mui/icons-material';
import {
    Grid, Box, Autocomplete, Paper, TextField,

    TableBody, TableCell, TableRow, Toolbar,
} from '@mui/material';
import NumberFormat from 'react-number-format';

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Moment from 'react-moment';
import PageHeader from '../../components/PageHeader';
import { Controls } from '../../components/controls/Controls';
import { useForm } from '../../components/useForm';
import http from '../../http-common';
import useTable from '../../components/useTable';
import GastosExtraForm from './GastosExtraForm';
import Popup from '../../components/Popup';
import ConfirmDialog from '../../components/ConfirmDialog';

const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
}));

const headCells = [
    { id: 'year', label: 'Año' },
    { id: 'mes', label: 'Mes' },
    { id: 'tipoGasto', label: 'Concepto' },
    { id: 'gastos', label: 'Cantidad' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

export function GastosExtra() {
    const classes = useStyles();

    const [records, setRecords] = useState([]);

    const refresh = async () => {
        const data = await http.get('/unidades/gastos');
        if (data.data) {
            setRecords(data.data);
        }
    };
    const [openPopup, setOpenPopup] = useState(false);
    const [recordForEdit, setRecordForEdit] = useState();
    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };
    useEffect(() => {
        refresh();
    }, []);
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [filterFnTotales, setFilterFnTotales] = useState({
        fn: (items) => items,
    });
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);
    const addOrEdit = async (c, resetForm) => {
        await http.post('/unidades/gastos', c);
        setOpenPopup(false);
        refresh();
        resetForm();
    };
    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteItem = async (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar el gasto?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/unidades/gastos/${item.id}`);
                refresh();
            },
        });
    };

    return (
        <div>
            <Box displayPrint='none'>
                <PageHeader
                    icon={<PeopleOutlineTwoTone fontSize='large' />}
                    subtitle=''
                    title='Gastos Extras'
                />
            </Box>
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopup(true);
                        }}
                        startIcon={<PlusOneSharp />}
                        text='Agregar Nuevo'
                        variant='outlined'
                    />

                </Toolbar>
            </Paper>
            <TblContainer>
                <TblHead />
                <TableBody>
                    {
                        recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>

                                <TableCell>
                                    {item.year}
                                </TableCell>
                                <TableCell>
                                    {meses[item.mes - 1]}
                                </TableCell>
                                <TableCell>
                                    {item.tipoGasto}
                                </TableCell>
                                <TableCell>
                                    <NumberFormat
                                        decimalScale={2}
                                        displayType='text'
                                        prefix='$'
                                        thousandSeparator
                                        value={item.gastos}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            openInPopup(item);
                                        }}
                                    >
                                        <EditOutlined fontSize='small' />
                                    </Controls.ActionButton>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            deleteItem(item);
                                        }}
                                    >
                                        <DeleteForeverOutlined fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </TblContainer>

            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Otros Gastos'
            >
                <GastosExtraForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </div>
    );
}
