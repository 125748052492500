export const SET_BUTTON = 'SET_BUTTON';

export const setButton = (apagado, apagadoCombustible) => ({
    type: SET_BUTTON,
    apagado,
    apagadoCombustible,
});

const initialState = {
    apagado: true,
    apagadoCombustible: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_BUTTON:
        return {
            ...state,
            apagado: action.apagado,
            apagadoCombustible: action.apagadoCombustible,
        };
    default:
        return state;
    }
};
