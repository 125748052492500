import { call, put } from 'redux-saga/effects';
import { setOperadores, savedOperador } from '../../ducks/operadores';
import {
    requestLoadOperadores,
    requestSaveOperador,
} from '../requests/operadores';

export function* handleLoadOperadores(action) {
    try {
        const response = yield call(requestLoadOperadores, action.incluirAdministrativos, action.soloAdmins, action.inactivos);
        const { data } = response;
        yield put(setOperadores(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveOperador(action) {
    try {
        const response = yield call(requestSaveOperador, action.operador);
        const { data } = response;
        yield put(savedOperador(data));
    } catch (error) {
        console.log(error);
    }
}
