import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

const initialFieldValues = {
    id: 0,
    nombre: '',
    telefono: '',
    contacto: '',
    direccion: '',
    url: '',
};

export default function UbicacionForm(props) {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('nombre' in fieldValues) {
            temp.nombre = fieldValues.nombre ? '' : 'Campo Requerido';
        }

        if ('telefono' in fieldValues) {
            temp.telefono = fieldValues.telefono ? '' : 'Telefono Incorrecto';
        }

        if ('contacto' in fieldValues) {
            temp.contacto = fieldValues.contacto ? '' : 'Contacto Requerido';
        }

        if ('url' in fieldValues) {
            temp.url = fieldValues.url ? '' : 'Campo Requerido';
        }

        if ('direccion' in fieldValues) {
            temp.direccion = fieldValues.direccion ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.nombre}
                        label='Nombre'
                        name='nombre'
                        onChange={handleInputChange}
                        value={values.nombre}
                    />

                    <Controls.Input
                        error={errors.telefono}
                        label='Telefono'
                        name='telefono'
                        onChange={handleInputChange}
                        value={values.telefono}
                    />

                    <Controls.Input
                        error={errors.contacto}
                        label='Contacto'
                        name='contacto'
                        onChange={handleInputChange}
                        value={values.contacto}
                    />

                    <Controls.Input
                        error={errors.direccion}
                        label='Direccion'
                        name='direccion'
                        onChange={handleInputChange}
                        value={values.direccion}
                    />

                    <Controls.Input
                        error={errors.url}
                        label='Url'
                        name='url'
                        onChange={handleInputChange}
                        value={values.url}
                    />

                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
