import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';

const initialFieldValues = {
    id: 0,
    nombre: '',
    rfc: '',
    razonSocial: '',
    direccion: '',
    correoContacto: '',
    telefono: '',
    impuesto: '',
    activo: true,
    texto: '',
};

const tipoImpuesto = [{ id: '16', title: '16%' },
    { id: '12', title: '12%' },
    { id: '0', title: '0%' },
];

export default function ClientesForm(props) {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        // eslint-disable-next-line no-use-before-define
    } = useForm(initialFieldValues, true, validate);

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('nombre' in fieldValues) {
            temp.nombre = fieldValues.nombre ? '' : 'Campo Requerido';
        }

        if ('rfc' in fieldValues) {
            temp.rfc = fieldValues.rfc ? '' : 'RFC Incorrecto';
        }
        if ('razonSocial' in fieldValues) {
            temp.razonSocial = fieldValues.razonSocial ? '' : 'Campo Requerido';
        }
        if ('impuesto' in fieldValues) {
            temp.impuesto = fieldValues.impuesto ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const { addOrEdit, recordForEdit } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };

    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.nombre}
                        label='Nombre'
                        name='nombre'
                        onChange={handleInputChange}
                        value={values.nombre}
                    />

                    <Controls.Input
                        error={errors.rfc}
                        label='RFC'
                        name='rfc'
                        onChange={handleInputChange}
                        value={values.rfc}
                    />

                    <Controls.Input
                        error={errors.razonSocial}
                        label='Razón Social'
                        name='razonSocial'
                        onChange={handleInputChange}
                        value={values.razonSocial}
                    />

                    <Controls.Input
                        error={errors.direccion}
                        label='Dirección'
                        name='direccion'
                        onChange={handleInputChange}
                        value={values.direccion}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        error={errors.correoContacto}
                        label='Correo de Contacto'
                        name='correoContacto'
                        onChange={handleInputChange}
                        value={values.correoContacto}
                    />

                    <Controls.Input
                        error={errors.telefono}
                        label='Teléfono'
                        name='telefono'
                        onChange={handleInputChange}
                        value={values.telefono}
                    />
                    <Controls.Select
                        error={errors.impuesto}
                        label='Cantidad de Impuesto'
                        name='impuesto'
                        onChange={handleInputChange}
                        options={tipoImpuesto}
                        value={values.impuesto}
                    />
                    <Controls.Textarea
                        error={errors.texto}
                        label='Notas'
                        maxRows={10}
                        minRows={5}
                        name='texto'
                        onChange={handleInputChange}
                        value={values.texto}
                    />
                    <div>
                        <Controls.Button text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
}
