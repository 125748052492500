/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    FormHelperText,
} from '@mui/material';

export default function Select(props) {
    const {
        name,
        label,
        options,
        onChange,
        value,
        error = null,
        displayField = 'title',
        ...other
    } = props;
    return (
        <FormControl
            {...other}
            variant='outlined'
            {...(error && {
                error: true,
            })}
            size='medium'
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect autoWidth name={name} onChange={onChange} value={value} {...other}>
                {options.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item[displayField]}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
