import React, { useState, useEffect } from 'react';
import {
    Paper,
    TableBody,
    TableRow,
    TableCell,
    Toolbar,
    InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DeleteForever, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import Popup from '../../components/Popup';
import useTable from '../../components/useTable';
import { loadUnidades, saveUnidad } from '../../redux/ducks/unidades';
import UnidadesForm from './UnidadesForm';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import { filterOnAnyField } from '../../utils';
import ConfirmDialog from '../../components/ConfirmDialog';
import http from '../../http-common';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
}));

const headCells = [
    { id: 'numeroEconomico', label: 'Numero Economico' },
    { id: 'ano', label: 'Año' },
    { id: 'numeroEje', label: 'Numero de Ejes' },
    { id: 'tag', label: 'Tag ID México' },
    { id: 'rendimiento', label: 'Rendimiento' },
    { id: 'actions', label: 'Acciones', disableSorting: true },
];

export default function Unidades() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const [filterFn, setFilterFn] = useState({
        fn: (items) => items,
    });
    const [recordForEdit, setRecordForEdit] = useState();

    const unidades = useSelector((state) => state.unidades.unidades);
    const indexInfo = useSelector((state) => state.sideMenuIndex);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(unidades, headCells, filterFn);

    const [filter, setFilter] = useState();
    const handleSearch = (e) => {
        const { target } = e;
        setFilter(target.value);
        setFilterFn({
            fn: (items) => {
                if (target.value === '') {
                    return items;
                }
                // var itemsNumeroEco =  items.filter( x =>  x.numeroEconomico.toLowerCase().includes(target.value.toLowerCase()));
                // var itemsCapacidad = items.filter( x =>  x.capacidadCarga.toLowerCase().includes(target.value.toLowerCase()));
                // var totalFilters = itemsCapacidad; //+ itemsNumeroEco;
                const filteredItems = filterOnAnyField(items, target.value.toLowerCase());
                return filteredItems;
            },
        });
    };
    const dispatch = useDispatch();

    const addOrEdit = (unidad, resetForm) => {
        dispatch(saveUnidad(unidad));
        setOpenPopup(false);
        resetForm();
    };

    const openInPopup = (item) => {
        setRecordForEdit(item);
        setOpenPopup(true);
    };

    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIndex(3, 'Cat'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [confirmDialog, setConfirmDialog] = useState({
        title: 'title',
        subtitle: 'subtitle',
        isOpen: false,
    });

    const deleteUnidad = (item) => {
        setConfirmDialog({
            subtitle: '',
            title: 'Desea borrar la unidad?',
            isOpen: true,
            onConfirm: async () => {
                await http.delete(`/unidades/${item.id}`);
                dispatch(loadUnidades());
            },
        });
    };

    return (
        <>
            <PageHeader
                icon={<LocalShippingIcon fontSize='large' />}
                subtitle=''
                title='Unidades'
            />
            <Paper className={classes.pageContent}>
                <Toolbar>
                    <Controls.Input
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        label='Buscar'
                        onChange={handleSearch}
                        value={filter}
                    />
                    <Controls.Button
                        className={classes.refreshButton}
                        startIcon={<RefreshIcon />}
                        text='Refrescar'
                        variant='outlined'
                        // onClick={() => dispatch(loadOUnidades())}
                    />
                    <Controls.Button
                        className={classes.newButton}
                        onClick={() => {
                            setRecordForEdit(null);
                            setOpenPopup(true);
                        }}
                        startIcon={<AddIcon />}
                        text='Agregar Nueva'
                        variant='outlined'
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.numeroEconomico}</TableCell>
                                <TableCell>{item.ano}</TableCell>
                                <TableCell>{item.numeroEje}</TableCell>
                                <TableCell>{item.tag}</TableCell>
                                <TableCell>{item.rendimiento}</TableCell>
                                <TableCell>
                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            openInPopup(item);
                                        }}
                                    >
                                        <EditOutlinedIcon fontSize='small' />
                                    </Controls.ActionButton>

                                    <Controls.ActionButton
                                        color='primary'
                                        onClick={() => {
                                            deleteUnidad(item);
                                        }}
                                    >
                                        <DeleteForever fontSize='small' />
                                    </Controls.ActionButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />

            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Unidad'
            >
                <UnidadesForm
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </Popup>
        </>
    );
}
