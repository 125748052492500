export const LOAD_UNIDADES = 'LOAD_UNIDADES';
export const SET_UNIDADES = 'SET_UNIDADES';

export const SAVE_UNIDAD = 'SAVE_UNIDAD';
export const SAVED_UNIDAD = 'SAVED_UNIDAD';

export const loadUnidades = () => ({
    type: LOAD_UNIDADES,
});

export const setUnidades = (unidades) => ({
    type: SET_UNIDADES,
    unidades,
});

export const saveUnidad = (unidad) => ({
    type: SAVE_UNIDAD,
    unidad,
});

export const savedUnidad = (unidad) => ({
    type: SAVED_UNIDAD,
    unidad,
});

const initialState = {
    unidades: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_UNIDADES:
        const unidades = action.unidades.map((u) => ({
            ...u,
            activa: !!u.activa,
        }));

        return {
            ...state,
            unidades,
        };
    case SAVED_UNIDAD:
        const { unidad } = action;
        const index = state.unidades.findIndex((item) => item.id === unidad.id);
        if (index === -1) {
            return {
                ...state,
                unidades: state.unidades.concat(unidad),
            };
        }
        const u = state.unidades
            .slice(0, index)
            .concat([unidad])
            .concat(state.unidades.slice(index + 1));
        return {
            ...state,
            unidades: u,
        };

    default:
        return state;
    }
};
