import { takeLatest, all } from 'redux-saga/effects';
import {
    handleLoadViajes,
    handleLoadViaje,
    handleRefreshViajeGps,
    handleSaveViaje,
    handleLoadViajeFiles,
    handleRefreshCombustible,
    handleDeleteCombustible,
    handleRemoveCaseta,
    handleRefreshCasetas,
    calcularRendimientoViaje,
} from './handlers/viajes';
import {
    LOAD_VIAJES,
    LOAD_VIAJE,
    SAVE_VIAJE,
    LOAD_VIAJE_FILES,
    REFRESH_VIAJE_CASETAS,
    REFRESH_COMBUSTIBLE,
    REMOVE_COMBUSTIBLE,
    REMOVE_CASETA,
    REFRESH_VIAJE_GPS,
    CALCULAR_RENDIMIENTO_VIAJE,
} from '../ducks/viajes';
import { LOAD_OPERADORES, SAVE_OPERADOR } from '../ducks/operadores';
import { LOAD_UNIDADES, SAVE_UNIDAD } from '../ducks/unidades';
import { LOAD_FACTURAS, SAVE_FACTURA } from '../ducks/facturas';
import { LOAD_CLIENTES, SAVE_CLIENTE } from '../ducks/clientes';
import { LOAD_GASOLINAS, SAVE_GASOLINA } from '../ducks/gasolinas';
import { LOAD_CASETAS_NEW } from '../ducks/casetas_New';
import { LOAD_CASETAS_LIQ } from '../ducks/casetasLiq';
import { LOAD_TRAMOS } from '../ducks/tramos';
import { LOAD_GPSALL } from '../ducks/gpsall';
import {
    handleLoadOperadores,
    handleSaveOperador,
} from './handlers/operadores';
import { handleLoadUnidades, handleSaveUnidad } from './handlers/unidades';
import { handleLoadFacturas, handleSaveFactura } from './handlers/facturas';
import { handleLoadClientes, handleSaveCliente } from './handlers/clientes';
import { handleLoadGasolinas, handleSaveGasolina } from './handlers/gasolinas';
import handleLoadCasetasNew from './handlers/casetas_New';
import handleLoadCasetasLiq from './handlers/casetasLiq';
import handleLoadTramos from './handlers/tramos';
import handleLoadGpsall from './handlers/gpsall';

export default function* watcherSaga() {
    yield all([
        yield takeLatest(LOAD_VIAJES, handleLoadViajes),
        yield takeLatest(LOAD_VIAJE, handleLoadViaje),
        yield takeLatest(SAVE_VIAJE, handleSaveViaje),
        yield takeLatest(LOAD_OPERADORES, handleLoadOperadores),
        yield takeLatest(SAVE_OPERADOR, handleSaveOperador),
        yield takeLatest(LOAD_VIAJE_FILES, handleLoadViajeFiles),
        yield takeLatest(LOAD_UNIDADES, handleLoadUnidades),
        yield takeLatest(SAVE_UNIDAD, handleSaveUnidad),
        yield takeLatest(LOAD_FACTURAS, handleLoadFacturas),
        yield takeLatest(SAVE_FACTURA, handleSaveFactura),
        yield takeLatest(LOAD_CLIENTES, handleLoadClientes),
        yield takeLatest(SAVE_CLIENTE, handleSaveCliente),
        yield takeLatest(LOAD_GASOLINAS, handleLoadGasolinas),
        yield takeLatest(SAVE_GASOLINA, handleSaveGasolina),
        yield takeLatest(LOAD_CASETAS_NEW, handleLoadCasetasNew),
        yield takeLatest(LOAD_CASETAS_LIQ, handleLoadCasetasLiq),
        yield takeLatest(LOAD_TRAMOS, handleLoadTramos),
        yield takeLatest(LOAD_GPSALL, handleLoadGpsall),
        yield takeLatest(REFRESH_COMBUSTIBLE, handleRefreshCombustible),
        yield takeLatest(REMOVE_COMBUSTIBLE, handleDeleteCombustible),
        yield takeLatest(REMOVE_CASETA, handleRemoveCaseta),
        yield takeLatest(REFRESH_VIAJE_GPS, handleRefreshViajeGps),
        yield takeLatest(REFRESH_VIAJE_CASETAS, handleRefreshCasetas),
        yield takeLatest(CALCULAR_RENDIMIENTO_VIAJE, calcularRendimientoViaje),
    ]);
}
