import React from 'react';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 0,
        margin: theme.spacing(0.5),
    },
    secondary: {
        backgroundColor: theme.palette.secondary.light,
        '& .MuiButton-label': {
            color: theme.palette.secondary.main,
        },
    },
    primary: {
        backgroundColor: theme.palette.primary.light,
        '& .MuiButton-label': {
            color: theme.palette.primary.main,
        },
    },
    turnedOff: {
        backgroundColor: '#d3d3d3',
        '& .MuiButton-label': {
            color: grey,
        },
    },
}));
export default function ActionButton(props) {
    const classes = useStyles();
    const {
        color, children, onClick, disabled,
    } = props;
    return (
        <div>
            <Button
                className={`${classes.root} ${classes[color]}`}
                disabled={disabled || false}
                onClick={onClick}
            >
                {children}
            </Button>
        </div>
    );
}
