export const LOAD_TRAMOS = 'LOAD_CASETAS';
export const SET_TRAMOS = 'SET_CASETAS';

export const loadTramos = () => ({
    type: LOAD_TRAMOS,
});

export const setTramos = (tramos) => ({
    type: SET_TRAMOS,
    tramos,
});

const initialState = {
    tramos: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
    case SET_TRAMOS:
        const { tramos } = action;
        return {
            ...state,
            tramos,
        };

    default:
        return state;
    }
};
