import { call, put } from 'redux-saga/effects';
import { setClientes, savedCliente } from '../../ducks/clientes';
import { requestLoadClientes, requestSaveCliente } from '../requests/clientes';

export function* handleLoadClientes() {
    try {
        const response = yield call(requestLoadClientes);
        const { data } = response;
        yield put(setClientes(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveCliente(action) {
    try {
        const response = yield call(requestSaveCliente, action.cliente);
        const { data } = response;
        yield put(savedCliente(data));
    } catch (error) {
        console.log(error);
    }
}
