import { PeopleOutlineTwoTone } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import PageHeader from '../../components/PageHeader';
import http from '../../http-common';
import { setIndex } from '../../redux/ducks/sideMenuIndex';

export default function Admin() {
    const [token, setToken] = useState('');
    const handleChange = (e) => {
        setToken(e.target.value);
    };

    const actualizar = () => {
        http.post('/usuarios/token', {
            token,
        });
    };
    // eslint-disable-next-line
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIndex(7, 'Cat'));
    }, [dispatch]);

    return (
        <>
            <PageHeader
                icon={<PeopleOutlineTwoTone fontSize='large' />}
                subtitle=''
                title='Admin'
            />

            <div>
                <Controls.Input
                    label='Token'
                    name='token'
                    onChange={handleChange}
                    value={token}
                />

                <Controls.Button
                    onClick={() => actualizar()}
                    startIcon={<RefreshIcon />}
                    text='Actualizar'
                    variant='outlined'
                />

            </div>
        </>
    );
}
