import React, { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import { useForm } from '../../components/useForm';
import { setSnackbar } from '../../redux/ducks/snackbar';
import UploadService from '../../services/FileUploadService';
import { loadUnidades } from '../../redux/ducks/unidades';
import { setIndex } from '../../redux/ducks/sideMenuIndex';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(1),
    },
}));
const initialFieldValues = {
    tipoArchivo: 'GENERICA',
    selectedFiles: [],
    vehiculo: null,
    gasolinera: 'MIGAS',
    tipoCaseta: 'ID Mexico',
};
const fileOptions = [{
    id: 'GENERICA',
    title: 'Gasolina',
}, {
    title: 'Casetas ID',
    id: 'ID',
}, {
    title: 'Zacatecas',
    id: 'SITEL',
}];

const gasCompany = [{
    id: 'OXXO',
    title: 'OXXO',
}, {
    id: 'ORSAN',
    title: 'ORSAN',
}, {
    id: 'MIGAS',
    title: 'Mi Gasolina',
},
];

export default function Carga() {
    const dispatch = useDispatch();
    const { values, setValues, handleInputChange } = useForm(
        initialFieldValues,
        false,
    );

    useEffect(() => {
        dispatch(setIndex(2, 'Op'));
    }, [dispatch]);

    const unidades = useSelector((state) => state.unidades.unidades);
    useEffect(() => {
        dispatch(loadUnidades());
    }, [dispatch]);

    const selectFile = (event) => {
        setValues({
            ...values,
            selectedFiles: event.target.files,
        });
    };
    const upload = () => {
        const currentFile = values.selectedFiles[0];

        UploadService.upload(currentFile, 'archivos', 0, () => {}, {
            tipo: values.tipoArchivo,
            vehiculo: values.vehiculo ? values.vehiculo.numeroEconomico : null,
            gasolinera: values.gasolinera,
            tipoCaseta: values.tipoCaseta,
        })
            .then(() => {
                dispatch(setSnackbar(true, 'success', 'Archivo cargado correctamente'));
            })
            .then(() => {
                dispatch(
                    setSnackbar(
                        true,
                        'success',
                        'Archivo cargado correctamente',
                    ),
                );
            })
            .catch(() => {
                dispatch(setSnackbar(true, 'error', 'Error subiendo archivo'));
            });
    };

    const classes = useStyles();
    return (
        <>
            <Paper className={classes.pageContent}>
                <Grid container item xs={12}>
                    <Grid item xs={8}>
                        <Controls.Select
                            label='Tipo De Archivo'
                            name='tipoArchivo'
                            onChange={handleInputChange}
                            options={fileOptions}
                            value={values.tipoArchivo}
                        />
                        <Grid item xs={3}>
                            <br />
                            <input onChange={selectFile} type='file' />
                            <div>
                                <br />
                                {values.tipoArchivo === 'GPS' && (
                                    <Controls.Autocomplete
                                        displayField='numeroEconomico'
                                        label='Unidad'
                                        name='vehiculo'
                                        onChange={handleInputChange}
                                        options={unidades}
                                        value={values.vehiculo}
                                    />
                                )}
                                {values.tipoArchivo === 'GENERICA'
                                && (
                                    <Controls.Select
                                        label='Selecciona Gasolinera'
                                        name='gasolinera'
                                        onChange={handleInputChange}
                                        options={gasCompany}
                                        value={values.gasolinera}
                                    />
                                )}
                            </div>

                        </Grid>
                        <Grid item xs={6}>
                            <br />
                            <Controls.Button
                                color='primary'
                                onClick={() => upload()}
                                text='Subir Archivo'
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
