import { call, put } from 'redux-saga/effects';
import { setFacturas, savedFactura } from '../../ducks/facturas';
import { requestLoadFacturas, requestSaveFactura } from '../requests/facturas';

export function* handleLoadFacturas() {
    try {
        const response = yield call(requestLoadFacturas);
        const { data } = response;
        yield put(setFacturas(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSaveFactura(action) {
    try {
        const response = yield call(requestSaveFactura, action.factura);
        const { data } = response;
        yield put(savedFactura(data));
    } catch (error) {
        console.log(error);
    }
}
