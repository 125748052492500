/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controls } from '../../components/controls/Controls';
import { Form, useForm } from '../../components/useForm';
import { loadClientes } from '../../redux/ducks/clientes';
import http from '../../http-common';

export default function TramoForm(props) {
    const initialFieldValues = {
        id: 0,
        fechaInicio: null,
        fechaFin: '',
        kilometros: 0,
        flete: 0,
        lugarInicio: '',
        lugarFin: '',
        cliente: null,
        pagoOperador: 0,
        anticipo: 0,
        factura: null,
        statusPagoOperador: 'PENDIENTE',
        comentarios: '',
        ordenDeCompra: '',
        pagoEfectivo: false,
    };

    const statusPago = [
        { id: 'PENDIENTE', title: 'Pendiente' },
        { id: 'PAGADO', title: 'Pagado' },
    ];

    const dispatch = useDispatch();

    function validate(fieldValues = values) {
        const temp = { ...errors };

        if ('fechaInicio' in fieldValues) {
            temp.fechaInicio = fieldValues.fechaInicio ? '' : 'Campo Requerido';
        }

        if ('lugarInicio' in fieldValues) {
            temp.lugarInicio = fieldValues.lugarInicio ? '' : 'Campo Requerido';
        }
        if ('lugarFin' in fieldValues) {
            temp.lugarFin = fieldValues.lugarFin ? '' : 'Campo Requerido';
        }
        if ('cliente' in fieldValues) {
            temp.cliente = fieldValues.cliente ? '' : 'Campo Requerido';
        }

        setErrors({
            ...temp,
        });

        if (temp.fechaInicio === 'Campo Requerido' || temp.lugarInicio === 'Campo Requerido' || temp.lugarFin === 'Campo Requerido' || temp.cliente === 'Campo Requerido') {
            setDisableButton(false);
        }

        if (fieldValues === values) {
            return Object.values(temp).every((x) => x === '');
        }
        return true;
    }

    const {
        values, setValues, errors, setErrors, handleInputChange, resetForm,
    } = useForm(
        initialFieldValues,
        true,
        // eslint-disable-next-line no-use-before-define
        validate,
    );

    const [disableButton, setDisableButton] = useState(false);

    const { addOrEdit, recordForEdit } = props;
    useEffect(() => {
        if (recordForEdit != null) {
            setValues({
                ...recordForEdit,
            });
        }
        console.log(recordForEdit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordForEdit]);

    const handleSubmit = (e) => {
        setDisableButton(true);
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    };
    const clientes = useSelector((state) => state.clientes.clientes);
    const [facturas, setFacturas] = useState([]);
    useEffect(() => {
        dispatch(loadClientes());
        http.get('/facturas?status=Generada')
            .then((data) => {
                setFacturas(data.data);
            });
    }, [dispatch]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container item spacing={1} xs={12}>
                <Grid item xs={6}>
                    <Controls.DatePicker
                        error={errors.fechaInicio}
                        label='Fecha De Inicio *'
                        name='fechaInicio'
                        onChange={handleInputChange}
                        value={values.fechaInicio}
                    />

                    <Controls.Input
                        error={errors.lugarInicio}
                        label='Inicio *'
                        name='lugarInicio'
                        onChange={handleInputChange}
                        value={values.lugarInicio}
                    />

                    <Controls.NumericInput
                        error={errors.kilometros}
                        label='Kilometros'
                        name='kilometros'
                        onChange={handleInputChange}
                        value={values.kilometros}
                    />

                    <Controls.NumericInput
                        error={errors.pagoOperador}
                        label='Pago Operador'
                        name='pagoOperador'
                        onChange={handleInputChange}
                        value={values.pagoOperador}
                    />
                    <Controls.NumericInput
                        error={errors.anticipo}
                        label='Anticipo'
                        name='anticipo'
                        onChange={handleInputChange}
                        value={values.anticipo}
                    />
                    <Controls.Autocomplete
                        displayField='numeroFactura'
                        error={errors.factura}
                        label='Factura'
                        name='factura'
                        onChange={handleInputChange}
                        options={facturas}
                        value={values.factura}
                    />

                </Grid>
                <Grid item xs={6}>
                    <Controls.DatePicker
                        label='Fecha De Fin'
                        name='fechaFin'
                        onChange={handleInputChange}
                        value={values.fechaFin}
                    />
                    <Controls.Input
                        error={errors.lugarFin}
                        label='Fin *'
                        name='lugarFin'
                        onChange={handleInputChange}
                        value={values.lugarFin}
                    />
                    <Controls.NumericInput
                        error={errors.flete}
                        label='Flete'
                        name='flete'
                        onChange={handleInputChange}
                        value={values.flete}
                    />
                    <Controls.Select
                        error={errors.statusPagoOperador}
                        label='Status Pago Operador'
                        name='statusPagoOperador'
                        onChange={handleInputChange}
                        options={statusPago}
                        value={values.statusPagoOperador || 'PENDIENTE'}
                    />

                    <Controls.Textarea
                        label='comentarios'
                        name='comentarios'
                        onChange={handleInputChange}
                        value={values.comentarios}
                    />

                    <Controls.Input
                        label='Orden De Compra'
                        name='ordenDeCompra'
                        onChange={handleInputChange}
                        value={values.ordenDeCompra}
                    />

                </Grid>
                <Grid item xs={6}>
                    <Controls.Autocomplete
                        displayField='nombre'
                        displayField1='rfc'
                        error={errors.cliente}
                        label='Cliente *'
                        name='cliente'
                        onChange={handleInputChange}
                        options={clientes}
                        value={values.cliente}
                    />

                    <div>
                        <Controls.Button disabled={disableButton} text='Guardar' type='submit' />
                        <Controls.Button
                            color='default'
                            onClick={resetForm}
                            text='Reset'
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Controls.Checkbox
                        label='Tramo Pagado en Efectivo'
                        name='pagoEfectivo'
                        onChange={handleInputChange}
                        value={values.pagoEfectivo}
                    />
                </Grid>
            </Grid>
        </Form>
    );
}
